import { Table, Typography } from "antd";

import { ColumnsType } from "antd/es/table";
import { IManagedAsset } from "store/types";
import { RootState } from "store/store";
import { useSelector } from "react-redux";

const CustomerStatsAssetDetails = () => {
  const { assets, loading } = useSelector(
    (state: RootState) => state.customerStats.customerLoansAndAssets
  );
  const columns: ColumnsType<IManagedAsset> = [
    {
      title: "Asset Name",
      key: "assetName",
      dataIndex: "productName",
    },
    {
      title: "Invoice",
      key: "invoice",
      dataIndex: "erpId",
    },
    {
      title: "MAC",
      key: "mac",
      dataIndex: "mac",
    },
    {
      title: "Order Id",
      key: "loanId",
      render: (_, record) => {
        return (
          <Typography.Link
            onClick={() => {
              window.open(`/orders/details/${record?.loanId}`, "_blank");
            }}
          >
            {record?.loanId}
          </Typography.Link>
        );
      },
    },
  ];
  return (
    <>
      <Typography.Text
        style={{ fontSize: 22, color: "#104359", fontWeight: 600 }}
      >
        {"Assets Details"}
      </Typography.Text>
      <Table
        bordered
        columns={columns}
        dataSource={assets}
        loading={loading}
        pagination={{ pageSize: 5 }}
        rowKey={(record) => record?.mac}
      />
    </>
  );
};

export default CustomerStatsAssetDetails;
