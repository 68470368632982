import {
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  Modal,
  Row,
  Statistic,
  TimePicker,
  message,
} from "antd";
import { Requester, formatURL } from "api/requester";

import { API_END_POINTS } from "api/urls";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IndianRuppeNumberFormattor } from "components/products/product.util.components";
import { LoansActions } from "store/slices/loansSlice";
import { RootState } from "store/store";
import { faIndianRupeeSign } from "@fortawesome/free-solid-svg-icons";
import { mergeDateAndTime } from "components/util-components/dateTimeFormattor";
import { useAppDispatch } from "store/hooks";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useTranslation } from "react-i18next";

interface ILoanDashboardAddDistributorFeeFormProps {
  open: boolean;
  close: () => void;
  fee: number;
}

const LoanDashboardAddDistributorFeeForm = (
  props: ILoanDashboardAddDistributorFeeFormProps
) => {
  const { t } = useTranslation();
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [form] = Form.useForm();
  const { details } = useSelector(
    (state: RootState) => state.loans.loanDashboard
  );
  const dispatch = useAppDispatch();
  const displayAmount = props?.fee;

  const handleAddPayment = async (formValues: any) => {
    setIsSubmitLoading(true);
    const data = {
      transactionId: formValues?.transactionId,
      paidOn: mergeDateAndTime(
        formValues?.transactionDate,
        formValues?.transactionTime
      ),
    };
    const url = formatURL(API_END_POINTS.LOANS.markDistributorFeePaid, {
      loanId: details?.id,
    });
    const response = await Requester.post(url, data).catch(
      ({ response }) => response
    );
    if (response?.status === 204) {
      message.success(t("Successfully added payment"));
      handleClose();
      dispatch(LoansActions.fetchLoanById(details?.id || ""));
    } else {
      message.error(t("Error while adding payment"));
    }
    setIsSubmitLoading(false);
  };

  const handleClose = () => {
    props?.close();
    form.resetFields();
  };

  return (
    <Modal
      title={t("Add Distributor Fee")}
      maskClosable={false}
      onCancel={handleClose}
      open={props?.open}
      okText={t("Submit")}
      onOk={form.submit}
      confirmLoading={isSubmitLoading}
    >
      <Form form={form} layout="vertical" onFinish={handleAddPayment}>
        <Row gutter={12}>
          <Col span={12}>
            <Statistic
              title={t("Total Fee")}
              prefix={<FontAwesomeIcon icon={faIndianRupeeSign} />}
              value={IndianRuppeNumberFormattor(displayAmount?.toString())}
            />
          </Col>
        </Row>
        <Divider />
        <Row gutter={12}>
          <Col span={24}>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: t("Please enter Transaction ID"),
                },
              ]}
              name={"transactionId"}
              label={t("Transaction ID")}
            >
              <Input placeholder={t("Enter Transaction ID")} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col span={12}>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: t("Please select transaction date"),
                },
              ]}
              name={"transactionDate"}
              label={t("Date")}
            >
              <DatePicker style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: t("Please select transaction time"),
                },
              ]}
              name={"transactionTime"}
              label={t("Time")}
            >
              <TimePicker style={{ width: "100%" }} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default LoanDashboardAddDistributorFeeForm;
