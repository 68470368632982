import "./styles/App.less";

import { ConfigProvider, Layout, Spin } from "antd";
import { Suspense, useEffect, useState } from "react";

import { AuthActions } from "store/slices/authSlice";
import { BrowserRouter } from "react-router-dom";
import BrowserTabTitle from "components/util-components/BrowserTabTitle";
import Content from "./screens/Content";
import HiddenComponent from "components/util-components/HiddenComponent";
import MainLoader from "components/util-components/MainLoader";
import { RootState } from "store/store";
import SideBar from "./components/sidebar/Sidebar";
import { Themes } from "styles/themes";
import { useAppDispatch } from "store/hooks";
import { useFirebase } from "components/hooks/useFirebase";
import { useSelector } from "react-redux";

function App() {
  const dispatch = useAppDispatch();
  const { auth } = useSelector((state: RootState) => state);
  const [customLoadBool, setCustomLoadBool] = useState(true);

  useFirebase();

  useEffect(() => {
    dispatch(AuthActions.loadExistingSession(null));
  }, []);

  useEffect(() => {
    if (!auth?.loading) {
      setTimeout(() => {
        setCustomLoadBool(false);
      }, 2000);
    }
  }, [auth?.loading]);

  return (
    <ConfigProvider theme={Themes}>
      <BrowserRouter>
        <BrowserTabTitle />
        <Suspense fallback={<Spin />}>
          <HiddenComponent
            hidden={customLoadBool}
            fallBack={<MainLoader loading={auth?.loading} />}
          >
            <Layout className="app-baseLayout">
              <SideBar />
              <Layout>
                <Content />
              </Layout>
            </Layout>
          </HiddenComponent>
        </Suspense>
      </BrowserRouter>
    </ConfigProvider>
  );
}

export default App;
