import "styles/CustomerProfile.less";

import RestrictedComponent, {
  PERMISSIONS,
} from "components/util-components/RestrictedComponent";

import { CUSTOMER_STATUS } from "store/types";
import CustomerProfileAcceptance from "components/customerProfile/customer.profile.customerAcceptance";
import CustomerProfileVerification from "components/customerProfile/customer.profile.verification";
import { Divider } from "antd";
import ErrorRenderer from "components/util-components/ErrorRenderer";
import HiddenComponent from "components/util-components/HiddenComponent";
import { Outlet } from "react-router-dom";
import { RootState } from "store/store";
import { useSelector } from "react-redux";

const CustomerProfileScreen = () => {
  const { customerProfile } = useSelector(
    (state: RootState) => state.customers
  );
  const isCustomerActive = [
    CUSTOMER_STATUS.ACTIVE,
    CUSTOMER_STATUS.REJECTED,
  ].includes(customerProfile?.customerDetails?.status);
  return (
    <RestrictedComponent
      permission={PERMISSIONS.CUSTOMERS.viewDashboard}
      withAlert
    >
      <section>
        {/* <CustomerProfileHeader /> */}
        <HiddenComponent
          hidden={customerProfile?.error}
          fallBack={<ErrorRenderer status={customerProfile?.errorStatus} />}
        >
          <div className="customer-profile-component-wrapper">
            <div className="customer-profile-verification-wrapper">
              <CustomerProfileVerification />
            </div>
            <HiddenComponent hidden={isCustomerActive}>
              <Divider type="vertical" style={{ height: "100%" }} />
              <div className="customer-profile-acceptance-wrapper">
                <CustomerProfileAcceptance />
              </div>
            </HiddenComponent>
          </div>
        </HiddenComponent>
      </section>
      <Outlet />
    </RestrictedComponent>
  );
};

export default CustomerProfileScreen;
