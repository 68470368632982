import { Button, Table, Tag, Typography } from "antd";

import { ColumnsType } from "antd/es/table";
import { CustomersActions } from "store/slices/customersSlice";
import CustomersUpdateForm from "components/customers/customers.form.edit";
import ErrorRenderer from "components/util-components/ErrorRenderer";
import HiddenComponent from "components/util-components/HiddenComponent";
import { ICustomer } from "store/types";
import { NOT_APPLICABLE } from "appConstants";
import { RootState } from "store/store";
import { momentDateTimeFormat } from "components/util-components/dateTimeFormattor";
import { useAppDispatch } from "store/hooks";
import { useCustomerListPagination } from "./useCustomerPagination";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const CustomersListActive = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { customers } = useSelector((state: RootState) => state);
  const navigate = useNavigate();

  const [selectedRecord, setSelectedRecord] = useState({} as ICustomer);
  const [showEditCustomerForm, setShowEditCustomerForm] = useState(false);
  const { pagination } = useCustomerListPagination();

  const columns: ColumnsType<ICustomer> = [
    {
      title: t("ERP Customer Id"),
      key: "erpId",
      render: (_, record) => {
        return <Typography.Text copyable>{record?.customerId}</Typography.Text>;
      },
    },
    {
      title: t("labels.customers.name"),
      key: "firstName",
      render: (_, record) => {
        return (
          <Typography.Link
            onClick={() => {
              navigate(`/customers/${record?.id}/dashboard`);
            }}
          >
            {record?.firstName + " " + record?.lastName}
            {record.vip && (
              <Tag style={{ marginLeft: "5px" }} color="gold">
                VIP
              </Tag>
            )}
          </Typography.Link>
        );
      },
    },
    {
      title: t("labels.customers.phoneNumber"),
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      render: (_, record) => {
        return (
          <Typography.Text copyable>{record?.phoneNumber}</Typography.Text>
        );
      },
    },
    {
      title: t("labels.customers.email"),
      dataIndex: "email",
      key: "email",
      render: (_, record) => {
        return <Typography.Text copyable>{record?.email}</Typography.Text>;
      },
    },
    {
      title: t("Network name"),
      key: "networkName",
      render: (_, record) => {
        return <>{record?.networkName || NOT_APPLICABLE}</>;
      },
    },
    {
      title: t("Customer since"),
      key: "customerSince",
      render: (_, record) => {
        return <>{momentDateTimeFormat(record?.createTime, "date")}</>;
      },
    },
    // {
    //   title: t("Orders"),
    //   key: "loans",
    //   render: (_, record) => {
    //     return (
    //       <>
    //         <Tag color={"green"}>{`Active : ${
    //           record?.loanSummary?.activeLoanCount || 0
    //         }`}</Tag>
    //         <Tag color={"orange"}>{`Pending :  ${
    //           record?.loanSummary?.pendingLoanCount || 0
    //         }`}</Tag>
    //       </>
    //     );
    //   },
    // },
    {
      title: t("Actions"),
      key: "review",
      render: (_, record) => {
        return (
          <Button
            type="primary"
            style={{ fontWeight: "bolder" }}
            onClick={() => {
              setSelectedRecord(record);
              setShowEditCustomerForm(true);
            }}
          >
            {t("Edit")}
          </Button>
        );
      },
    },
  ];

  return (
    <HiddenComponent
      hidden={customers?.customerList?.error}
      fallBack={<ErrorRenderer status={customers?.customerList?.errorStatus} />}
    >
      <Table
        bordered
        sticky={true}
        rowKey={(data) => data?.id}
        loading={customers?.customerList?.loading}
        dataSource={customers?.customerList?.customersListActive}
        columns={columns}
        size={"small"}
        scroll={{
          x: "calc(100vw - 150px)",
          scrollToFirstRowOnChange: true,
        }}
        pagination={pagination}
      />
      <CustomersUpdateForm
        selectedCustomer={selectedRecord}
        open={showEditCustomerForm}
        onClose={() => {
          setShowEditCustomerForm(false);
          dispatch(CustomersActions.refreshList());
        }}
      />
    </HiddenComponent>
  );
};
export default CustomersListActive;
