import { Button, Modal, Space, Typography, message } from "antd";
import { Requester, formatURL } from "api/requester";

import { API_END_POINTS } from "api/urls";
import { LoansActions } from "store/slices/loansSlice";
import { RootState } from "store/store";
import { useAppDispatch } from "store/hooks";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import LoanDashboardDispatcherList from "components/loanDashboard/loan.dashboard.dispatcherList";

const LoanDashboardAssignDispatcherForm = (props: {
  open: boolean;
  close: () => void;
}) => {
  const { t } = useTranslation();
  const { details } = useSelector(
    (state: RootState) => state.loans.loanDashboard
  );
  const dispatch = useAppDispatch();
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [assignedTo, setAssignedTo] = useState("");

  const handleClose = () => {
    props?.close();
  };

  const handleSubmit = async () => {
    setIsSubmitLoading(true);
    const url = formatURL(API_END_POINTS.LOANS.assignDispatcher, {
      loanId: details?.id,
    });
    const data = { userId: assignedTo };
    console.log("data is: ", data);
    const response = await Requester.post(url, data).catch(
      ({ response }) => response
    );
    if (response?.status === 204) {
      message.success(t("Dispatcher assigned successfully"));
      handleClose();
      dispatch(LoansActions.fetchLoanById(details?.id || ""));
    } else {
      message.error(t("Error while assigning dispatcher"));
    }
    setIsSubmitLoading(false);
  };

  const handleUserSelection = (userId: string) => {
    console.log("user id is: ", userId);
    setAssignedTo(userId);
  };

  return (
    <Modal
      open={props?.open}
      onCancel={props?.close}
      maskClosable={false}
      width={1000}
      footer={[
        <Button type="default" onClick={handleClose} loading={isSubmitLoading}>
          {t("Cancel")}
        </Button>,
        <Button type="primary" onClick={handleSubmit} loading={isSubmitLoading}>
          {t("Assign Dispatcher")}
        </Button>,
      ]}
    >
      <Typography.Text strong style={{ fontSize: 16 }}>
        {t("Assign dispatcher")}
      </Typography.Text>
      <br />
      <Space direction="vertical" style={{ width: "100%" }}>
        <LoanDashboardDispatcherList onUserSelection={handleUserSelection} />
      </Space>
    </Modal>
  );
};

export default LoanDashboardAssignDispatcherForm;
