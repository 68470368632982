import { IUser, ROLES_VIEW } from "store/types";
import { Outlet, useNavigate } from "react-router-dom";
import { Button, Table, Tag, Typography } from "antd";

import { ColumnsType } from "antd/es/table";
import ErrorRenderer from "components/util-components/ErrorRenderer";
import HiddenComponent from "components/util-components/HiddenComponent";
import { RootState } from "store/store";
import { UsersActions } from "store/slices/usersSlice";
import { useAppDispatch } from "store/hooks";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import RestrictedComponent, {
  PERMISSIONS,
} from "components/util-components/RestrictedComponent";

const UsersList = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { usersList } = useSelector((state: RootState) => state.users);
  const { auth } = useSelector((state: RootState) => state);

  useEffect(() => {}, []);

  useEffect(() => {
    dispatch(UsersActions.fetchUsersList({}));
  }, []);

  const columns: ColumnsType<IUser> = [
    {
      title: t("labels.users.name"),
      key: "userName",
      render: (_, record) => {
        return (
          <>
            <Typography.Text>
              {record?.firstName + " " + record?.lastName}
            </Typography.Text>
            <br />
            <Tag color="green">{ROLES_VIEW[record?.role]}</Tag>
          </>
        );
      },
    },
    {
      title: t("labels.users.phoneNumber"),
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      render: (_, record) => {
        return (
          <>
            <Typography.Text copyable>{record?.phoneNumber}</Typography.Text>
          </>
        );
      },
    },
    {
      title: t("labels.users.email"),
      dataIndex: "emailId",
      key: "email",
      render: (_, record) => {
        return (
          <>
            <Typography.Text copyable>{record?.emailId}</Typography.Text>
          </>
        );
      },
    },
    {
      title: t("Branch"),
      dataIndex: "branchId",
      key: "branch",
    },
    {
      title: t("labels.actions"),
      key: "actions",
      fixed: "right",
      align: "center",
      render: (_: any, record: IUser) => {
        return (
          <>
            <Button
              onClick={() => {
                navigate(`${record?.id}/details`);
              }}
              type="link"
            >
              {t("View")}
            </Button>
            <RestrictedComponent permission={PERMISSIONS.USERS.edit}>
              <Button
                onClick={() => {
                  navigate(`${record?.id}/edit`);
                }}
                type="link"
              >
                {t("Edit")}
              </Button>
            </RestrictedComponent>
          </>
        );
      },
    },
  ];

  return (
    <RestrictedComponent permissions={[PERMISSIONS.USERS.getAll]} withAlert>
      <HiddenComponent
        hidden={usersList?.error}
        fallBack={<ErrorRenderer status={usersList?.errorStatus} />}
      >
        <Table
          bordered
          sticky={true}
          rowKey={(data) => data?.id}
          loading={usersList?.loading}
          dataSource={usersList?.details.filter((user: IUser) => {
            return user.id !== auth.userInfo.id;
          })}
          size={"small"}
          columns={columns}
          scroll={{
            x: "calc(100vw - 150px)",
            scrollToFirstRowOnChange: true,
          }}
        />
        <Outlet />
      </HiddenComponent>
    </RestrictedComponent>
  );
};

export default UsersList;
