import { Col, Drawer, Row, Typography } from "antd";
import { useNavigate, useParams } from "react-router-dom";

import { DetailDescriptionItem } from "components/products/product.util.components";
import ErrorRenderer from "components/util-components/ErrorRenderer";
import HiddenComponent from "components/util-components/HiddenComponent";
import Loader from "components/util-components/Loader";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useAppDispatch } from "store/hooks";
import { DistributorsActions } from "store/slices/distributorsSlice";
import { RootState } from "store/store";

const DistributorDetails = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const params = useParams();
  const { details, loading, error, errorStatus } = useSelector(
    (state: RootState) => state?.distributors?.distributorById
  );

  useEffect(() => {
    console.log("details are: ", details);
  }, [details]);

  useEffect(() => {
    console.log("fetching");
    dispatch(
      DistributorsActions.fetchDistributorById(params?.distributorId || "")
    );
  }, []);

  return (
    <Drawer
      width={800}
      open={true}
      onClose={() => {
        navigate(`/distributors`);
      }}
      maskClosable={false}
      title={
        <HiddenComponent hidden={loading} fallBack={<Loader />}>
          <div>
            <span>{`${details.networkName}'s Details`}</span>
            <Typography.Link
              underline
              onClick={() => {
                navigate(`/distributors/${details.distributorId}/edit`);
              }}
              style={{ float: "right" }}
            >
              {t("Edit")}
            </Typography.Link>
          </div>
        </HiddenComponent>
      }
    >
      <HiddenComponent hidden={loading} fallBack={<Loader />}>
        <HiddenComponent
          hidden={error}
          fallBack={<ErrorRenderer status={errorStatus} />}
        >
          <Row>
            <Col span={12}>
              <DetailDescriptionItem label={t("Network Name")}>
                {details?.networkName}
              </DetailDescriptionItem>
            </Col>
            <Col span={12}>
              <DetailDescriptionItem label={t("Contact Person Name")}>
                {details?.contactPersonName}
              </DetailDescriptionItem>
            </Col>
          </Row>

          <Row>
            <Col span={12}>
              <DetailDescriptionItem label={t("Email")}>
                {details?.email || "-"}
              </DetailDescriptionItem>
            </Col>
            <Col span={12}>
              <DetailDescriptionItem label={t("Phone number")}>
                {details?.phoneNumber || "-"}
              </DetailDescriptionItem>
            </Col>
          </Row>
          <Row>
            <Row>
              <Col span={24}>
                <DetailDescriptionItem label={t("Address")}>
                  {details?.address?.addressLine1 + ", "}
                  {details?.address?.addressLine2
                    ? details?.address?.addressLine2 + ", "
                    : ""}
                  {details?.address?.city +
                    " - " +
                    details?.address?.pincode +
                    ", " +
                    details?.address?.state +
                    ", " +
                    details?.address?.country}
                  {/* </Typography.Text> */}
                </DetailDescriptionItem>
              </Col>
            </Row>
          </Row>
        </HiddenComponent>
      </HiddenComponent>
    </Drawer>
  );
};

export default DistributorDetails;
