import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { getSessionToken, resetSecurityToken } from "store/sessionStore";

import { API_END_POINTS } from "../api/urls";
import { SECURITY_HEADER_NAME } from "../appConstants";
import qs from "qs";

const SKIP_401 = [API_END_POINTS.AUTH.login];

export const formatURL = (
  url: string,
  dynamicParams: { [key: string]: any }
) => {
  Object.keys(dynamicParams).forEach((key) => {
    url = url.replace(`{${key}}`, dynamicParams[key]);
  });
  return url;
};

export const formatError = (response: AxiosResponse) => {
  let message: string | undefined = "";

  if (response?.data?.errorMessage) {
    message = response?.data?.errorMessage;
  }
  if (
    message &&
    ['"', "'"].includes(message[0]) &&
    ['"', "'"].includes(message[message.length - 1])
  ) {
    message = message.substring(1, message.length - 1);
  }
  if (response?.status === 500) {
    message = "";
  }
  return message;
};

const AXIOS_DEFAULTS: AxiosRequestConfig = {
  withCredentials: false,
  timeout: 60000,
  headers: {
    // 'Cache-Control': 'no-cache',
    "x-netlink-client-info": `name:VIZ;version:${process.env.VERSION_CODE}_${process.env.BUILD_VERSION}`,
  },
  transformResponse: [
    function (data) {
      if (!data) {
        return { message: "" };
      }
      try {
        return JSON.parse(data || {});
      } catch (e) {
        console.log("JSON formart error", e);
        try {
          console.log("Trying with eval");
          /*eslint no-eval: "off"*/
          return eval(data);
        } catch (e) {
          console.log("Eval also failed", e);
          return {
            dataFormatError: true,
            message: "Error in processing the request",
          };
        }
      }
    },
  ],
};

const createAxiosInstance = (
  baseURL: string = "",
  options: AxiosRequestConfig | {} = {}
): AxiosInstance => {
  const instance = axios.create({
    baseURL,
    ...AXIOS_DEFAULTS,
    ...options,
    paramsSerializer: {
      serialize: (params) => {
        return qs.stringify(params, { arrayFormat: "repeat" });
      },
    },
  });

  instance.interceptors.request.use(
    async function (config) {
      if (config?.headers) {
        const token = getSessionToken();
        if (token && !config.headers[SECURITY_HEADER_NAME]) {
          config.headers[SECURITY_HEADER_NAME] = token;
        }
      }
      return config;
    },
    function (error) {
      console.log(error);
      return Promise.reject(error);
    }
  );

  instance.interceptors.response.use(
    function (axiosResponse: AxiosResponse<any, any>) {
      if (axiosResponse.data.dataFormatError) {
        return Promise.reject(axiosResponse);
      }
      return axiosResponse;
    },
    function (error) {
      if (error?.response?.data) {
        if (typeof error.response.data === "string") {
          error.response.data = {
            message: error.response.data,
          };
        }
        error.response.data.error = true;
      }
      if (
        [401].includes(error?.response?.status) &&
        !SKIP_401.includes(error?.response?.config?.url)
      ) {
        resetSecurityToken();
        window.location.reload();
      }
      return Promise.reject(error);
    }
  );
  return instance;
};

export const Requester = createAxiosInstance(process.env.BASE_PATH);
