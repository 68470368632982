import { Table, Typography } from "antd";

import { ColumnsType } from "antd/es/table";
import { EmiStatus } from "store/types";
import { IndianRuppeeView } from "components/products/product.util.components";
import { RootState } from "store/store";
import { getLoanStatusTagByStatus } from "components/loans/loans.util-components";
import { momentDateTimeFormat } from "components/util-components/dateTimeFormattor";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const CustomerStatsLoanDetails = () => {
  const { t } = useTranslation();
  const { loans, loading } = useSelector(
    (state: RootState) => state.customerStats.customerLoansAndAssets
  );
  const columns: ColumnsType<any> = [
    {
      title: t("Order Id"),
      key: "orderId",
      render: (_, record) => {
        return (
          <Typography.Link
            onClick={() => {
              window.open(`/orders/details/${record?.id}`, "_blank");
            }}
          >
            {record?.id}
          </Typography.Link>
        );
      },
    },
    {
      title: t("Order Amount"),
      key: "orderAmount",
      render: (_, record) => {
        return <IndianRuppeeView value={record?.loanAmount?.toString()} />;
      },
    },
    {
      title: t("Tenure"),
      key: "tenure",
      render: (_, record) => {
        return <>{`${record?.tenure} months`}</>;
      },
    },
    {
      title: t("Order Start Date"),
      key: "orderStartDate",
      render: (_, record) => {
        return <>{momentDateTimeFormat(record?.approveTime, "date")}</>;
      },
    },
    {
      title: t("EMIs Paid"),
      key: "emiPaid",
      render: (_, record) => {
        const emisPaidCount = record?.emis?.filter((emi: any) => {
          return emi?.status === EmiStatus.PAID;
        })?.length;
        return <>{`${emisPaidCount} / ${record?.emis?.length}`}</>;
      },
    },
    {
      title: t("Status"),
      key: "status",
      render: (_, record) => {
        return <>{getLoanStatusTagByStatus(record?.status)}</>;
      },
    },
  ];
  return (
    <>
      <Typography.Text
        style={{ fontSize: 22, color: "#104359", fontWeight: 600 }}
      >
        {t("Loan Details")}
      </Typography.Text>
      <Table
        rowKey={(data) => data?.orderId}
        bordered
        loading={loading}
        columns={columns}
        dataSource={loans}
        pagination={{
          pageSize: 5,
        }}
      />
    </>
  );
};

export default CustomerStatsLoanDetails;
