import { Button, Table, Typography } from "antd";

import { ColumnsType } from "antd/es/table";
import ErrorRenderer from "components/util-components/ErrorRenderer";
import HiddenComponent from "components/util-components/HiddenComponent";
import { IActiveAsset } from "store/types";
import Modal from "antd/es/modal/Modal";
import { RootState } from "store/store";
import { momentDateTimeFormat } from "components/util-components/dateTimeFormattor";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const LoansRequestDashboardLoanApproverModal = (props: {
  open: boolean;
  close: () => void;
  mappedAssetSetter: React.Dispatch<
    React.SetStateAction<IActiveAsset | undefined>
  >;
}) => {
  const { t } = useTranslation();
  const { customerActiveAssets } = useSelector(
    (state: RootState) => state.customers
  );

  const handleClose = () => {
    props?.close();
  };

  const columns: ColumnsType<IActiveAsset> = [
    {
      title: t("Product Name"),
      key: "productname",
      render: (_, record) => {
        return (
          <>
            <Typography.Text>{record?.productName}</Typography.Text>
            <br />
            <Typography.Text type="secondary">{record?.mac}</Typography.Text>
          </>
        );
      },
    },
    {
      title: t("Order ID"),
      key: "loanId",
      dataIndex: "loanId",
    },
    {
      title: t("Order Start Date"),
      key: "orderStartDate",
      render: (_, record) => {
        return <>{momentDateTimeFormat(record?.loanStartDate, "date")}</>;
      },
    },
    {
      title: t("Order End Date"),
      key: "orderEndDate",
      render: (_, record) => {
        return <>{momentDateTimeFormat(record?.loanStartDate, "date")}</>;
      },
    },
    {
      title: t("Existing Child Orders"),
      key: "childOrders",
      render: (_, record) => {
        const childLoans = record?.activeChildLoanIds;
        return (
          <>
            {childLoans?.length === 0 ? (
              "Not Available"
            ) : (
              <>
                {childLoans?.map((loanId) => {
                  return (
                    <>
                      {loanId}
                      <br />
                    </>
                  );
                })}
              </>
            )}
            <br />
          </>
        );
      },
    },
  ];
  return (
    <Modal
      open={props?.open}
      onCancel={handleClose}
      maskClosable={false}
      title={t("Map")}
      width={1200}
      footer={[
        <HiddenComponent hidden={customerActiveAssets?.error}>
          <Button
            key={"map"}
            type="primary"
            onClick={handleClose}
          >{`Map`}</Button>
        </HiddenComponent>,
      ]}
    >
      <HiddenComponent
        hidden={customerActiveAssets?.error}
        fallBack={<ErrorRenderer />}
      >
        <Table
          rowKey={(record) => record?.assetId}
          rowSelection={{
            type: "radio",
            onChange: (_, selectedRows: IActiveAsset[]) => {
              props?.mappedAssetSetter(selectedRows?.at(0));
            },
          }}
          columns={columns}
          dataSource={customerActiveAssets?.details}
          pagination={false}
          loading={customerActiveAssets?.loading}
        />
      </HiddenComponent>
    </Modal>
  );
};

export default LoansRequestDashboardLoanApproverModal;
