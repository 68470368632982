import "styles/loader.css";

import { useEffect, useState } from "react";

import { ReactComponent as Logo } from "../../assets/images/logo_New.svg";

const MainLoader = (props: { loading: boolean }) => {
  const [animateClassName, setAnimateClassName] = useState(
    "main-loader-wrapper"
  );
  useEffect(() => {
    if (props.loading) {
      setAnimateClassName("main-loader-wrapper");
    } else {
      setAnimateClassName("main-loader-wrapper-hidden");
    }
  }, [props?.loading]);
  return (
    <div className={animateClassName}>
      <Logo width={200} />
    </div>
  );
};

export default MainLoader;
