import { CustomersActions } from "store/slices/customersSlice";
import { RootState } from "store/store";
import { useAppDispatch } from "store/hooks";
import { useSelector } from "react-redux";

// const customerListPagination = {};
export function useCustomerListPagination() {
  const { customers } = useSelector((state: RootState) => state);

  const dispatch = useAppDispatch();

  const pagination = {
    showSizeChanger: true,
    pageSize: customers?.customerList?.pagination?.pageSize,
    current:
      customers?.customerList?.pagination?.currentPageNumber || undefined,
    total:
      (customers?.customerList?.pagination?.pageSize || 0) *
      customers?.customerList?.pagination?.pageTokens?.length,
    onChange: (pageNumber: any, pageSize: any) => {
      dispatch(CustomersActions.refreshList());
      if (customers?.customerList?.pagination?.pageSize !== pageSize) {
        dispatch(
          CustomersActions.setCustomerListPagination({
            currentPageNumber: 1,
            pageSize: pageSize,
            pageTokens: [""],
          })
        );
      } else {
        dispatch(CustomersActions.setCustomerListPageNumber(pageNumber));
      }
    },
  };

  return { pagination };
}
