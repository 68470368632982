import "styles/Customers.less";

import { CUSTOMER_KYC_STATUS, CUSTOMER_STATUS } from "store/types";
import { Tabs, TabsProps } from "antd";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { CustomersActions } from "store/slices/customersSlice";
import CustomersListActive from "./customers.list.active";
import CustomersListKYCPending from "./customers.list.kycPending";
import CustomersListRejected from "./customers.list.rejected";
import { RootState } from "store/store";
import { useAppDispatch } from "store/hooks";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const CustomersList = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { customerList } = useSelector((state: RootState) => state.customers);
  const pagination = customerList?.pagination;
  const location = useLocation();
  const currentTab = location?.pathname?.split("/")?.at(3) || "";
  const tabPaths = {
    SUBMITTED: "kyc-pending",
    COMPLETED: "active",
    REJECTED: "rejected",
  };
  const tabParams = {
    SUBMITTED: {
      status: [
        CUSTOMER_STATUS.KYC_PENDING,
        CUSTOMER_STATUS.ERP_MAPPING_PENDING,
        CUSTOMER_STATUS.CREDIT_SCORE_VERIFICATION_PENDING,
        CUSTOMER_STATUS.VERIFICATION_COMPLETED,
      ].join(","),
      kycStatus: [
        CUSTOMER_KYC_STATUS.SUBMITTED,
        CUSTOMER_KYC_STATUS.RESUBMITTED,
        CUSTOMER_KYC_STATUS.COMPLETED,
      ].join(","),
      pageSize: pagination?.pageSize,
      pageToken: pagination?.pageTokens?.[pagination?.currentPageNumber - 1],
    },
    COMPLETED: {
      status: CUSTOMER_STATUS.ACTIVE,
      pageSize: pagination?.pageSize,
      pageToken: pagination?.pageTokens?.[pagination?.currentPageNumber - 1],
    },
    REJECTED: {
      status: CUSTOMER_STATUS.REJECTED,
      pageSize: pagination?.pageSize,
      pageToken: pagination?.pageTokens?.[pagination?.currentPageNumber - 1],
    },
  };
  const [requestParams, setRequestParams] = useState<{
    status: string;
    kycStatus?: string;
    pageSize: number;
    pageToken?: string;
  }>(tabParams[customerList?.selectedTab as keyof typeof tabParams]);

  useEffect(() => {
    if (Object.values(tabPaths).includes(currentTab)) {
      navigate(
        `/customers/list/${
          tabPaths[customerList?.selectedTab as keyof typeof tabPaths]
        }`,
        { replace: true }
      );
    } else {
      navigate(`/customers/list/kyc-pending`, { replace: true });
    }
  }, [customerList?.selectedTab]);

  useEffect(() => {
    const tab = customerList?.selectedTab;
    setRequestParams({ ...tabParams[tab as keyof typeof tabParams] });
    const paginationParams = {
      ...requestParams,
      pageSize: pagination?.pageSize,
      pageToken: pagination?.pageTokens?.[pagination?.currentPageNumber - 1],
    };
    dispatch(CustomersActions.fetchCustomersList({ paginationParams, tab }));
  }, [customerList?.refreshToken]);

  const handleTabChange = (key: string) => {
    dispatch(CustomersActions.setSelectedTab(key as CUSTOMER_KYC_STATUS));
    dispatch(CustomersActions.resetPaginationParams());
    setRequestParams(tabParams[key as keyof typeof tabParams]);
    dispatch(CustomersActions.refreshList());
  };
  const items: TabsProps["items"] = [
    {
      key: CUSTOMER_KYC_STATUS.SUBMITTED,
      label: t("labels.kycPending"),
      children: <CustomersListKYCPending />,
    },
    {
      key: CUSTOMER_KYC_STATUS.COMPLETED,
      label: t("labels.active"),
      children: <CustomersListActive />,
    },
    {
      key: CUSTOMER_KYC_STATUS.REJECTED,
      label: t("labels.rejected"),
      children: <CustomersListRejected />,
    },
  ];

  return (
    <Tabs
      defaultActiveKey={customerList?.selectedTab}
      items={items}
      onChange={handleTabChange}
    />
  );
};

export default CustomersList;
