import "styles/CustomerDashboard.less";

import { Divider, Tabs, TabsProps } from "antd";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";

import { CUSTOMER_STATUS } from "store/types";
import CustomerDashboardHeader from "components/customerDashboard/customer.dashboard.header";
import CustomerDashboardPersonalDetails from "components/customerDashboard/customer.dashboard.personalDetails";
import CustomerProfileScreen from "screens/customerProfile/customer.profile.screen";
import CustomerStatsScreen from "screens/customerStats/customer.stats.screen";
import { CustomersActions } from "store/slices/customersSlice";
import { RootState } from "store/store";
import { useAppDispatch } from "store/hooks";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

enum ICustomerDashboardTabs {
  DASHBOARD = "dashboard",
  PROFILE = "profile",
}
const CustomerDashboardScreen = () => {
  const dispatch = useAppDispatch();
  const params = useParams();
  const location = useLocation();
  const currentRequestedTab = location?.pathname?.split("/")?.at(4) || "";
  const [activeKey, setActiveKey] = useState<ICustomerDashboardTabs>(
    ICustomerDashboardTabs.PROFILE
  );
  const { customerDetails } = useSelector(
    (state: RootState) => state.customers.customerProfile
  );
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(CustomersActions.fetchCustomerById(params?.customerId || ""));
    if (
      Object.values(ICustomerDashboardTabs).includes(
        currentRequestedTab as ICustomerDashboardTabs
      )
    ) {
      navigate(`/customers/${params?.customerId}/${currentRequestedTab}`, {
        replace: true,
      });
      setActiveKey(currentRequestedTab as ICustomerDashboardTabs);
    } else {
      navigate(`/customers/${params?.customerId}/profile`, {
        replace: true,
      });
    }
  }, []);

  const handleTabChange = (tab: string) => {
    navigate(`/customers/${params?.customerId}/${tab}`);
    setActiveKey(tab as ICustomerDashboardTabs);
  };

  const items: TabsProps["items"] = [
    {
      key: ICustomerDashboardTabs.DASHBOARD,
      label: t("Dashboard"),
      children: <CustomerStatsScreen />,
      disabled: [
        CUSTOMER_STATUS.CREDIT_SCORE_VERIFICATION_PENDING,
        CUSTOMER_STATUS.REJECTED,
        CUSTOMER_STATUS.KYC_PENDING,
        CUSTOMER_STATUS.VERIFICATION_COMPLETED,
      ].includes(customerDetails?.status),
    },
    {
      key: ICustomerDashboardTabs.PROFILE,
      label: t("Profile"),
      children: <CustomerProfileScreen />,
    },
  ];
  return (
    <section style={{ width: "100%", height: "100vh" }}>
      <CustomerDashboardHeader />
      <div className="customer-dashboard-components-wrapper">
        <div className="customer-dashboard-personal-details-wrapper">
          <CustomerDashboardPersonalDetails />
        </div>
        <Divider type="vertical" style={{ height: "100%" }} />
        <div className="customer-dashboard-tabs-wrapper">
          <Tabs
            activeKey={activeKey}
            items={items}
            onChange={handleTabChange}
          />
        </div>
      </div>
      <Outlet />
    </section>
  );
};

export default CustomerDashboardScreen;
