import { Button, Table, Typography } from "antd";

import { ColumnsType } from "antd/es/table";
import ErrorRenderer from "components/util-components/ErrorRenderer";
import HiddenComponent from "components/util-components/HiddenComponent";
import { ILoansRequest } from "store/types";
import { IndianRuppeeView } from "components/products/product.util.components";
import { LoansListActions } from "store/slices/loansListSlice";
import { RootState } from "store/store";
import { ShowProductsInTable } from "components/loans/loans.util-components";
import { momentDateTimeFormat } from "components/util-components/dateTimeFormattor";
import { useAppDispatch } from "store/hooks";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const LoansRequestListRejected = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { rejectedRequestsTable } = useSelector(
    (state: RootState) => state?.loansList
  );

  useEffect(() => {
    dispatch(LoansListActions.fetchRejectedLoansList({}));
  }, []);

  const columns: ColumnsType<ILoansRequest> = [
    {
      title: t("Order Id"),
      key: "loanId",
      render: (_, record) => {
        return <Typography.Text copyable>{record?.id}</Typography.Text>;
      },
    },
    {
      title: t("Customer Name"),
      key: "customerName",
      render: (_, record) => {
        return (
          <Typography.Link
            onClick={() => {
              navigate(`/customers/${record?.customerId}/profile`);
            }}
            strong
          >
            {record?.customerName}
          </Typography.Link>
        );
      },
    },
    {
      title: t("Products"),
      key: "products",
      render: (_, record) => {
        return <ShowProductsInTable productList={record?.purchasedItems} />;
      },
    },
    {
      title: t("Amount"),
      key: "amount",
      render: (_, record) => {
        return (
          <>
            <IndianRuppeeView value={record?.loanAmount?.toString()} />
          </>
        );
      },
    },
    {
      title: t("Down Payment"),
      key: "downPayment",
      render: (_, record) => {
        return <IndianRuppeeView value={record?.downPayment?.toString()} />;
      },
    },
    {
      title: t("Tenure"),
      key: "loanDuration",
      render: (_, record) => {
        return <>{record?.tenure + " months"}</>;
      },
    },
    {
      title: t("Close Time"),
      key: "createdTime",
      render: (_, record) => {
        return <>{momentDateTimeFormat(record?.closeTime)}</>;
      },
    },
    {
      title: t("Actions"),
      key: "actions",
      render: (_, record) => {
        return (
          <Button
            type="link"
            onClick={() => {
              navigate(`/orders/loan-request/${record?.id}`);
            }}
          >
            {t("View")}
          </Button>
        );
      },
    },
  ];

  return (
    <HiddenComponent
      hidden={rejectedRequestsTable?.error}
      fallBack={<ErrorRenderer status={rejectedRequestsTable?.errorStatus} />}
    >
      <Table
        bordered
        sticky={true}
        rowKey={(data) => data?.id}
        loading={rejectedRequestsTable?.loading}
        dataSource={rejectedRequestsTable?.details}
        columns={columns}
        size={"small"}
        scroll={{
          x: "calc(100vw - 150px)",
          scrollToFirstRowOnChange: true,
        }}
      />
    </HiddenComponent>
  );
};

export default LoansRequestListRejected;
