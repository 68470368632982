import LoansHeader from 'components/loans/loans.header';
import LoansList from 'components/loans/loans.list';
import LoansSeachComponent from 'components/loans/loans.search';

const LoanScreen = () => {
  return (
    <div className={'loans-list-screen-container'}>
      <LoansHeader />
      <LoansSeachComponent />
      <LoansList />
    </div>
  );
};

export default LoanScreen;
