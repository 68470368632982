import "styles/Loans.less";

import {
  Alert,
  Button,
  Divider,
  Form,
  Input,
  Radio,
  Space,
  Spin,
  Tooltip,
  Typography,
  message,
} from "antd";
import {
  IActiveAsset,
  ILoanRequestScreenTabs,
  ILoanRequestStatus,
  ILoanType,
  IProductItem,
} from "store/types";
import React, { ReactNode, useEffect, useState } from "react";
import { Requester, formatURL } from "api/requester";
import { useForm, useWatch } from "antd/es/form/Form";

import { API_END_POINTS } from "api/urls";
import { COLORS } from "appConstants";
import { CustomersActions } from "store/slices/customersSlice";
import HiddenComponent from "components/util-components/HiddenComponent";
import { IndianRuppeeView } from "components/products/product.util.components";
import { LoansListActions } from "store/slices/loansListSlice";
import LoansRequestDashboardLoanApproverModal from "./loans.request.dashboard.LoanApproverModal";
import { RootState } from "store/store";
import { InfoCircleOutlined, SelectOutlined } from "@ant-design/icons";
import moment from "moment";
import { momentDateTimeFormat } from "components/util-components/dateTimeFormattor";
import { useAppDispatch } from "store/hooks";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const LoansRequestDashboardForm = () => {
  const { t } = useTranslation();
  const { requestDetails, customerDetails } = useSelector(
    (state: RootState) => state.loans.loansRequestDashboard
  );
  const navigate = useNavigate();
  const [form] = useForm();
  const [formValues, setFormValues] = useState({
    closingRemark: "",
  });
  const approveZeroDownPayment = useWatch("zeroDownPayment", form);
  const [isApproveLoading, setIsApproveLoading] = useState(false);
  const [isRejectLoading, setIsRejectLoading] = useState(false);
  const [isApproveModalOpen, setIsApproveModalOpen] = useState(false);
  const isPrimaryItem = requestDetails?.details?.loanType === ILoanType.PRIMARY;
  const dispatch = useAppDispatch();
  const [mappedAsset, setMappedAsset] = useState<IActiveAsset>();
  const isAssetMapped =
    requestDetails?.details?.loanType === ILoanType.PRIMARY || !!mappedAsset;

  useEffect(() => {
    form.setFieldsValue({
      zeroDownPayment: false,
    });
  }, []);

  const handleSubmit = async (
    loanAcceptance: "APPROVE" | "REJECT",
    formValues: any
  ) => {
    const url = formatURL(
      loanAcceptance === "APPROVE"
        ? API_END_POINTS.LOANS.approveLoanRequest
        : API_END_POINTS.LOANS.rejectLoanRequest,
      { loanRequestId: requestDetails?.details?.id }
    );
    const data = {
      ...formValues,
      assetId: mappedAsset?.assetId,
      loanId: mappedAsset?.loanId,
    };
    if (loanAcceptance === "APPROVE") {
      setIsApproveLoading(true);
    } else {
      setIsRejectLoading(true);
    }
    const response = await Requester.post(url, data).catch(
      ({ response }) => response
    );
    if (response?.status === 204) {
      message.success(t("Successfully submitted"));
      dispatch(
        LoansListActions.setCurrentRequestTab(
          ILoanRequestScreenTabs.NEW_REQUESTS
        )
      );
      navigate("/orders/requests-list");
    } else {
      message.error(t("messages.error.generic"));
    }
    if (loanAcceptance === "APPROVE") {
      setIsApproveLoading(false);
    } else {
      setIsRejectLoading(false);
    }
  };
  return (
    <HiddenComponent
      hidden={customerDetails?.loading || requestDetails?.loading}
      fallBack={<Spin style={{ width: "100%" }} />}
    >
      <Typography.Text
        style={{ fontSize: 22, color: "#104359", fontWeight: 600 }}
      >
        {t("Requested Order Details")}
      </Typography.Text>
      <Typography.Text
        style={{ color: "#969191", fontWeight: 600, paddingLeft: 8 }}
      >
        {`${moment(requestDetails?.details?.createTime + "Z").fromNow()}`}
        {/* 'Z' is to convert it to UTC */}
      </Typography.Text>
      <br />
      <br />
      <div style={{ display: "flex" }}>
        <div
          style={{
            display: "flex",
            borderRight: "1px solid #0505050f",
          }}
        >
          <div style={{ width: 250 }}>
            <DetailDescriptionItem
              title={t("Order Amount")}
              value={requestDetails?.details?.loanAmount?.toString()}
              rupeeSymbol
              tooltip="Order Amount is exclusive of down payment"
            />
            <br />
            <HiddenComponent hidden={!isPrimaryItem}>
              <DetailDescriptionItem
                title={t("Down Payment")}
                value={
                  requestDetails?.details?.optedForZeroDownPayment
                    ? "0"
                    : requestDetails?.details?.downPayment?.toString()
                }
                rupeeSymbol
              />
              <br />
            </HiddenComponent>
            <DetailDescriptionItem
              title={t("EMI")}
              value={requestDetails?.details?.monthlyEmi}
              rupeeSymbol
              tooltip="Order Amount / Tenure"
            />
            <br />
            <DetailDescriptionItem
              title={t("Products")}
              value={
                <>
                  {requestDetails?.details?.purchasedItems?.map(
                    (item: IProductItem) => {
                      return (
                        <React.Fragment key={item?.id}>
                          <Typography.Link
                            style={{
                              fontSize: 18,
                            }}
                            onClick={() => {
                              window.open(`/products/${item?.id}/details`);
                            }}
                          >
                            {item?.name}
                          </Typography.Link>{" "}
                          <Typography.Text>{`(Quantity : ${item?.count})`}</Typography.Text>
                          <br />
                        </React.Fragment>
                      );
                    }
                  )}
                </>
              }
            />
          </div>
          <div style={{ width: 250 }}>
            <DetailDescriptionItem
              title={t("Tenure")}
              value={requestDetails?.details?.tenure?.toString() + " months"}
            />
            <br />
            <DetailDescriptionItem
              title={t("Processing Fee")}
              value={requestDetails?.details?.processingFee}
              rupeeSymbol
              hidden={!isPrimaryItem}
            />
          </div>
        </div>
        <div
          style={{
            paddingLeft: 40,
          }}
        >
          <Form form={form}>
            <HiddenComponent
              hidden={
                (isPrimaryItem &&
                  !requestDetails?.details?.optedForZeroDownPayment) ||
                requestDetails?.details?.status ===
                  ILoanRequestStatus.REJECTED ||
                !isPrimaryItem
              }
            >
              <DetailDescriptionItem
                title={t("Actual Down Payment")}
                value={requestDetails?.details?.downPayment}
                rupeeSymbol
              />
              <br />
              <Typography.Text style={{ fontSize: 16 }}>
                {t("Approve zero down payment request?")}
              </Typography.Text>
              <br />
              <Typography.Text style={{ fontSize: 14, color: "#969191" }}>
                {t("* Approving will change the EMI amount")}
              </Typography.Text>
              <Form.Item
                name={"zeroDownPayment"}
                rules={[
                  {
                    required: true,
                    message: t("Please select a value"),
                  },
                ]}
              >
                <Radio.Group optionType="default">
                  <Radio.Button value={true}>{t("Yes")}</Radio.Button>
                  <Radio.Button value={false}>{t("No")}</Radio.Button>
                </Radio.Group>
              </Form.Item>
            </HiddenComponent>
            <Typography.Text
              style={{
                color: COLORS.LOAN_REQUEST_DASHBOARD.LOAN_REQUEST_TITLE,
              }}
            >
              {t("Summary")}
            </Typography.Text>
            <br />
            <Space>
              <EMIDetailsCard
                title={t("Monthly EMI")}
                value={
                  approveZeroDownPayment
                    ? requestDetails?.details?.monthlyEmi?.toString()
                    : requestDetails?.details?.monthlyEmi?.toString()
                }
              />
              <EMIDetailsCard
                title={t("Overall Monthly EMI")}
                value={
                  (approveZeroDownPayment
                    ? (
                        requestDetails?.details?.monthlyEmi +
                        (customerDetails?.details?.loanSummary?.monthlyEmi ||
                          0 ||
                          0)
                      )?.toString()
                    : (
                        requestDetails?.details?.monthlyEmi +
                        (customerDetails?.details?.loanSummary?.monthlyEmi || 0)
                      )?.toString()) || "0"
                }
              />
              <div className="loan-request-form-emi-details-card">
                <div>
                  <Typography.Text
                    style={{
                      fontSize: 14,
                      color: COLORS.LOAN_REQUEST_DASHBOARD.LOAN_REQUEST_TITLE,
                    }}
                  >
                    {t("Start Date")}
                  </Typography.Text>
                  <br />
                  <Typography.Text
                    style={{
                      fontSize: 14,
                      color: COLORS.LOAN_REQUEST_DASHBOARD.LOAN_REQUEST_VALUES,
                    }}
                  >
                    {momentDateTimeFormat(
                      requestDetails?.details?.startDate,
                      "date"
                    )}
                  </Typography.Text>
                </div>
                <div>
                  <Typography.Text
                    style={{
                      fontSize: 14,
                      color: COLORS.LOAN_REQUEST_DASHBOARD.LOAN_REQUEST_TITLE,
                    }}
                  >
                    {t("End Date")}
                  </Typography.Text>
                  <br />
                  <Typography.Text
                    style={{
                      fontSize: 14,
                      color: COLORS.LOAN_REQUEST_DASHBOARD.LOAN_REQUEST_VALUES,
                    }}
                  >
                    {momentDateTimeFormat(
                      requestDetails?.details?.endDate,
                      "date"
                    )}
                  </Typography.Text>
                </div>
              </div>
            </Space>
            <Divider />
            <HiddenComponent hidden={isPrimaryItem}>
              <Typography.Text
                style={{
                  color: COLORS.LOAN_REQUEST_DASHBOARD.LOAN_REQUEST_TITLE,
                }}
              >
                {t("Map a Primary Product")}
              </Typography.Text>
              <br />
              <Button
                onClick={() => {
                  dispatch(
                    CustomersActions.fetchActiveAssets(
                      customerDetails?.details?.id
                    )
                  );
                  setIsApproveModalOpen(true);
                }}
                type="primary"
                icon={<SelectOutlined />}
              >
                {t("Map")}
              </Button>
              <HiddenComponent hidden={!isAssetMapped}>
                <br />
                <Typography.Text type="secondary">
                  {t("Mapped to - ")}
                </Typography.Text>
                <Typography.Text>{`${mappedAsset?.productName}(${mappedAsset?.mac})`}</Typography.Text>
              </HiddenComponent>
              <br />
              <br />
            </HiddenComponent>
            <HiddenComponent
              hidden={
                requestDetails?.details?.status === ILoanRequestStatus.REJECTED
              }
              fallBack={<RejectedNotification />}
            >
              <Form.Item
                name={"closingRemark"}
                rules={[
                  {
                    required: true,
                    message: t("Please enter remarks"),
                  },
                ]}
              >
                <Input.TextArea
                  style={{ height: 100 }}
                  placeholder={t("Remarks")}
                  onChange={(e) => {
                    setFormValues({
                      ...formValues,
                      closingRemark: e?.target?.value,
                    });
                  }}
                />
              </Form.Item>
              <Space
                align="end"
                style={{ width: "100%", justifyContent: "flex-end" }}
              >
                <Button
                  loading={isRejectLoading}
                  onClick={() => {
                    if (formValues?.closingRemark === "") {
                      form.validateFields();
                    } else {
                      handleSubmit("REJECT", formValues);
                    }
                  }}
                  disabled={approveZeroDownPayment || isApproveLoading}
                >
                  {t("Reject")}
                </Button>
                <Tooltip
                  title={!isAssetMapped ? t("Map to an asset first") : ""}
                >
                  <Button
                    loading={isApproveLoading}
                    onClick={() => {
                      if (formValues?.closingRemark === "") {
                        form.validateFields();
                      } else {
                        handleSubmit("APPROVE", formValues);
                      }
                    }}
                    type="primary"
                    disabled={
                      !(
                        approveZeroDownPayment ||
                        !requestDetails?.details?.optedForZeroDownPayment
                      ) ||
                      !isAssetMapped ||
                      isRejectLoading
                    }
                  >
                    {t("Approve")}
                  </Button>
                </Tooltip>
              </Space>
            </HiddenComponent>
          </Form>
        </div>
      </div>
      <LoansRequestDashboardLoanApproverModal
        open={isApproveModalOpen}
        close={() => {
          setIsApproveModalOpen(false);
        }}
        mappedAssetSetter={setMappedAsset}
      />
    </HiddenComponent>
  );
};
export default LoansRequestDashboardForm;

const DetailDescriptionItem = (props: {
  title: string | ReactNode;
  value: string | ReactNode;
  rupeeSymbol?: boolean;
  hidden?: boolean;
  tooltip?: string;
}) => {
  return (
    <HiddenComponent hidden={props?.hidden || false}>
      <Typography.Text
        style={{
          fontSize: 16,
          color: COLORS.LOAN_REQUEST_DASHBOARD.LOAN_REQUEST_TITLE,
        }}
      >
        {props?.title} &nbsp;
        {props.tooltip && (
          <Tooltip title={props?.tooltip}>
            <InfoCircleOutlined />
          </Tooltip>
        )}
      </Typography.Text>
      <br />
      {props?.rupeeSymbol ? (
        <IndianRuppeeView
          value={props?.value as string}
          fontSize={18}
          fontColor={COLORS.LOAN_REQUEST_DASHBOARD.LOAN_REQUEST_VALUES}
        />
      ) : (
        <Typography.Text
          style={{
            fontSize: 18,
            color: COLORS.LOAN_REQUEST_DASHBOARD.LOAN_REQUEST_VALUES,
          }}
        >
          {props?.value}
        </Typography.Text>
      )}
      <br />
    </HiddenComponent>
  );
};

const EMIDetailsCard = (props: {
  title: string;
  value: string;
  tooltip?: string;
}) => {
  return (
    <div className="loan-request-form-emi-details-card">
      <Typography.Text
        style={{
          fontSize: 14,
          color: COLORS.LOAN_REQUEST_DASHBOARD.LOAN_REQUEST_TITLE,
        }}
      >
        {props?.title}
      </Typography.Text>
      <br />
      <Typography.Text>
        <IndianRuppeeView
          value={props?.value}
          fontSize={24}
          fontColor={COLORS.LOAN_REQUEST_DASHBOARD.LOAN_REQUEST_VALUES}
        />
      </Typography.Text>
    </div>
  );
};

const RejectedNotification = () => {
  const { t } = useTranslation();
  const { details } = useSelector(
    (state: RootState) => state.loans.loansRequestDashboard?.requestDetails
  );
  const rejectedBy = details?.closedBy;
  const rejectedDate = momentDateTimeFormat(details?.closeTime);
  return (
    <Alert
      type="error"
      description={
        <section>
          <Typography.Text underline>{t("Rejection Details")}</Typography.Text>
          <br />
          <Typography.Text>{`" ${details?.closingRemark?.note} "`}</Typography.Text>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography.Text strong>{rejectedBy}</Typography.Text>
            <Typography.Text strong>{rejectedDate}</Typography.Text>
          </div>
        </section>
      }
    />
  );
};
