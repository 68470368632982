import { Alert, Typography } from "antd";

import { RootState } from "store/store";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const CustomerProfileRejectionMessage = () => {
  const { t } = useTranslation();
  const { customerDetails } = useSelector(
    (state: RootState) => state.customers.customerProfile
  );
  const rejectedReason = customerDetails?.remarks;
  return (
    <Alert
      type="error"
      style={{ width: 400 }}
      description={
        <section>
          <Typography.Text>
            {t("Customer was rejected with the following reason")}
          </Typography.Text>
          <br />
          <Typography.Text>{`" ${rejectedReason} "`}</Typography.Text>
        </section>
      }
    />
  );
};

export default CustomerProfileRejectionMessage;
