import {
  Alert,
  Button,
  Col,
  Drawer,
  Form,
  Input,
  Popover,
  Row,
  Select,
  Typography,
  message,
} from "antd";
import { IDistributor, IUser, ROLES, ROLES_VIEW } from "store/types";
import RestrictedComponent, {
  PERMISSIONS,
} from "components/util-components/RestrictedComponent";
import { useEffect, useState } from "react";

import { API_END_POINTS } from "api/urls";
import FormItem from "antd/es/form/FormItem";
import NumericInput from "components/util-components/NumericInput";
import { PASSWORD_REGEX } from "appConstants";
import { Requester } from "api/requester";
import { RootState } from "store/store";
import { UsersActions } from "store/slices/usersSlice";
import { useAppDispatch } from "store/hooks";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useWatch } from "antd/es/form/Form";
import { DistributorsActions } from "store/slices/distributorsSlice";

const UserForm = () => {
  const { t } = useTranslation();
  const [userForm] = Form.useForm();
  const navigate = useNavigate();
  const [isPasswordToolTipOpen, setIsPasswordToolTipOpen] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [alertDescription, setAlertDescription] = useState("");
  const { users } = useSelector((state: RootState) => state);
  const dispatch = useAppDispatch();
  const passwordWatch = useWatch("password", userForm);
  const distributorIdWatch = useWatch("distributorId", userForm);
  const [rolesOptions, setRolesOptions] = useState([{}]);

  const { distributorsList } = useSelector(
    (state: RootState) => state.distributors
  );

  useEffect(() => {
    dispatch(DistributorsActions.fetchDistributorsList({}));
  }, []);

  const handleCancel = () => {
    navigate("/users");
  };

  useEffect(() => {
    dispatch(UsersActions.fetchBranchesList(null));
    updateRoles();
  }, []);

  const updateRoles = () => {
    if (!distributorIdWatch) {
      const options = Object.keys(ROLES).map((role) => {
        return {
          value: role,
          label: ROLES_VIEW[role as keyof typeof ROLES_VIEW],
        };
      });
      setRolesOptions(options);
    } else {
      setRolesOptions([
        {
          value: ROLES.DISTRIBUTOR_ADMIN,
          label: ROLES_VIEW[ROLES.DISTRIBUTOR_ADMIN],
        },
      ]);
    }
  };

  useEffect(() => {
    updateRoles();
  }, [distributorIdWatch]);

  // const rolesOptions = Object.keys(ROLES).map((role) => {
  //   return { value: role, label: ROLES_VIEW[role as keyof typeof ROLES_VIEW] };
  // });

  const handleSubmit = async (data: IUser) => {
    // data.phoneNumber = "+91" + data?.phoneNumber;
    setIsSubmitLoading(true);
    const response = await Requester.post(
      API_END_POINTS.OPERATORS.users,
      data
    ).catch(({ response }) => response);
    if (response?.status === 201) {
      message.success(t("User created successfully"));
      navigate("/users");
      dispatch(UsersActions.fetchUsersList({}));
      userForm.resetFields();
    } else {
      const error = t("messages.error.generic");
      setAlertDescription(error);
    }
    setIsSubmitLoading(false);
  };

  return (
    <Drawer
      open={true}
      width={800}
      onClose={() => handleCancel()}
      maskClosable={false}
      footer={
        <RestrictedComponent permission={PERMISSIONS.USERS.create}>
          <Row>
            <Col span={24} style={{ textAlign: "right" }}>
              <FormItem className="form-actionbuttons">
                <Button
                  type="primary"
                  htmlType="submit"
                  onClick={userForm.submit}
                  loading={isSubmitLoading}
                >
                  {t("labels.submit")}
                </Button>
              </FormItem>
            </Col>
          </Row>
        </RestrictedComponent>
      }
    >
      <RestrictedComponent permission={PERMISSIONS.USERS.create} withAlert>
        <Form
          name={"userForm"}
          form={userForm}
          initialValues={{
            distributorId: undefined,
            role: undefined,
            phoneNumber: "",
            emailId: "",
            firstName: "",
            lastName: "",
            password: "",
          }}
          layout={"vertical"}
          onFinish={handleSubmit}
        >
          <Row gutter={24}>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 12 }}
              xl={{ span: 12 }}
              xxl={{ span: 12 }}
            >
              <Form.Item name={"distributorId"} label={t("Distributor")}>
                <Select
                  showSearch
                  allowClear
                  placeholder={t("Select Distributor")}
                >
                  {distributorsList.details.map((distributor: IDistributor) => {
                    return (
                      <Select.Option
                        key={distributor?.distributorId}
                        value={distributor?.distributorId}
                      >
                        {distributor?.networkName}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 12 }}
              xl={{ span: 12 }}
              xxl={{ span: 12 }}
            >
              <FormItem
                name={"firstName"}
                label={t("labels.users.firstName")}
                rules={[
                  {
                    required: true,
                    message: t("messages.validations.nameRequired"),
                  },
                ]}
              >
                <Input placeholder={t("placeholders.name")}></Input>
              </FormItem>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 12 }}
              xl={{ span: 12 }}
              xxl={{ span: 12 }}
            >
              <FormItem
                name={"lastName"}
                label={t("labels.users.lastName")}
                rules={[
                  {
                    required: true,
                    message: t("messages.validations.nameRequired"),
                  },
                ]}
              >
                <Input placeholder={t("placeholders.name")}></Input>
              </FormItem>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 12 }}
              xl={{ span: 12 }}
              xxl={{ span: 12 }}
            >
              <FormItem
                name={"role"}
                label={t("labels.users.role")}
                rules={[
                  {
                    required: true,
                    message: t("messages.validations.roleRequired"),
                  },
                ]}
              >
                <Select
                  options={rolesOptions}
                  showSearch
                  allowClear
                  placeholder={t("placeholders.role")}
                />
              </FormItem>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 12 }}
              xl={{ span: 12 }}
              xxl={{ span: 12 }}
            >
              <FormItem
                name={"branchId"}
                label={t("Branch")}
                rules={[
                  {
                    required: !distributorIdWatch,
                    message: t("Branch is required"),
                  },
                ]}
              >
                <Select
                  showSearch
                  allowClear
                  placeholder={t("Select Branch")}
                  disabled={distributorIdWatch}
                >
                  {users?.branches?.details?.map((branch) => {
                    return (
                      <Select.Option key={branch?.id} value={branch?.id}>
                        {branch?.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </FormItem>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 12 }}
              xl={{ span: 12 }}
              xxl={{ span: 12 }}
            >
              <FormItem
                name={"emailId"}
                label={t("labels.users.email")}
                rules={[
                  {
                    required: true,
                    message: t("messages.validations.emailRequired"),
                  },
                  {
                    type: "email",
                    message: t("messages.validations.emailPattern"),
                  },
                ]}
              >
                <Input placeholder={t("placeholders.email")}></Input>
              </FormItem>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 12 }}
              xl={{ span: 12 }}
              xxl={{ span: 12 }}
            >
              <FormItem
                name={"phoneNumber"}
                label={t("labels.users.phoneNumber")}
                rules={[
                  {
                    required: true,
                    message: t("messages.validations.phoneNumberRequired"),
                  },
                  {
                    max: 10,
                    min: 10,
                    message: t("messages.validations.phoneNumberLength"),
                  },
                ]}
              >
                <NumericInput
                  value={userForm.getFieldValue("phoneNumber")}
                  onChange={(e) => {
                    userForm.setFieldValue("phoneNumber", e);
                  }}
                  placeholder={t("placeholders.phoneNumber")}
                  maxLength={10}
                />
              </FormItem>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 12 }}
              xl={{ span: 12 }}
              xxl={{ span: 12 }}
            >
              <Popover
                placement="bottomRight"
                trigger="focus"
                open={isPasswordToolTipOpen}
                content={
                  <>
                    <Typography.Text>
                      {t("Your password must include:")}
                    </Typography.Text>
                    <ul>
                      <li>{t("Minimum 8 characters")}</li>
                      <li>{t("Maximum 20 characters")}</li>
                      <li>{t("Atleast one uppercase letter")}</li>
                      <li>{t("Atleast one lowercase letter")}</li>
                      <li>{t("Atleast one number")}</li>
                      <li>{t("Atleast one symbol (@ $ ! % * ? &)")}</li>
                    </ul>
                  </>
                }
              >
                <FormItem
                  name={"password"}
                  label={t("labels.users.password")}
                  rules={[
                    {
                      required: true,
                      message: t("messages.validations.passwordRequired"),
                    },
                    {
                      pattern: PASSWORD_REGEX,
                      message: t("messages.validations.passwordPatern"),
                    },
                  ]}
                >
                  <Input.Password
                    onFocus={() => {
                      setIsPasswordToolTipOpen(true);
                    }}
                    onBlur={() => {
                      setIsPasswordToolTipOpen(false);
                    }}
                    placeholder={t("placeholders.password")}
                  />
                </FormItem>
              </Popover>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 12 }}
              xl={{ span: 12 }}
              xxl={{ span: 12 }}
            >
              <FormItem
                name={"confirmPassword"}
                label={t("Confirm Password")}
                rules={[
                  {
                    required: true,
                    message: t("Confirm password is required"),
                  },
                  {
                    validator(_, value) {
                      if (value && value !== passwordWatch) {
                        return Promise.reject(t("Password doesn't match"));
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Input.Password placeholder={t("Re-enter your password")} />
              </FormItem>
            </Col>
          </Row>
        </Form>
        {(alertDescription && (
          <Alert message={alertDescription} type="error"></Alert>
        )) || <></>}
      </RestrictedComponent>
    </Drawer>
  );
};

export default UserForm;
