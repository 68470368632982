import { ThemeConfig } from "antd";

export const Themes: ThemeConfig = {
  components: {
    Layout: {
      colorBgHeader: "#00a6e2",
    },
  },
  token: {
    // colorPrimary: "#00a6e2",
    colorPrimary: "#A6CB43",
    // fontFamily: "Roboto",
    borderRadius: 4,
    colorLink: "#A6CB43",

    // colorFillSecondary: '#83de45',
  },
};
