import "styles/LoanDashboard.less";

import { EmiStatus, ILoanType } from "store/types";

import { RootState } from "store/store";
import { Steps } from "antd";
import { momentDateTimeFormat } from "components/util-components/dateTimeFormattor";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const LoanDashboardTimeLine = () => {
  const { t } = useTranslation();
  const [stepClassName, setStepClassName] = useState(
    "loan-dashboard-time-line"
  );
  const { details } = useSelector(
    (state: RootState) => state.loans.loanDashboard
  );

  const generateData = () => {
    const data: any[] = [];
    let title = "";
    let description = "";
    let status: "wait" | "process" | "finish" | "error" = "wait";

    //downPayment
    if (
      !details?.optedForZeroDownPayment &&
      details?.loanType === ILoanType.PRIMARY
    ) {
      const isDownPaymentPaid =
        details?.downPaymentDetails?.status === EmiStatus.PAID;

      const downPaymentTitle = isDownPaymentPaid
        ? details?.downPaymentDetails?.transactionTime
        : details?.downPaymentDetails?.dueDate;

      const downPaymentStatus = isDownPaymentPaid ? "finish" : "wait";

      data?.push({
        title: momentDateTimeFormat(downPaymentTitle, "date"),
        description: t("Down Payment"),
        status: downPaymentStatus,
      });
    }

    //EMIs
    details?.emis?.forEach((emi, index) => {
      title = momentDateTimeFormat(emi?.dueDate, "date");
      description = `EMI ${index + 1}`;
      // if (emi?.status === EmiStatus.PAID) {
      //   // title = momentDateTimeFormat(emi?.transactionTime, "date");
      //   status = "finish";
      // } else {
      //   // description = `EMI ${index + 1}`;
      //   status = "wait";
      // }
      status = emi?.status === EmiStatus.PAID ? "finish" : "wait";
      data.push({
        title: title,
        description: description,
        status: status,
      });
    });
    return data;
  };
  return (
    <div
      onMouseEnter={() => {
        setStepClassName("loan-dashboard-time-line hovered");
      }}
    >
      <Steps
        progressDot
        direction="vertical"
        className={stepClassName}
        items={generateData()}
      />
    </div>
  );
};

export default LoanDashboardTimeLine;
