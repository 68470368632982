import "styles/LoanDashboard.less";

import { Dropdown, MenuProps, Table, Tag, Typography } from "antd";
import {
  EmiStatus,
  EmiStatus_View,
  IEmiDetail,
  ILoanType,
  ILoansStatus,
  IPaymentTypes,
  IPaymentTypes_View,
} from "store/types";
import { MoreOutlined, PlusCircleFilled } from "@ant-design/icons";

import { ColumnsType } from "antd/es/table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IndianRuppeeView } from "components/products/product.util.components";
import LoanDashboardAddPaymentForm from "./loan.dashboard.addPaymentForm";
import LoanDashboardSendLicenseForm from "./loan.dashboard.sendLicenseForm";
import { NOT_APPLICABLE } from "appConstants";
import { RootState } from "store/store";
import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import { momentDateTimeFormat } from "components/util-components/dateTimeFormattor";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const LoanDashboardPayments = () => {
  const { details, loading } = useSelector(
    (state: RootState) => state.loans.loanDashboard
  );
  const { t } = useTranslation();
  const [isAddPaymentModalOpen, setIsAddPaymentModalOpen] = useState(false);
  const [isSendLicenseModalOpen, setSendLicenseModalOpen] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState<IEmiDetail>(
    {} as IEmiDetail
  );
  let enabledButtonDate = "";

  let dataSource = [] as any[];
  if (
    !details?.optedForZeroDownPayment &&
    details?.loanType === ILoanType.PRIMARY
  ) {
    dataSource.push({
      ...details?.downPaymentDetails,
      paymentType: IPaymentTypes.DOWN_PAYMENT,
    });
  }

  details?.emis?.every((emi: IEmiDetail) => {
    if (emi?.status !== EmiStatus.PAID) {
      enabledButtonDate = emi?.dueDate;
      return false;
    }
    return true;
  });

  dataSource = dataSource.concat(details?.emis || []);

  const getPaymentStatusTag = (status: EmiStatus) => {
    switch (status) {
      case EmiStatus.AUTO_DEBIT_FAILED:
        return <Tag color="red">{EmiStatus_View.AUTO_DEBIT_FAILED}</Tag>;
      case EmiStatus.DUE:
        return <Tag color="orange">{EmiStatus_View.DUE}</Tag>;
      case EmiStatus.GRACE_PERIOD_OVER:
        return <Tag color="red">{EmiStatus_View.GRACE_PERIOD_OVER}</Tag>;
      case EmiStatus.PAID:
        return (
          <>
            <Tag color="green">{EmiStatus_View.PAID}</Tag>
          </>
        );
      case EmiStatus.PENDING:
        return <Tag color="blue">{EmiStatus_View.PENDING}</Tag>;
      default:
        break;
    }
  };

  const PaymentActions = (props: { record: IEmiDetail }) => {
    // const isPaid = props?.record?.status === EmiStatus.PAID;
    const isNextPayment = props?.record?.dueDate === enabledButtonDate;

    const actionMenuItems: MenuProps["items"] = [
      {
        key: "add-payment",
        icon: <PlusCircleFilled />,
        disabled: props?.record?.paymentType
          ? ![ILoansStatus.DOWN_PAYMENT_PENDING].includes(details?.status)
          : [
              ILoansStatus.DOWN_PAYMENT_PENDING,
              ILoansStatus.DEVICE_MAPPING_PENDING,
              ILoansStatus.LICENSE_KEY_PENDING,
              ILoansStatus.ASSIGN_DISPATCHER_PENDING,
              ILoansStatus.DISPATCH_PENDING,
            ].includes(details?.status) || !isNextPayment,
        label: t("Add Payment"),
        onClick: () => {
          setIsAddPaymentModalOpen(true);
          setSelectedRecord(props?.record);
        },
      },
      {
        key: "send-license",
        icon: <FontAwesomeIcon icon={faArrowUpRightFromSquare} />,
        label: t("labels.loans.dashboard.sendLicense"),
        onClick: () => {
          setSendLicenseModalOpen(true);
        },
        disabled:
          props?.record?.transactionId !== details?.lastPaidEmi?.transactionId,
      },
    ];

    return (
      <Dropdown menu={{ items: actionMenuItems }}>
        <MoreOutlined />
      </Dropdown>
    );

    // return (
    //   <>
    //     {isPaid ? (
    //       <>{getPaymentStatusTag(record?.status)}</>
    //     ) : record?.paymentType ? (
    //       // Button for Down Payment
    //       <Button
    //         type='primary'
    //         onClick={() => {
    //           setIsAddPaymentModalOpen(true);
    //           setSelectedRecord(record);
    //         }}
    //         disabled={
    //           ![ILoansStatus.DOWN_PAYMENT_PENDING].includes(details?.status)
    //         }
    //       >
    //         {t('Add Payment')}
    //       </Button>
    //     ) : (
    //       //Button for EMIs
    //       <Button
    //         type='primary'
    //         onClick={() => {
    //           setIsAddPaymentModalOpen(true);
    //           setSelectedRecord(record);
    //         }}
    //         disabled={
    //           [
    //             ILoansStatus.EMI_PENDING,
    //             ILoansStatus.DOWN_PAYMENT_PENDING,
    //             ILoansStatus.DEVICE_MAPPING_PENDING,
    //             ILoansStatus.LICENSE_KEY_PENDING,
    //             ILoansStatus.ASSIGN_DISPATCHER_PENDING,
    //             ILoansStatus.DISPATCH_PENDING,
    //           ].includes(details?.status) || !isNextPayment
    //         }
    //       >
    //         {t('Add Payment')}
    //       </Button>
    //     )}
    //   </>
    // );
  };

  const columns: ColumnsType<IEmiDetail> = [
    {
      title: t("Amount"),
      key: "amount",
      render: (_, record) => {
        return <IndianRuppeeView value={record?.amount?.toString()} />;
      },
    },
    {
      title: t("Due Date"),
      key: "dueDate",
      render: (_, record) => {
        return (
          <Typography.Text>
            {momentDateTimeFormat(record?.dueDate, "date") || NOT_APPLICABLE}
          </Typography.Text>
        );
      },
    },
    {
      title: t("Payment Method"),
      key: "paymentMethod",
      render: (_, record) => {
        return (
          <Typography.Text>
            {record?.paymentMode || NOT_APPLICABLE}
          </Typography.Text>
        );
      },
    },
    {
      title: t("Payment Type"),
      key: "paymentMethod",
      render: (_, record) => {
        return (
          <Typography.Text>
            {IPaymentTypes_View[record?.paymentType || IPaymentTypes.EMI]}
          </Typography.Text>
        );
      },
    },
    {
      title: t("Transaction Id"),
      key: "transactionId",
      render: (_, record) => {
        return (
          <Typography.Text>
            {record?.transactionId || NOT_APPLICABLE}
          </Typography.Text>
        );
      },
    },
    {
      title: t("Transaction Time"),
      key: "transactionTime",
      render: (_, record) => {
        return (
          <Typography.Text>
            {momentDateTimeFormat(record?.transactionTime, "dateTime") ||
              NOT_APPLICABLE}
          </Typography.Text>
        );
      },
    },
    {
      title: t("Status"),
      key: "status",
      render: (_, record) => {
        return <>{getPaymentStatusTag(record?.status)}</>;
      },
    },
    {
      title: t("Actions"),
      key: "actions",
      align: "center",
      render: (_, record) => {
        return <PaymentActions record={record} />;
      },
    },
  ];

  return (
    <section style={{ paddingBottom: "20px", marginBottom: "100px" }}>
      <Typography.Title level={3} style={{ color: "#104359" }}>
        {t("Payments")}
      </Typography.Title>
      <Table
        bordered
        columns={columns}
        rowKey={(data: IEmiDetail) => data?.dueDate}
        dataSource={dataSource}
        pagination={false}
        loading={loading}
      />
      <LoanDashboardAddPaymentForm
        open={isAddPaymentModalOpen}
        close={() => setIsAddPaymentModalOpen(false)}
        record={selectedRecord}
      />
      <LoanDashboardSendLicenseForm
        openModal={isSendLicenseModalOpen}
        closeModal={() => setSendLicenseModalOpen(false)}
        record={selectedRecord}
      />
    </section>
  );
};

export default LoanDashboardPayments;
