import {
  Alert,
  Button,
  Col,
  Divider,
  Drawer,
  Form,
  Input,
  Row,
  message,
} from "antd";
import RestrictedComponent, {
  PERMISSIONS,
} from "components/util-components/RestrictedComponent";
import { useEffect, useState } from "react";

import FormItem from "antd/es/form/FormItem";
import { Requester } from "api/requester";
import { API_END_POINTS } from "api/urls";
import HiddenComponent from "components/util-components/HiddenComponent";
import Loader from "components/util-components/Loader";
import NumericInput from "components/util-components/NumericInput";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch } from "store/hooks";
import { DistributorsActions } from "store/slices/distributorsSlice";
import { RootState } from "store/store";

const DistributorEditForm = () => {
  const { t } = useTranslation();
  const [distributorForm] = Form.useForm();
  const navigate = useNavigate();
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [alertDescription, setAlertDescription] = useState("");
  const { details, loading } = useSelector(
    (state: RootState) => state?.distributors?.distributorById
  );

  const params = useParams();
  const dispatch = useAppDispatch();

  const handleCancel = () => {
    navigate("/distributors");
  };

  useEffect(() => {
    dispatch(
      DistributorsActions.fetchDistributorById(params?.distributorId || "")
    );
    return () => {
      dispatch(DistributorsActions.resetState());
    };
  }, []);

  useEffect(() => {
    if (details) {
      prefillValue();
    }
  }, [details]);

  const prefillValue = async () => {
    distributorForm.setFieldsValue({
      networkName: details.networkName,
      contactPersonName: details.contactPersonName,
      email: details.email,
      phoneNumber: details.phoneNumber,
      address: details.address,
    });
  };

  const handleSubmit = async (data: any) => {
    console.log("form values are: ", data);
    setIsSubmitLoading(true);
    const response = await Requester.put(
      API_END_POINTS.OPERATORS.distributors + "/" + params?.distributorId,
      data
    ).catch(({ response }) => response);
    if (response?.status === 200) {
      message.success(t("Distributor updated successfully"));
      navigate("/distributors");
      dispatch(DistributorsActions.fetchDistributorsList({}));
      distributorForm.resetFields();
    } else {
      const error = t("messages.error.generic");
      setAlertDescription(error);
    }
    setIsSubmitLoading(false);
  };

  return (
    <Drawer
      open={true}
      width={800}
      onClose={() => handleCancel()}
      maskClosable={false}
      title={"Edit Distributor"}
      footer={
        <RestrictedComponent permission={PERMISSIONS.DISTRIBUTORS.create}>
          <Row>
            <Col span={24} style={{ textAlign: "right" }}>
              <FormItem className="form-actionbuttons">
                <Button
                  type="primary"
                  htmlType="submit"
                  onClick={distributorForm.submit}
                  loading={isSubmitLoading}
                  disabled={loading || isSubmitLoading}
                >
                  {t("labels.submit")}
                </Button>
              </FormItem>
            </Col>
          </Row>
        </RestrictedComponent>
      }
    >
      <RestrictedComponent permission={PERMISSIONS.DISTRIBUTORS.edit} withAlert>
        <HiddenComponent hidden={loading} fallBack={<Loader />}>
          <Form
            name={"distributorForm"}
            form={distributorForm}
            initialValues={{
              phoneNumber: "",
              contactPersonName: "",
              email: "",
              networkName: "",
              address: {
                addressLine1: "",
                addressLine2: "",
                city: "",
                state: "",
                country: "India",
                pincode: "",
              },
            }}
            layout={"vertical"}
            onFinish={handleSubmit}
          >
            <Row gutter={24}>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 12 }}
                xl={{ span: 12 }}
                xxl={{ span: 12 }}
              >
                <FormItem
                  name={"networkName"}
                  label={t("labels.distributors.networkName")}
                  rules={[
                    {
                      required: true,
                      message: t("messages.validations.networkNameRequired"),
                    },
                  ]}
                >
                  <Input placeholder={t("placeholders.networkName")}></Input>
                </FormItem>
              </Col>

              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 12 }}
                xl={{ span: 12 }}
                xxl={{ span: 12 }}
              >
                <FormItem
                  name={"contactPersonName"}
                  label={t("labels.distributors.contactPersonName")}
                  rules={[
                    {
                      required: true,
                      message: t(
                        "messages.validations.contactPersonNameRequired"
                      ),
                    },
                  ]}
                >
                  <Input
                    placeholder={t("placeholders.contactPersonName")}
                  ></Input>
                </FormItem>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 12 }}
                xl={{ span: 12 }}
                xxl={{ span: 12 }}
              >
                <FormItem
                  name={"email"}
                  label={t("labels.distributors.email")}
                  rules={[
                    {
                      required: true,
                      message: t("messages.validations.emailRequired"),
                    },
                    {
                      type: "email",
                      message: t("messages.validations.emailPattern"),
                    },
                  ]}
                >
                  <Input placeholder={t("placeholders.email")}></Input>
                </FormItem>
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 12 }}
                xl={{ span: 12 }}
                xxl={{ span: 12 }}
              >
                <FormItem
                  name={"phoneNumber"}
                  label={t("labels.distributors.phoneNumber")}
                  rules={[
                    {
                      required: true,
                      message: t("messages.validations.phoneNumberRequired"),
                    },
                    {
                      max: 10,
                      min: 10,
                      message: t("messages.validations.phoneNumberLength"),
                    },
                  ]}
                >
                  <NumericInput
                    value={distributorForm.getFieldValue("phoneNumber")}
                    onChange={(e) => {
                      distributorForm.setFieldValue("phoneNumber", e);
                    }}
                    placeholder={t("placeholders.phoneNumber")}
                    maxLength={10}
                  />
                </FormItem>
              </Col>
            </Row>

            <Divider orientation="left" plain>
              Address
            </Divider>

            <Row gutter={24}>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 12 }}
                xl={{ span: 12 }}
                xxl={{ span: 12 }}
              >
                <FormItem
                  name={["address", "addressLine1"]}
                  label={t("labels.distributors.addressLine1")}
                  rules={[
                    {
                      required: true,
                      message: t("messages.validations.addressLine1Required"),
                    },
                  ]}
                >
                  <Input placeholder={t("placeholders.addressLine1")}></Input>
                </FormItem>
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 12 }}
                xl={{ span: 12 }}
                xxl={{ span: 12 }}
              >
                <FormItem
                  name={["address", "addressLine2"]}
                  label={t("labels.distributors.addressLine2")}
                >
                  <Input placeholder={t("placeholders.addressLine2")}></Input>
                </FormItem>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 12 }}
                xl={{ span: 12 }}
                xxl={{ span: 12 }}
              >
                <FormItem
                  name={["address", "city"]}
                  label={t("labels.distributors.city")}
                  rules={[
                    {
                      required: true,
                      message: t("messages.validations.cityRequired"),
                    },
                  ]}
                >
                  <Input placeholder={t("placeholders.city")}></Input>
                </FormItem>
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 12 }}
                xl={{ span: 12 }}
                xxl={{ span: 12 }}
              >
                <FormItem
                  name={["address", "state"]}
                  label={t("labels.distributors.state")}
                  rules={[
                    {
                      required: true,
                      message: t("messages.validations.stateRequired"),
                    },
                  ]}
                >
                  <Input placeholder={t("placeholders.state")}></Input>
                </FormItem>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 12 }}
                xl={{ span: 12 }}
                xxl={{ span: 12 }}
              >
                <FormItem
                  name={["address", "pincode"]}
                  label={t("labels.distributors.pincode")}
                  rules={[
                    {
                      required: true,
                      message: t("messages.validations.pincodeRequired"),
                    },
                  ]}
                >
                  <Input placeholder={t("placeholders.pincode")}></Input>
                </FormItem>
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 12 }}
                xl={{ span: 12 }}
                xxl={{ span: 12 }}
              >
                <FormItem
                  name={["address", "country"]}
                  label={t("labels.distributors.country")}
                  rules={[
                    {
                      required: true,
                      message: t("messages.validations.countryRequired"),
                    },
                  ]}
                >
                  <Input
                    disabled={true}
                    placeholder={t("placeholders.country")}
                  ></Input>
                </FormItem>
              </Col>
            </Row>
          </Form>
        </HiddenComponent>
        {(alertDescription && (
          <Alert message={alertDescription} type="error"></Alert>
        )) || <></>}
      </RestrictedComponent>
    </Drawer>
  );
};

export default DistributorEditForm;
