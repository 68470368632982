import { Action, ThunkAction, configureStore } from "@reduxjs/toolkit";

import { AuthReducer } from "./slices/authSlice";
import { CommonReducer } from "./slices/commonSlice";
import { CustomerStatsReducer } from "./slices/customerStatsSlice";
import { CustomersReducer } from "./slices/customersSlice";
import { LoansListReducer } from "./slices/loansListSlice";
import { LoansReducer } from "./slices/loansSlice";
import { ProductReducer } from "./slices/productsSlice";
import { UsersReducer } from "./slices/usersSlice";
import { DistributorsReducer } from "store/slices/distributorsSlice";

export const store = configureStore({
  reducer: {
    auth: AuthReducer,
    common: CommonReducer,
    customers: CustomersReducer,
    products: ProductReducer,
    loans: LoansReducer,
    loansList: LoansListReducer,
    users: UsersReducer,
    distributors: DistributorsReducer,
    customerStats: CustomerStatsReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
