import { UploadFile, message } from "antd";

import { API_END_POINTS } from "api/urls";
import { Requester } from "api/requester";
import { getSessionToken } from "store/sessionStore";

export const removeUndefinedFromObject = (obj: any) => {
  let newObj: any = {};
  Object.keys(obj).forEach((key) => {
    if (obj[key] === Object(obj[key])) {
      newObj[key] = removeUndefinedFromObject(obj[key]);
    } else if (obj[key] !== undefined) {
      newObj[key] = obj[key];
    }
  });
  return newObj;
};

export const uploadFilesToURL = async (
  files: UploadFile<any>[],
  links: string[]
) => {
  const responses = links.map(async (link, index) => {
    const response = await Requester.put(link, files[index]?.originFileObj, {
      headers: {
        "Content-Type": files[index]?.type,
      },
    });
    if (response?.status === 200) {
      return true;
    } else {
      return false;
    }
  });
  return responses;
};

export const getUploadLinks = async (files: UploadFile<any>[]) => {
  const url = API_END_POINTS.PRODUCTS.newUploadLink;
  const links = files.map(async (file: UploadFile<any>) => {
    const data = {
      mimeType: file?.type,
    };
    const response = await Requester.post(url, data).catch(
      ({ response }) => response
    );
    if (response?.status === 200) {
      return response?.data?.link;
    } else {
      message.error("Fetching Upload Link Error");
    }
  });
  return links;
};

/* 
  as its an async function, setting form loading to false should be done
  inside the afterUpload function
  << Look at 'products.create.tsx' handleSubmit method for reference >>
*/
export const uploadFilesToS3 = async (
  files: UploadFile<any>[],
  errorHandler?: () => void,
  afterUpload?: (links: string[]) => void
) => {
  const links = await getUploadLinks(files);
  Promise.all(links).then(async (links) => {
    const responses = await uploadFilesToURL(files, links);
    Promise.all(responses)
      .then(async (responses) => {
        if (responses.every(Boolean)) {
          if (afterUpload) {
            afterUpload(links);
          }
        } else {
          if (errorHandler) {
            errorHandler();
            message.error("Images Upload Failed");
          }
        }
      })
      .catch(() => {
        if (errorHandler) {
          errorHandler();
          message.error("Images Upload Failed");
        }
      });
  });
};

export const wrapWithAuthToken = (url: string | undefined) => {
  const token = getSessionToken();
  return `${url}?authKey=${token}`;
};

export const zeroPad = (numberStr: string | number, maxLength: number) => {
  return numberStr.toString().padStart(maxLength, "0");
};
