import ProductsHeader from "components/products/products.header";
import ProductsList from "components/products/products.list";

const ProductsScreen = () => {
  return (
    <>
      <ProductsHeader />
      <br />
      <ProductsList />
    </>
  );
};

export default ProductsScreen;
