import { Button, Space, Typography } from "antd";

import { CustomersActions } from "store/slices/customersSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SyncOutlined } from "@ant-design/icons";
import { faUsers } from "@fortawesome/free-solid-svg-icons";
import { useAppDispatch } from "store/hooks";
import { useTranslation } from "react-i18next";

const CustomerHeader = () => {
  const { t } = useTranslation();
  const headerComponentFontSize = 24;
  const dispatch = useAppDispatch();

  const handleRefresh = () => {
    dispatch(CustomersActions.refreshList());
  };
  return (
    <Space
      align="center"
      style={{ justifyContent: "space-between", width: "100%" }}
    >
      <Typography.Text style={{ fontSize: headerComponentFontSize }}>
        <FontAwesomeIcon
          icon={faUsers}
          color={"inherit"}
          fontSize={headerComponentFontSize}
        />{" "}
        {t("labels.customers.title")}
      </Typography.Text>
      <Button onClick={handleRefresh} icon={<SyncOutlined />} />
    </Space>
  );
};

export default CustomerHeader;
