import {
  Alert,
  Button,
  Col,
  Drawer,
  Form,
  Input,
  Popover,
  Row,
  Select,
  Typography,
  message,
} from "antd";
import { IUser, ROLES, ROLES_VIEW } from "store/types";
import RestrictedComponent, {
  PERMISSIONS,
} from "components/util-components/RestrictedComponent";
import { useEffect, useState } from "react";

import { API_END_POINTS } from "api/urls";
import FormItem from "antd/es/form/FormItem";
import NumericInput from "components/util-components/NumericInput";
import { PASSWORD_REGEX } from "appConstants";
import { Requester } from "api/requester";
import { RootState } from "store/store";
import { UsersActions } from "store/slices/usersSlice";
import { useAppDispatch } from "store/hooks";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useWatch } from "antd/es/form/Form";
import HiddenComponent from "components/util-components/HiddenComponent";
import Loader from "components/util-components/Loader";
import { getHomeByRole } from "store/slices/authSlice";

const UserEditForm = () => {
  const { t } = useTranslation();
  const [userForm] = Form.useForm();
  const navigate = useNavigate();
  const [isPasswordToolTipOpen, setIsPasswordToolTipOpen] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [alertDescription, setAlertDescription] = useState("");
  const { users } = useSelector((state: RootState) => state);
  const [disableEditPassword, setDisableEditPassword] = useState(true);
  const dispatch = useAppDispatch();
  const params = useParams();
  const { auth } = useSelector((state: RootState) => state);

  const passwordWatch = useWatch("password", userForm);

  const { details, loading } = useSelector(
    (state: RootState) => state?.users?.userById
  );

  useEffect(() => {
    console.log("params are: ", params);
    dispatch(UsersActions.fetchUserById(params?.userId || ""));
    return () => {
      userForm.resetFields();
      dispatch(UsersActions.resetInitialValues());
    };
  }, []);

  useEffect(() => {
    if (details) {
      prefillValue();
    }
  }, [details]);

  const prefillValue = async () => {
    userForm.setFieldsValue({
      role: details.role,
      phoneNumber: details.phoneNumber,
      emailId: details.emailId,
      firstName: details.firstName,
      lastName: details.lastName,
      branchId: details.branchId,
    });
  };

  const handleCancel = () => {
    if (auth.userInfo.distributorId) {
      navigate(`/distributors/${auth.userInfo.distributorId}/dashboard`);
    } else if (auth.userInfo.role !== ROLES.ADMIN) {
      navigate(getHomeByRole(auth.userInfo));
    } else {
      navigate(`/users`);
    }
  };

  useEffect(() => {
    if (!auth.userInfo.distributorId) {
      dispatch(UsersActions.fetchBranchesList(null));
    }
  }, []);

  const rolesOptions = Object.keys(ROLES).map((role) => {
    return { value: role, label: ROLES_VIEW[role as keyof typeof ROLES_VIEW] };
  });

  const handleSubmit = async (data: IUser) => {
    // data.phoneNumber = "+91" + data?.phoneNumber;
    setIsSubmitLoading(true);
    data.id = params?.userId || "";
    const response = await Requester.patch(
      API_END_POINTS.OPERATORS.users + "/" + params?.userId,
      data
    ).catch(({ response }) => response);
    if (response?.status === 200) {
      message.success(t("User updated successfully"));
      if (auth.userInfo.distributorId) {
        navigate(`/distributors/${auth.userInfo.distributorId}/dashboard`);
      } else if (auth.userInfo.role !== ROLES.ADMIN) {
        navigate(getHomeByRole(auth.userInfo));
      } else {
        navigate("/users");
        dispatch(UsersActions.fetchUsersList({}));
      }

      userForm.resetFields();
    } else {
      const error = t("messages.error.generic");
      setAlertDescription(error);
    }
    setIsSubmitLoading(false);
  };

  return (
    <Drawer
      open={true}
      width={800}
      onClose={() => handleCancel()}
      title={t("Edit User")}
      maskClosable={false}
      footer={
        <RestrictedComponent
          permissions={[PERMISSIONS.USERS.edit, PERMISSIONS.USERS.editMine]}
        >
          <Row>
            <Col span={24} style={{ textAlign: "right" }}>
              <FormItem className="form-actionbuttons">
                <Button
                  type="primary"
                  htmlType="submit"
                  onClick={userForm.submit}
                  loading={isSubmitLoading}
                >
                  {t("labels.submit")}
                </Button>
              </FormItem>
            </Col>
          </Row>
        </RestrictedComponent>
      }
    >
      <RestrictedComponent
        permissions={[PERMISSIONS.USERS.edit, PERMISSIONS.USERS.editMine]}
        withAlert
      >
        <HiddenComponent hidden={loading} fallBack={<Loader />}>
          <Form
            name={"userForm"}
            form={userForm}
            initialValues={{
              role: undefined,
              phoneNumber: "",
              emailId: "",
              firstName: "",
              lastName: "",
              password: "",
            }}
            layout={"vertical"}
            onFinish={handleSubmit}
          >
            <Row gutter={24}>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 12 }}
                xl={{ span: 12 }}
                xxl={{ span: 12 }}
              >
                <FormItem
                  name={"firstName"}
                  label={t("labels.users.firstName")}
                  rules={[
                    {
                      required: true,
                      message: t("messages.validations.nameRequired"),
                    },
                  ]}
                >
                  <Input placeholder={t("placeholders.name")}></Input>
                </FormItem>
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 12 }}
                xl={{ span: 12 }}
                xxl={{ span: 12 }}
              >
                <FormItem name={"lastName"} label={t("labels.users.lastName")}>
                  <Input placeholder={t("placeholders.name")}></Input>
                </FormItem>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 12 }}
                xl={{ span: 12 }}
                xxl={{ span: 12 }}
              >
                <FormItem
                  name={"role"}
                  label={t("labels.users.role")}
                  rules={[
                    {
                      required: true,
                      message: t("messages.validations.roleRequired"),
                    },
                  ]}
                >
                  <Select
                    options={rolesOptions}
                    showSearch
                    allowClear
                    placeholder={t("placeholders.role")}
                  />
                </FormItem>
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 12 }}
                xl={{ span: 12 }}
                xxl={{ span: 12 }}
              >
                <FormItem
                  name={"branchId"}
                  label={t("Branch")}
                  rules={[
                    {
                      required: auth.userInfo.distributorId ? false : true,
                      message: t("Branch is required"),
                    },
                  ]}
                >
                  <Select
                    showSearch
                    allowClear
                    placeholder={t("Select Branch")}
                    disabled={auth.userInfo.distributorId ? true : false}
                  >
                    {users?.branches?.details?.map((branch) => {
                      return (
                        <Select.Option key={branch?.id} value={branch?.id}>
                          {branch?.name}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </FormItem>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 12 }}
                xl={{ span: 12 }}
                xxl={{ span: 12 }}
              >
                <FormItem
                  name={"emailId"}
                  label={t("labels.users.email")}
                  rules={[
                    {
                      required: true,
                      message: t("messages.validations.emailRequired"),
                    },
                    {
                      type: "email",
                      message: t("messages.validations.emailPattern"),
                    },
                  ]}
                >
                  <Input placeholder={t("placeholders.email")}></Input>
                </FormItem>
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 12 }}
                xl={{ span: 12 }}
                xxl={{ span: 12 }}
              >
                <FormItem
                  name={"phoneNumber"}
                  label={t("labels.users.phoneNumber")}
                  rules={[
                    {
                      required: true,
                      message: t("messages.validations.phoneNumberRequired"),
                    },
                    {
                      max: 10,
                      min: 10,
                      message: t("messages.validations.phoneNumberLength"),
                    },
                  ]}
                >
                  <NumericInput
                    value={userForm.getFieldValue("phoneNumber")}
                    onChange={(e) => {
                      userForm.setFieldValue("phoneNumber", e);
                    }}
                    placeholder={t("placeholders.phoneNumber")}
                    maxLength={10}
                  />
                </FormItem>
              </Col>
            </Row>

            <Row style={{ display: "flex", justifyContent: "flex-end" }}>
              <Typography.Link
                style={{
                  paddingLeft: 10,
                  textDecoration: "underline",
                }}
                onClick={() => {
                  setDisableEditPassword(true);
                }}
              >
                Cancel
              </Typography.Link>
              <Typography.Link
                style={{
                  paddingLeft: 10,
                  textDecoration: "underline",
                }}
                onClick={() => {
                  setDisableEditPassword(false);
                }}
              >
                Edit Password
              </Typography.Link>
            </Row>

            <div
              style={{
                background: "#f2f2f2",
                paddingLeft: 15,
                paddingRight: 15,
                paddingTop: 15,
              }}
            >
              <Row gutter={24}>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 24 }}
                  lg={{ span: 12 }}
                  xl={{ span: 12 }}
                  xxl={{ span: 12 }}
                >
                  <Popover
                    placement="bottomRight"
                    trigger="focus"
                    open={isPasswordToolTipOpen}
                    content={
                      <>
                        <Typography.Text>
                          {t("Your password must include:")}
                        </Typography.Text>
                        <ul>
                          <li>{t("Minimum 8 characters")}</li>
                          <li>{t("Maximum 20 characters")}</li>
                          <li>{t("Atleast one uppercase letter")}</li>
                          <li>{t("Atleast one lowercase letter")}</li>
                          <li>{t("Atleast one number")}</li>
                          <li>{t("Atleast one symbol (@ $ ! % * ? &)")}</li>
                        </ul>
                      </>
                    }
                  >
                    <FormItem
                      name={"password"}
                      label={<label>{t("labels.users.password")}</label>}
                      rules={[
                        {
                          required: disableEditPassword ? false : true,
                          message: t("messages.validations.passwordRequired"),
                        },
                        {
                          pattern: PASSWORD_REGEX,
                          message: t("messages.validations.passwordPatern"),
                        },
                      ]}
                    >
                      <Input.Password
                        disabled={disableEditPassword}
                        visibilityToggle={false}
                        onFocus={() => {
                          setIsPasswordToolTipOpen(true);
                        }}
                        onBlur={() => {
                          setIsPasswordToolTipOpen(false);
                        }}
                        placeholder={t("placeholders.password")}
                      />
                    </FormItem>
                  </Popover>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 24 }}
                  lg={{ span: 12 }}
                  xl={{ span: 12 }}
                  xxl={{ span: 12 }}
                >
                  <FormItem
                    name={"confirmPassword"}
                    label={t("Confirm Password")}
                    rules={[
                      {
                        required: disableEditPassword ? false : true,
                        message: t("Confirm password is required"),
                      },
                      {
                        validator(_, value) {
                          if (value && value !== passwordWatch) {
                            return Promise.reject(t("Password doesn't match"));
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <Input.Password
                      disabled={disableEditPassword}
                      visibilityToggle={false}
                      placeholder={t("Re-enter your password")}
                    />
                  </FormItem>
                </Col>
              </Row>
            </div>
          </Form>
        </HiddenComponent>
        {(alertDescription && (
          <Alert message={alertDescription} type="error"></Alert>
        )) || <></>}
      </RestrictedComponent>
    </Drawer>
  );
};

export default UserEditForm;
