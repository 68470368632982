import { Button, Table, Tooltip, Typography } from "antd";
import { Outlet, useNavigate } from "react-router-dom";

import { ColumnsType } from "antd/es/table";
import ErrorRenderer from "components/util-components/ErrorRenderer";
import HiddenComponent from "components/util-components/HiddenComponent";
import { IProduct } from "store/types";
import { IndianRuppeeView } from "./product.util.components";
import { InfoCircleOutlined } from "@ant-design/icons";
import { ProductsActions } from "store/slices/productsSlice";
import { RootState } from "store/store";
import { useAppDispatch } from "store/hooks";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const ProductsList = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { productsTable } = useSelector((state: RootState) => state?.products);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(ProductsActions.fetchProductsList(null));
  }, []);

  const columns: ColumnsType<IProduct> = [
    {
      title: t("labels.products.name"),
      dataIndex: "name",
      key: "productName",
    },
    {
      title: t("Product Group"),
      dataIndex: "group",
      key: "group",
    },
    {
      title: (
        <>
          <Typography.Text>{t("Managed")}</Typography.Text>{" "}
          <Tooltip title={t("Managed devices require License to function")}>
            <InfoCircleOutlined />
          </Tooltip>
        </>
      ),
      key: "managedBy",
      render: (_, record) => {
        return <>{record?.isPrimary ? "Yes" : "No"}</>;
      },
    },
    {
      title: t("labels.products.price"),
      key: "price",
      render: (_, record) => {
        return <IndianRuppeeView value={record?.retailPrice?.toString()} />;
      },
    },
    {
      title: t("Down Payment"),
      key: "down payment",
      render: (_, record) => {
        return (
          <IndianRuppeeView
            value={record?.emiOptions?.at(0)?.downPayment?.toString() || "0"}
          />
        );
      },
    },
    {
      title: t("Processing Fees"),
      key: "processing fee",
      render: (_, record) => {
        return (
          <IndianRuppeeView
            value={record?.emiOptions?.at(0)?.processingFee?.toString() || "0"}
          />
        );
      },
    },
    {
      title: t("Distributor Commission"),
      key: "distributor commission",
      render: (_, record) => {
        return (
          <IndianRuppeeView value={record?.distributorFee?.toString() || "0"} />
        );
      },
    },
    {
      title: t("labels.actions"),
      key: "actions",
      render: (_, record) => {
        return (
          <>
            <Button
              onClick={() => {
                navigate(`${record?.id}/details`);
              }}
              type="link"
            >
              {t("View")}
            </Button>
            <Button
              onClick={() => {
                navigate(`${record?.id}/edit`);
              }}
              type="link"
            >
              {t("Edit")}
            </Button>
          </>
        );
      },
    },
  ];
  return (
    <HiddenComponent
      hidden={productsTable?.error}
      fallBack={<ErrorRenderer status={productsTable?.errorStatus} />}
    >
      <Table
        bordered
        rowKey={(data) => data?.id}
        columns={columns}
        size={"small"}
        loading={productsTable?.loading}
        dataSource={productsTable?.productsList}
        style={{ width: "100%" }}
      />
      <Outlet
        context={{
          returnTo: "/products",
          callback: () => {
            dispatch(ProductsActions.fetchProductsList(null));
          },
        }}
      />
    </HiddenComponent>
  );
};

export default ProductsList;
