import {
  CUSTOMER_KYC_STATUS,
  IActiveAsset,
  ICustomer,
  ICustomerDocument,
} from "store/types";
import { Requester, formatURL } from "api/requester";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { API_END_POINTS } from "api/urls";

const fetchCustomersList = createAsyncThunk(
  "customers/loadCustomerList",
  async (payload: any, thunkAPI) => {
    try {
      const response = await Requester.get(API_END_POINTS.CUSTOMERS.list, {
        params: payload?.paginationParams,
      });
      return response?.data;
    } catch (e: any) {
      return thunkAPI.rejectWithValue({ errorStatus: e?.response?.status });
    }
  }
);

const fetchCustomerById = createAsyncThunk(
  "customers/loadCustomer",
  async (customerId: string, thunkAPI) => {
    let url = formatURL(API_END_POINTS.CUSTOMERS.customerById, {
      customerId: customerId,
    });
    try {
      const response = await Requester.get(url);
      return response?.data;
    } catch (e: any) {
      return thunkAPI.rejectWithValue({ errorStatus: e?.response?.status });
    }
  }
);

const fetchActiveAssets = createAsyncThunk(
  "customers/fetchActiveAssets",
  async (customerId: string, thunkAPI) => {
    let url = formatURL(API_END_POINTS.CUSTOMERS.activeAssets, {
      customerId: customerId,
    });
    const response = await Requester.get(url);
    if (response?.status === 200) {
      return response?.data;
    } else {
      return thunkAPI.rejectWithValue({
        error: true,
        status: response?.status,
      });
    }
  }
);

const emptyCustomer = {} as ICustomer;

const initialState = {
  customerList: {
    customersListKYCPending: [] as ICustomer[],
    customersListActive: [] as ICustomer[],
    customersListRejected: [] as ICustomer[],
    customersListDocumentPending: [] as ICustomer[],
    loading: false,
    pagination: {
      pageTokens: [""] as any[],
      pageSize: 10,
      currentPageNumber: 1,
    },
    error: false,
    errorStatus: 0,
    selectedTab: CUSTOMER_KYC_STATUS.COMPLETED,
    refreshToken: Math.random(),
  },
  customerProfile: {
    customerDetails: emptyCustomer,
    customerDocumnetDetails: {
      AADHAR_CARD: {} as ICustomerDocument,
      PAN_CARD: {} as ICustomerDocument,
      BANK_STATEMENT: {} as ICustomerDocument,
    },
    loading: false,
    error: false,
    errorStatus: 0,
  },
  customerActiveAssets: {
    details: [] as IActiveAsset[],
    loading: false,
    error: false,
    errorStatus: 0,
  },
};

export const customersSlice = createSlice({
  name: "customers",
  initialState,
  reducers: {
    resetPaginationParams: (state) => {
      state.customerList.pagination = {
        pageTokens: [""] as any[],
        pageSize: 10,
        currentPageNumber: 1,
      };
    },

    setCustomerListPageNumber: (state, { payload }: { payload: number }) => {
      state.customerList.pagination.currentPageNumber = payload;
    },

    setCustomerListPagination: (state, action) => {
      state.customerList.pagination = action.payload;
    },

    setSelectedTab: (state, { payload }) => {
      state.customerList.selectedTab = payload;
    },
    refreshList: (state) => {
      state.customerList.refreshToken = Math.random();
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchCustomersList.pending, (state, payload) => {
      state.customerList.loading = true;
    });
    builder.addCase(
      fetchCustomersList.fulfilled,
      (state, { payload, meta }) => {
        state.customerList.loading = false;
        state.customerList.error = false;

        if (
          payload?.pageToken &&
          !state.customerList.pagination?.pageTokens?.includes(
            payload?.pageToken
          )
        ) {
          state.customerList.pagination.pageTokens.push(payload?.pageToken);
        }
        switch (meta.arg?.tab) {
          case CUSTOMER_KYC_STATUS.COMPLETED:
            state.customerList.customersListActive = payload?.items;
            break;
          case CUSTOMER_KYC_STATUS.PENDING:
            state.customerList.customersListDocumentPending = payload?.items;
            break;
          case CUSTOMER_KYC_STATUS.REJECTED:
            state.customerList.customersListRejected = payload?.items;
            break;
          case CUSTOMER_KYC_STATUS.SUBMITTED:
            state.customerList.customersListKYCPending = payload?.items;
            break;
        }
      }
    );
    builder.addCase(fetchCustomersList.rejected, (state, action: any) => {
      state.customerList.loading = false;
      state.customerList.error = true;
      state.customerList.errorStatus = action?.payload?.errorStatus;
    });

    builder.addCase(fetchCustomerById.pending, (state, payload) => {
      state.customerProfile.loading = true;
    });
    builder.addCase(
      fetchCustomerById.fulfilled,
      (state, { payload }: { payload: ICustomer }) => {
        state.customerProfile.loading = false;
        state.customerProfile.error = false;
        state.customerProfile.customerDetails = payload;
        payload?.kyc?.documents?.forEach((document) => {
          state.customerProfile.customerDocumnetDetails[document.type] =
            document;
        });
      }
    );
    builder.addCase(fetchCustomerById.rejected, (state, payload) => {
      state.customerProfile.loading = false;
      state.customerProfile.error = true;
    });

    builder.addCase(fetchActiveAssets.pending, (state, payload) => {
      state.customerActiveAssets.loading = true;
    });
    builder.addCase(
      fetchActiveAssets.fulfilled,
      (state, { payload }: { payload: IActiveAsset[] }) => {
        state.customerActiveAssets.details = payload;
        state.customerActiveAssets.loading = false;
        state.customerActiveAssets.error = false;
      }
    );
    builder.addCase(fetchActiveAssets.rejected, (state, action: any) => {
      state.customerActiveAssets.loading = false;
      state.customerActiveAssets.error = true;
      state.customerActiveAssets.errorStatus = action?.payload?.errorStatus;
    });
  },
});

export const CustomersActions = {
  ...customersSlice.actions,
  fetchCustomersList,
  fetchCustomerById,
  fetchActiveAssets,
};

export const CustomersReducer = customersSlice.reducer;
