import { Button, Space, Typography } from "antd";
import RestrictedComponent, {
  PERMISSIONS,
} from "components/util-components/RestrictedComponent";

import { COLORS } from "appConstants";
import { CUSTOMER_STATUS } from "store/types";
import { ReactComponent as CreditsScoreDoc } from "assets/images/creditScoreDoc.svg";
import CustomerProfileCibilUploadForm from "./customer.profile.cibil.uploadForm";
import { RootState } from "store/store";
import { momentDateTimeFormat } from "components/util-components/dateTimeFormattor";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const CustomerProfileCibil = () => {
  const { t } = useTranslation();
  const { customerDetails } = useSelector(
    (state: RootState) => state?.customers?.customerProfile
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  return (
    <>
      <section>
        {[
          CUSTOMER_STATUS.ERP_MAPPING_PENDING,
          CUSTOMER_STATUS.ACTIVE,
          CUSTOMER_STATUS.SUSPENDED,
          CUSTOMER_STATUS.REJECTED,
          CUSTOMER_STATUS.VERIFICATION_COMPLETED,
        ].includes(customerDetails?.status) ? (
          <>
            <Typography.Title level={3} style={{ marginBottom: 0 }}>
              {t("Credit Score")}
            </Typography.Title>
            <Typography.Text style={{ color: "#888" }}>
              {t("Last updated")}
            </Typography.Text>{" "}
            <Typography.Text>
              {momentDateTimeFormat(
                customerDetails?.creditScore?.updateTime,
                "date"
              )}
            </Typography.Text>{" "}
            <Typography.Link
              underline
              onClick={() => {
                navigate(
                  `/customers/${customerDetails?.id}/profile/credit-score-history`
                );
              }}
            >
              {t("[History]")}
            </Typography.Link>
            <br />
            <br />
            <Typography.Title
              level={3}
              style={{
                marginBottom: 0,
                color: COLORS.LOAN_REQUEST_DASHBOARD.LOAN_REQUEST_TITLE,
                fontSize: 20,
              }}
            >
              {t("CIBIL")}
            </Typography.Title>
            <Typography.Title level={3} style={{ marginTop: 0, fontSize: 20 }}>
              {customerDetails?.creditScore?.score || "-"}
            </Typography.Title>
            <RestrictedComponent permission={PERMISSIONS.CUSTOMERS.verifier}>
              <Button
                style={{ width: 100 }}
                onClick={() => {
                  setIsModalOpen(true);
                }}
                disabled={[
                  CUSTOMER_STATUS.REJECTED,
                  CUSTOMER_STATUS.SUSPENDED,
                ].includes(customerDetails?.status)}
                type="primary"
              >
                {t("ADD")}
              </Button>
            </RestrictedComponent>
          </>
        ) : (
          <RestrictedComponent permission={PERMISSIONS.CUSTOMERS.verifier}>
            <Typography.Title level={3} style={{ marginBottom: 0 }}>
              {t("Credit Score")}
            </Typography.Title>
            <Typography.Text style={{ color: "#888", fontSize: 16 }}>
              {t("Add the customer's latest credit score from CIBIL")}
            </Typography.Text>
            <br />
            <br />
            <Space align="center" direction="vertical">
              <CreditsScoreDoc id="creditScore" width={64} height={64} />
              <Button
                style={{ width: 100 }}
                onClick={() => {
                  setIsModalOpen(true);
                }}
                disabled={[
                  CUSTOMER_STATUS.KYC_PENDING,
                  CUSTOMER_STATUS.REJECTED,
                  CUSTOMER_STATUS.SUSPENDED,
                ].includes(customerDetails?.status)}
                type="primary"
              >
                {t("ADD")}
              </Button>
            </Space>
          </RestrictedComponent>
        )}
      </section>
      <CustomerProfileCibilUploadForm
        open={isModalOpen}
        close={() => {
          setIsModalOpen(false);
        }}
      />
    </>
  );
};

export default CustomerProfileCibil;
