import { DetailDescriptionItem } from "components/loans/loans.util-components";
import ErrorRenderer from "components/util-components/ErrorRenderer";
import HiddenComponent from "components/util-components/HiddenComponent";
import Loader from "components/util-components/Loader";
import { NOT_APPLICABLE } from "appConstants";
import { RootState } from "store/store";
import { momentDateTimeFormat } from "components/util-components/dateTimeFormattor";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const CustomerDashboardPersonalDetails = () => {
  const { t } = useTranslation();
  const { customerDetails, loading, error } = useSelector(
    (state: RootState) => state?.customers?.customerProfile
  );

  return (
    <HiddenComponent hidden={loading} fallBack={<Loader />}>
      <HiddenComponent hidden={error} fallBack={<ErrorRenderer />}>
        <section>
          <DetailDescriptionItem label={t("Name")}>
            {customerDetails?.firstName + " " + customerDetails?.lastName}
          </DetailDescriptionItem>
          <br />
          <DetailDescriptionItem
            label={t("EPR Id")}
            copyable={!!customerDetails?.erpCustomer?.id}
          >
            {customerDetails?.erpCustomer?.id || NOT_APPLICABLE}
          </DetailDescriptionItem>
          <br />
          <DetailDescriptionItem label={t("Network")}>
            {customerDetails?.networkName}
          </DetailDescriptionItem>
          <br />
          <DetailDescriptionItem label={t("Address")}>
            {customerDetails?.address?.addressLine1 +
              "," +
              (customerDetails?.address?.addressLine2
                ? customerDetails?.address?.addressLine2 + ","
                : "") +
              customerDetails?.address?.city}
            <br />
            {customerDetails?.address?.state +
              "," +
              customerDetails?.address?.pincode}
          </DetailDescriptionItem>
          <br />
          <DetailDescriptionItem label={t("Mobile")}>
            {customerDetails?.phoneNumber}
          </DetailDescriptionItem>
          <br />
          <DetailDescriptionItem label={t("Email")}>
            {customerDetails?.email}
          </DetailDescriptionItem>
          <br />
          <DetailDescriptionItem label={t("Credit Score")}>
            {customerDetails?.creditScore?.score || NOT_APPLICABLE}
          </DetailDescriptionItem>
          <br />
          <DetailDescriptionItem label={t("Created On")}>
            {momentDateTimeFormat(customerDetails?.createTime, "date") ||
              NOT_APPLICABLE}
          </DetailDescriptionItem>
          <br />
          <DetailDescriptionItem label={t("Updated On")}>
            {momentDateTimeFormat(customerDetails?.updateTime, "date") ||
              NOT_APPLICABLE}
          </DetailDescriptionItem>
        </section>
      </HiddenComponent>
    </HiddenComponent>
  );
};

export default CustomerDashboardPersonalDetails;
