import "styles/LoanDashboard.less";

import { Divider, Result } from "antd";
import { ILoanType, ILoansStatus } from "store/types";

import HiddenComponent from "components/util-components/HiddenComponent";
import LoanDashboardCards from "components/loanDashboard/loan.dashboard.cards";
import LoanDashboardChildLoanDetails from "components/loanDashboard/loan.dashboard.childLoanDetails";
import LoanDashboardCustomerDetails from "components/loanDashboard/loan.dashboard.customerDetails";
import LoanDashboardHeader from "components/loanDashboard/loan.dashboard.header";
import LoanDashboardPayments from "components/loanDashboard/loan.dashboard.payments";
import LoanDashboardProductDetails from "components/loanDashboard/loan.dashboard.productDetails";
import LoanDashboardStatusAlert from "components/loanDashboard/loan.dashboard.statusAlert";
import LoanDashboardTimeLine from "components/loanDashboard/loan.dashboard.loanTimeline";
import { LoansActions } from "store/slices/loansSlice";
import { RootState } from "store/store";
import { useAppDispatch } from "store/hooks";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import LoanDashboardDistributorDetails from "components/loanDashboard/loan.dashboard.distributorDetails";

const LoanDashboardScreen = () => {
  const dispatch = useAppDispatch();
  const params = useParams();
  const { error, details } = useSelector(
    (state: RootState) => state?.loans?.loanDashboard
  );
  const { selectedAssetId } = useSelector(
    (state: RootState) => state?.loans.loanDashboard
  );

  console.log("details are: ", details);
  useEffect(() => {
    dispatch(LoansActions.fetchLoanById(params?.loanId || ""));
  }, []);

  useEffect(() => {
    if (selectedAssetId !== "") {
      dispatch(LoansActions.fetchLicenseKeysByAssetId(selectedAssetId));
    }
  }, [selectedAssetId]);

  return (
    <section className="loan-dashboard-page-wrapper" style={{ width: "100%" }}>
      <div className="loan-dashboard-header">
        <LoanDashboardHeader />
      </div>
      <HiddenComponent
        hidden={error}
        fallBack={
          <Result
            status="500"
            title="500"
            subTitle="Sorry, something went wrong."
          />
        }
      >
        <br />
        <div className="loan-dashboard-content-wrapper">
          <div className="loan-dashboard-customer-details">
            <LoanDashboardCustomerDetails />
          </div>
          <Divider type="vertical" className="loan-dashboard-divider" />
          <div className="loan-dashboard-cardsNTable-wrapper">
            <HiddenComponent
              hidden={
                ![
                  ILoansStatus.DOWN_PAYMENT_PENDING,
                  ILoansStatus.EMI_PENDING,
                  ILoansStatus.LICENSE_KEY_PENDING,
                  ILoansStatus.CHILD_LOAN_PENDING,
                  ILoansStatus.ASSIGN_DISPATCHER_PENDING,
                ].includes(details?.status)
              }
            >
              <LoanDashboardStatusAlert />
              <br />
              <br />
            </HiddenComponent>
            <LoanDashboardCards />
            <br />
            <br />
            <LoanDashboardProductDetails />
            <br />
            <br />

            <HiddenComponent hidden={details?.loanType === ILoanType.MORTGAGE}>
              <LoanDashboardChildLoanDetails />
              <br />
              <br />
            </HiddenComponent>

            <HiddenComponent hidden={details?.distributorId ? false : true}>
              <LoanDashboardDistributorDetails />
              <br />
              <br />
            </HiddenComponent>
            <LoanDashboardPayments />
          </div>
          <div className="loan-dashboard-timeLine-wrapper">
            <LoanDashboardTimeLine />
          </div>
        </div>
      </HiddenComponent>
    </section>
  );
};

export default LoanDashboardScreen;
