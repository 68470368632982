import React, { ErrorInfo, ReactNode } from "react";

import { Alert } from "antd";

type IProps = {
  children?: ReactNode;
};
type IState = {
  hasError: boolean;
};
class ErrorBoundary extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = { hasError: false };
  }
  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({ hasError: true });
    console.error("Uncaught error:", error);
    console.error("Uncaught errorInfo:", errorInfo);
  }
  render() {
    if (this.state.hasError) {
      return <Alert message={"Error in Rendering"} type="error" />;
    }
    return this.props.children;
  }
}

export default React.memo(ErrorBoundary);
