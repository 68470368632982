import "styles/CustomerStats.less";

import { CustomerStatsActions } from "store/slices/customerStatsSlice";
import CustomerStatsAssetDetails from "components/customerStats/customer.stats.assetDetails";
import CustomerStatsLoanDetails from "components/customerStats/customer.stats.loanDetails";
import CustomerStatsLoanStats from "components/customerStats/customer.stats.loanStats";
import { Divider } from "antd";
import { useAppDispatch } from "store/hooks";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

const CustomerStatsScreen = () => {
  const dispatch = useAppDispatch();
  const params = useParams();
  useEffect(() => {
    dispatch(
      CustomerStatsActions.fetchCustomerLoansById(params?.customerId || "")
    );
    dispatch(
      CustomerStatsActions.fetchCustomerDashboardStatsById(
        params?.customerId || ""
      )
    );
  }, []);
  return (
    <section style={{ height: "100%" }}>
      <div className="customer-stats-components-wrapper">
        {/* <div className="customer-stats-personal-details-wrapper">
          <CustomerStatsPersonalDetails />
        </div> */}
        {/* <Divider type="vertical" style={{ height: "100%" }} /> */}
        <div className="customer-stats-loan-stats-wrapper">
          <CustomerStatsLoanStats />
        </div>
        <Divider type="vertical" style={{ height: "100%" }} />
        <div className="customer-stats-asset-details-wrapper">
          <CustomerStatsAssetDetails />
          <br />
          <CustomerStatsLoanDetails />
        </div>
      </div>
    </section>
  );
};

export default CustomerStatsScreen;
