export interface IAuthState {
  loading: boolean;
  authorized: boolean;
  userInfo: UserInfo;
  error: boolean;
  homeLink: string;
}

export interface ICommonState {
  tabTitle: string;
  sideBarCollapsed: boolean;
  offline: boolean;
}

export interface ILogin {
  email: string;
  password: string;
}

export interface ILoginResponse {
  email: string;
  username: string;
  accessToken: string;
  tokenExpiryTime: string;
}

export interface UserInfo {
  emailId?: string;
  firstName: string;
  id: string;
  lastName?: string;
  phoneNumber?: string;
  role: ROLES;
  distributorId?: string;
}

export enum ROLES {
  ADMIN = "ADMIN",
  CREDIT_APPROVER = "CREDIT_APPROVER",
  LICENSE_KEY_MANAGER = "LICENSE_KEY_MANAGER",
  DELIVERY_MANAGER = "DELIVERY_MANAGER",
  VIEW_ADMIN = "VIEW_ADMIN",
  DISTRIBUTOR_ADMIN = "DISTRIBUTOR_ADMIN",
}

export enum ROLES_VIEW {
  ADMIN = "Admin",
  CREDIT_APPROVER = "Credit approver",
  LICENSE_KEY_MANAGER = "License key manager",
  DELIVERY_MANAGER = "Delivery manager",
  VIEW_ADMIN = "View admin",
  DISTRIBUTOR_ADMIN = "Distributor Admin",
}

export interface IUsersList {
  items: IUser[];
  pageSize: number;
  pageToken: string;
}

export interface IUser {
  id: string;
  role: ROLES;
  phoneNumber: string;
  emailId?: string;
  firstName: string;
  lastName?: string;
  createTime: string;
  createdBy: string;
  branchId: string;
}

export interface IDistributorsList {
  items: IDistributor[];
  pageSize: number;
  pageToken: string;
}

export interface IDistributorAddress {
  addressLine1: string;
  addressLine2?: string;
  city: string;
  state: string;
  country: string;
  pincode: string;
}

export interface IDistributorStats {
  pendingCommissions?: number;
  pendingCommissionAmount?: number;
  paidCommissions?: number;
  paidCommissionAmount?: number;
}

export interface IDistributor {
  distributorId: string;
  networkName: string;
  contactPersonName: string;
  email: string;
  phoneNumber: string;
  address: IDistributorAddress;
}

export interface IWhoAmIResponse {
  id: string;
  firstName: string;
  lastName: string;
  emailId: string;
  phoneNumber: string;
  role: ROLES;
  accountStatus: string;
}

export interface ICustomersList {
  items: ICustomer[];
  pageSize: number;
  pageToken?: string;
}

export interface IAddress {
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  pincode: string;
}

export interface ICustomer {
  id: string;
  customerId?: string;
  status: CUSTOMER_STATUS;
  networkName?: string; //should be mandatory
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  address: IAddress;
  kyc: {
    status: CUSTOMER_KYC_STATUS;
    documents: ICustomerDocument[];
    verificationDate?: string;
  };
  creditScore?: ICreditScore;
  creditScoreHistory?: ICreditScore[];
  erpCustomer: {
    id: string;
    updateTime: string;
    updatedBy: string;
  };
  loanSummary?: ICustomerLoanSummary;
  createTime: string;
  updateTime?: string;
  remarks?: string;
  vip: boolean;
  distributorId?: string;
}

export interface ICreditScore {
  customerId: string;
  score: number;
  bureau: {
    name: string;
    minScore: number;
    maxScore: number;
  };
  supportingDocumentLink?: string;
  remark?: string;
  updateTime: string;
  updatedBy: string;
}

export enum CUSTOMER_STATUS {
  KYC_PENDING = "KYC_PENDING",
  CREDIT_SCORE_VERIFICATION_PENDING = "CREDIT_SCORE_VERIFICATION_PENDING",
  ERP_MAPPING_PENDING = "ERP_MAPPING_PENDING",
  ACTIVE = "ACTIVE",
  DEACTIVATED = "DEACTIVATED",
  REJECTED = "REJECTED",
  SUSPENDED = "SUSPENDED",
  CLOSED = "CLOSED",
  VERIFICATION_COMPLETED = "VERIFICATION_COMPLETED",
}

export enum CUSTOMER_STATUS_DISPLAY_TEXT {
  KYC_PENDING = "Kyc Pending",
  CREDIT_SCORE_VERIFICATION_PENDING = "Credit Score Verification Pending",
  ERP_MAPPING_PENDING = "ERP Mapping Pending",
  ACTIVE = "Active",
  DEACTIVATED = "Deactivated",
  REJECTED = "Rejected",
  SUSPENDED = "Suspended",
  CLOSED = "Closed",
  VERIFICATION_COMPLETED = "Verification Completed",
}

export enum CUSTOMER_KYC_STATUS {
  PENDING = "PENDING",
  SUBMITTED = "SUBMITTED",
  COMPLETED = "COMPLETED",
  REJECTED = "REJECTED",
  RESUBMITTED = "RESUBMITTED",
  //PENDING, SUBMITTED, COMPLETED, DOC_REJECTED, REJECTED, RESUBMITTED
}

export enum CUSTOMER_KYC_STATUS_VIEW {
  PENDING = "Document not submitted",
  SUBMITTED = "Pending",
  COMPLETED = "Verified",
  REJECTED = "Rejcted",
  RESUBMITTED = "Pending",
  //PENDING, SUBMITTED, COMPLETED, DOC_REJECTED, REJECTED, RESUBMITTED
}

export interface ICustomerDocument {
  type: CUSTOMER_DOCUMENT_TYPE;
  frontPageLink?: string;
  backPageLink?: string;
  uploadTime?: string;
  status: CUSTOMER_DOCUMENT_STATUS;
  rejectionReason?: string;
  phoneNumber?: string;
}

export enum CUSTOMER_DOCUMENT_TYPE {
  AADHAR_CARD = "AADHAR_CARD",
  PAN_CARD = "PAN_CARD",
  BANK_STATEMENT = "BANK_STATEMENT",
}

export enum CUSTOMER_DOCUMENT_TYPE_VIEW {
  AADHAR_CARD = "Aadhar Card",
  PAN_CARD = "Pan Card",
  BANK_STATEMENT = "Bank Statement",
}

export enum CUSTOMER_DOCUMENT_STATUS {
  PENDING = "PENDING", //Doc not submitted
  SUBMITTED = "SUBMITTED", // Doc submitted but not verified
  VERIFIED = "VERIFIED", //Doc verified
  RESUBMITTED = "RESUBMITTED", // Same as Submitted
  REJECTED = "REJECTED", // Doc rejected
}
export enum CUSTOMER_DOCUMENT_STATUS_VIEW {
  PENDING = "Not Submitted", //Doc not submitted
  SUBMITTED = "Pending", // Doc submitted but not verified
  VERIFIED = "Verified", //Doc verified
  RESUBMITTED = "Resubmitted", // Same as Submitted
  REJECTED = "Rejected", // Doc rejected
}

// used for the modal popup doc viewer
export const DOC_STEPS = {
  AADHAR: 0,
  PAN: 1,
  BANK_STATEMENT: 2,
  VERIFY: 3,
};

export interface IDocumentRejection {
  documentType: CUSTOMER_DOCUMENT_TYPE;
  status: CUSTOMER_DOCUMENT_STATUS;
  rejectionReason: string;
}

export interface IProductsList {
  items: IProduct[];
  pageSize: number;
  pageToken?: string;
}

export interface IProduct {
  id: string;
  name: string;
  brand: string;
  group: string;
  isPrimary: boolean;
  productLink: string;
  retailPrice: number;
  imageLinks: string[];
  emiOptions: IProductEmiOptions[];
  attributes: { [key: string]: string };
  createTime: string;
  updateTime: string;
  distributorFee?: number;
}

export interface IProductEmiOptions {
  downPayment?: number;
  tenure?: number;
  processingFee?: number;
}

export enum PURCHASE_MODES {
  ONE_TIME_PAYMENT = "ONE_TIME_PAYMENT",
  EMI = "EMI",
}
export enum PURCHASE_MODES_VIEW {
  ONE_TIME_PAYMENT = "One time payment",
  EMI = "EMI",
}

export interface ILoansList {
  items: ILoan[];
  total: number;
  pageSize?: number;
  pageToken?: string;
}
export interface ILoan {
  id: string;
  loanRequestId: string;
  customerId: string;
  status: ILoansStatus;
  parentLoanId: string;
  childLoanIds: string[];
  applyTime: string;
  purchasedItems: IProductItem[];
  tenure: number;
  loanAmount: number;
  processingFee: number;
  downPayment: number;
  outstandingAmount: number;
  monthlyEmi: number;
  totalMonthlyEmi: number;
  lastPaidEmi: IEmiDetail;
  optedForZeroDownPayment: boolean;
  downPaymentDetails: IDownPayment;
  emis?: IEmiDetail[];
  managedAssets: IManagedAsset[];
  endDate: string;
  notes: ILoansNote[];
  closingNote?: ILoansNote;
  approvedBy: string;
  approveTime: string;
  revisions: [{}]; //Fix needed
  customerDetails: LoanResponseCustomerDetails;
  loanType: ILoanType;
  mappedAssetDetails: {
    assetName: string;
    assetMac: string;
    parentLoanId: string;
    assetId: string;
  };
  distributorFee?: number;
  distributorFeeTransactions?: IDistributorFeeTransaction[];
  distributorId?: string;
  distributorFeePaid?: boolean;
  emiStartDate: string;
  emiEndDate: string;
  nextEmiDate: string;
  lastEmiPaidDate: string;
  customerEmiDate: string;
}

export interface IProductItem {
  id: string;
  price: number;
  name: string;
  count: number;
  processingFee: number;
  emiOption: IProductEmiOptions;
}

export interface IEmiDetail {
  amount: number;
  dueDate: string;
  amountRemainingPostPayment?: number;
  status: EmiStatus;
  paymentMode: string;
  transactionId?: string;
  transactionTime?: string;
  paidBy?: string;
  paidTime?: string;
  paymentType: IPaymentTypes.EMI; //intentionally hardcoded,
  licenseKeyDispatched?: string | null;
}

export interface IDownPayment {
  amount: number;
  dueDate: string;
  amountRemainingPostPayment?: number;
  lastPaymentDate?: string;
  status: EmiStatus;
  transactionId?: string;
  transactionTime?: string;
  paidTime?: string;
  paidBy?: string;
  paymentType: IPaymentTypes.EMI; //intentionally hardcoded
}

export interface IAssetLicenseKey {
  productName: string;
  assetId: string;
  mac: string;
  lastDispatchedKey?: string;
  lastDispatchedTime?: string;
  licenseKeys: [{}];
}

export enum ILoansStatus {
  DEVICE_MAPPING_PENDING = "DEVICE_MAPPING_PENDING",
  DOWN_PAYMENT_PENDING = "DOWN_PAYMENT_PENDING",
  EMI_PENDING = "EMI_PENDING",
  LICENSE_KEY_PENDING = "LICENSE_KEY_PENDING",
  ACTIVE = "ACTIVE",
  CLOSED = "CLOSED",
  ASSIGN_DISPATCHER_PENDING = "ASSIGN_DISPATCHER_PENDING",
  DISPATCH_PENDING = "DISPATCH_PENDING",
  CHILD_LOAN_PENDING = "CHILD_LOAN_PENDING",
}

export enum ILoansStatus_View {
  DEVICE_MAPPING_PENDING = "Device mapping pending",
  DOWN_PAYMENT_PENDING = "Down payment pending",
  EMI_PENDING = "EMI pending",
  LICENSE_KEY_PENDING = "License key pending",
  ACTIVE = "Active",
  CLOSED = "Closed",
  DISPATCH_PENDING = "Dispatch pending",
  ASSIGN_DISPATCHER_PENDING = "Assign dispatcher pending",
  CHILD_LOAN_PENDING = "Child order pending",
}

export enum EmiStatus {
  PENDING = "PENDING",
  DUE = "DUE",
  AUTO_DEBIT_FAILED = "AUTO_DEBIT_FAILED",
  GRACE_PERIOD_OVER = "GRACE_PERIOD_OVER",
  PAID = "PAID",
}

export enum EmiStatus_View {
  PENDING = "Pending",
  DUE = "Due",
  AUTO_DEBIT_FAILED = "Auto debit failed",
  GRACE_PERIOD_OVER = "Grace period over",
  PAID = "Paid",
}

export interface ILoansNote {
  note: string;
  time: string;
  author: string;
}

export interface ILoansRequestList {
  items: ILoansRequest[];
  pageSize: number;
  pageToken: string;
}

export enum ILoanType {
  PRIMARY = "PRIMARY",
  MORTGAGE = "MORTGAGE",
}

export interface ILoansRequest {
  id: string;
  customerId: string;
  customerName: string;
  status: ILoanRequestStatus;
  purchasedItems: IProductItem[];
  tenure: number;
  loanAmount: number;
  processingFee: number;
  downPayment: number;
  monthlyEmi: number;
  totalMonthlyEmi: number;
  optedForZeroDownPayment: boolean;
  monthlyEmiWithoutDownPayment: number;
  totalMonthlyEmiWithoutDownPayment: number;
  startDate: string;
  endDate: string;
  notes: ILoansNote[];
  closedBy?: string;
  closeTime?: string;
  closingRemark?: ILoansNote;
  createTime: string;
  loanType: ILoanType;
}

export enum ILoanRequestStatus {
  PENDING = "PENDING",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
}

export enum ILoanScreenTabs {
  ACTIVE = "ACTIVE,CHILD_LOAN_PENDING",
  CLOSED = "CLOSED",
  DEVICE_MAPPING_PENDING = "DEVICE_MAPPING_PENDING",
  DOWN_PAYMENT_PENDING = "DOWN_PAYMENT_PENDING",
  LICENSE_KEY_PENDING = "LICENSE_KEY_PENDING",
  ASSIGN_DISPATCHER_PENDING = "ASSIGN_DISPATCHER_PENDING",
  EMI_PENDING = "EMI_PENDING",
  DISPATCH_PENDING = "DISPATCH_PENDING",
}

export enum ILoanRequestScreenTabs {
  NEW_REQUESTS = "NEW_REQUESTS",
  REJECTED_REQUESTS = "REJECTED_REQUESTS",
}

export interface LoanResponseCustomerDetails {
  id: string;
  externalCustomerId: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  emailId: string;
  networkName: string;
  address: {
    addressLine1: string;
    addressLine2?: string;
    city: string;
    state: string;
    pincode: string;
  };
}

export interface ILicenseKey {
  id: string;
  validityStart: string;
  validityEnd: string;
  issueTime: string;
  issuedBy: string;
  deliveryTime: string;
  deliveryStatus: LicenseKeyDeliverySatus;
  deliveryFailureReason?: string;
}

export interface IDistributorFeeTransaction {
  transactionId: string;
  paidOn: string;
}

export interface IManagedAsset {
  id: string;
  mac: string;
  productId: string;
  productName: string;
  loanId?: string;
  licenseKeys?: ILicenseKey[];
  deliveryTime?: string;
  erpId: string;
}

export enum LicenseKeyDeliverySatus {
  PENDING = "PENDING",
  DELIVERED = "DELIVERED",
  FAILED = "FAILED",
}
export enum LicenseKeyDeliverySatus_View {
  PENDING = "Pending",
  DELIVERED = "Delivered",
  FAILED = "Failed",
}

export interface ICustomerLoanSummary {
  activeLoanCount: number;
  closedLoanCount: number;
  rejectedLoanCount: number;
  pendingLoanCount: number;
  totalOutstandingAmount: number;
  totalLoanAmount: number;
  failedEmiCount: number;
  monthlyEmi: number;
  loanEndingDate: string;
}

export interface IBranchesList {
  items: IBranch[];
  pageSize: number;
  pageToken: string;
}

export interface IBranch {
  id: string;
  name: string;
  phoneNumber: string;
  email: string;
  createdBy: string;
  createTime: string;
  updateTime: string;
}

export enum IPaymentTypes {
  EMI = "EMI",
  DOWN_PAYMENT = "DOWN_PAYMENT",
}

export enum IPaymentTypes_View {
  EMI = "EMI",
  DOWN_PAYMENT = "Down Payment",
}

export interface IPagination {
  pageSize?: number;
  pageToken?: number;
}

export enum POSSIBLE_PAGINATION_LIST {
  customersListKYCPending = "customersListKYCPending",
  customersListActive = "customersListActive",
  customersListRejected = "customersListRejected",
}

export interface IAsset {
  id: string;
  invoiceId: string;
  mac: string;
  customerId: string;
  productId: string;
  loanId: string;
  licenseKeys: {
    id: string;
    s3Link: string;
    issueTime: string;
    issuedBy: string;
    deliveryStatus: LicenseKeyDeliverySatus;
    deliveryFailureReason: string;
  };
  deliveryTime: string;
  hasActiveLoan: boolean;
  productName: string;
}

export interface IActiveAsset {
  activeChildLoanIds: string[];
  loanEndDate: string;
  loanId: string;
  loanStartDate: string;
  mac: string;
  productName: string;
  assetId: string;
}

export interface IDashboardStats {
  loansCount: ILoansCount;
  activeLoans: IActiveLoan[];
  activeLoanStats: IActiveLoanStats;
}
export interface ILoansCount {
  activeLoanCount: number;
  closedLoanCount: number;
  rejectedLoanCount: number;
  pendingLoanCount: number;
}
export interface IActiveLoan {
  loanId: string;
  startDate: string;
  paidMonths: number;
  totalMonths: number;
  outstandingAmount: number;
}
export interface IActiveLoanStats {
  totalAmount: number;
  paidAmount: number;
  lastPaymentDate?: string;
  nextEmiAmount: number;
  nextEmiDueDate?: string;
}

export interface ILoansReduxState {
  newRequestsTable: {
    details: ILoansRequest[];
    loading: boolean;
    error: boolean;
    errorStatus: number;
  };
  rejectedRequestsTable: {
    details: ILoansRequest[];
    loading: boolean;
    error: boolean;
    errorStatus: number;
  };
  activeLoansTable: {
    details: ILoansList;
    loading: boolean;
    error: boolean;
    errorStatus: number;
  };
  closedLoansTable: {
    details: ILoansList;
    loading: boolean;
    error: boolean;
    errorStatus: number;
  };
  deviceMappingPending: {
    details: ILoansList;
    loading: boolean;
    error: boolean;
    errorStatus: number;
  };
  downPaymentPending: {
    details: ILoansList;
    loading: boolean;
    error: boolean;
    errorStatus: number;
  };
  licenseKeyPending: {
    details: ILoansList;
    loading: boolean;
    error: boolean;
    errorStatus: number;
  };
  emiPending: {
    details: ILoansList;
    loading: boolean;
    error: boolean;
    errorStatus: number;
  };
  dispatchPending: {
    details: ILoansList;
    loading: boolean;
    error: boolean;
    errorStatus: number;
  };
  assignDispatcherPending: {
    details: ILoansList;
    loading: boolean;
    error: boolean;
    errorStatus: number;
  };
  currentRequestTab: ILoanRequestScreenTabs;
  currentLoansTab: string;
  loansPagination: IPagination;
  loanSearchValues: {
    customerId?: string;
    loanNumber?: string;
  };
  loanSortValues: {
    sort?: string;
  };
}

export enum LOAN_LIST_SEARCH_CATEGORIES {
  CUSTOMER = "customer",
  LOAN_NUMBER = "loan-number",
}

export const LOADING = "loading";
