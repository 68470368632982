import 'styles/Content.less';

import { Layout, Spin } from 'antd';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { Suspense, useEffect } from 'react';

import CustomerDashboardScreen from './customerDashboard/customer.dashboard.screen';
import CustomerProfileCibilHistory from 'components/customerProfile/customer.profile.cibil.history';
import CustomersScreen from './customers/customers.screen';
import DistributorCreateForm from 'components/distributors/distributor.create.form';
import DistributorDashboard from 'components/distributors/distributor.dashboard';
import DistributorDetails from 'components/distributors/distributors.details';
import DistributorEditForm from 'components/distributors/distributor.edit.form';
import DistributorsScreen from './distributors/distributors.screen';
import ErrorBoundary from 'components/util-components/ErrorBoundary';
import Home from './home/home';
import LoanDashboardScreen from './loanDashboard/loan.dashboard.screen';
import LoanRequestDashboard from 'components/loans/loans.request.dashboard';
import LoanRequestScreen from './loansRequest/loans.request.screen';
import LoanScreen from './loans/loans.screen';
import LoginScreen from './auth/login.screen';
import ProductCreateForm from 'components/products/products.create';
import ProductScreen from './product/products.screen';
import ProductsDetails from 'components/products/products.view';
import { RootState } from 'store/store';
import UserDetails from 'components/users/users.details';
import UserEditForm from 'components/users/user.edit.form';
import UserForm from '../components/users/user.form';
import UsersScreen from './users/users.screen';
import { useSelector } from 'react-redux';

const Content = () => {
  const { auth } = useSelector((state: RootState) => state);
  let navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!auth.authorized) {
      console.log('is not authorized');
      navigate('/login');
    } else {
      if (['/login', '/'].includes(location.pathname)) {
        console.log('navigating to homelink: ', auth?.homeLink);
        navigate(auth?.homeLink);
      }
    }
    return () => {};
  }, [auth.authorized]);

  return (
    <Suspense fallback={<Spin />}>
      <ErrorBoundary>
        <Layout
          id={'content-container'}
          className={auth.authorized ? 'content-container' : ''}
        >
          <Routes>
            <Route path='/login' element={<LoginScreen />} />
            <Route path='/' element={<Home />} />
            <Route path='/home' element={<Home />} />
            <Route path='/products' element={<ProductScreen />}>
              <Route path='new' element={<ProductCreateForm />} />
              <Route path=':productId/details' element={<ProductsDetails />} />
              <Route path=':productId/edit' element={<ProductCreateForm />} />
            </Route>
            <Route path='/customers/list/*' element={<CustomersScreen />} />
            <Route
              path='/customers/:customerId/*'
              element={<CustomerDashboardScreen />}
            />
            {/* ---------------------------------------- */}
            {/* Added for outlet issue */}
            <Route
              path='/customers/:customerId/profile'
              element={<CustomerDashboardScreen />}
            >
              <Route
                path='credit-score-history'
                element={<CustomerProfileCibilHistory />}
              />
            </Route>
            {/* ---------------------------------------- */}
            <Route path='/distributors' element={<DistributorsScreen />}>
              <Route path='new' element={<DistributorCreateForm />} />
              <Route
                path=':distributorId/details'
                element={<DistributorDetails />}
              />
              <Route
                path=':distributorId/edit'
                element={<DistributorEditForm />}
              />
            </Route>

            <Route
              path='/distributors/:distributorId/dashboard'
              element={<DistributorDashboard />}
            />

            <Route path='/users' element={<UsersScreen />}>
              <Route path='new' element={<UserForm />} />
              <Route path=':userId/details' element={<UserDetails />} />
              <Route path=':userId/edit' element={<UserEditForm />} />
            </Route>

            <Route
              path='/user-profile/:userId/details'
              element={<UserDetails />}
            />

            <Route
              path='/user-profile/:userId/edit'
              element={<UserEditForm />}
            />
            <Route path='/orders/accepted-list' element={<LoanScreen />}>
              <Route path=':tabId' element={<LoanScreen />} />
            </Route>
            <Route
              path='/orders/requests-list/*'
              element={<LoanRequestScreen />}
            />
            <Route
              path='/orders/details/:loanId'
              element={<LoanDashboardScreen />}
            />
            <Route
              path='orders/loan-request/:loanRequestId'
              element={<LoanRequestDashboard />}
            />
          </Routes>
        </Layout>
      </ErrorBoundary>
    </Suspense>
  );
};

export default Content;
