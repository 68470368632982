import { Button, Progress, Space, Table, Tooltip, Typography } from "antd";
import { EmiStatus, ILoan } from "store/types";

import { ColumnsType } from "antd/es/table";
import ErrorRenderer from "components/util-components/ErrorRenderer";
import HiddenComponent from "components/util-components/HiddenComponent";
import { IndianRuppeeView } from "components/products/product.util.components";
import { LoansListActions } from "store/slices/loansListSlice";
import { RootState } from "store/store";
import { ShowProductsInTable } from "./loans.util-components";
import { momentDateTimeFormat } from "components/util-components/dateTimeFormattor";
import { useAppDispatch } from "store/hooks";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const LoansListActive = () => {
  const { loansList } = useSelector((state: RootState) => state);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { details, loading, error, errorStatus } = loansList.activeLoansTable;

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    dispatch(
      LoansListActions.setLoanSortValues({
        sort: `${sorter.columnKey}:${
          sorter.order === "ascend" ? "asc" : "desc"
        }`,
      })
    );
  };

  const columns: ColumnsType<ILoan> = [
    {
      title: t("Order Id"),
      key: "loanId",
      render: (_, record) => {
        return <Typography.Text copyable>{record?.id}</Typography.Text>;
      },
    },
    {
      title: t("Products"),
      key: "products",
      render: (_, record) => {
        return <ShowProductsInTable productList={record?.purchasedItems} />;
      },
    },
    {
      title: t("labels.customers.name"),
      key: "customerName",
      render: (_, record) => {
        return (
          <Typography.Link
            onClick={() => {
              window.open(
                `/customers/${record?.customerId}/dashboard`,
                "_blank"
              );
            }}
          >
            {record?.customerDetails?.firstName +
              " " +
              record?.customerDetails?.lastName}
          </Typography.Link>
        );
      },
    },
    {
      title: t("Order Amount"),
      key: "loanAmount",
      render: (_, record) => {
        return (
          <>
            <IndianRuppeeView value={record?.loanAmount?.toString()} />
          </>
        );
      },
    },
    {
      title: t("Outstanding balance"),
      key: "outstandingBalance",
      render: (_, record) => {
        return (
          <>
            <IndianRuppeeView value={record?.outstandingAmount?.toString()} />
          </>
        );
      },
    },
    {
      title: t("Emi Details"),
      key: "emiDetails",
      width: 230,
      render: (_, record) => {
        const paidEmi =
          record?.emis?.filter((emi) => {
            return emi?.status === EmiStatus.PAID;
          }).length || 0;
        const totalEmi = record?.emis?.length || 0;
        const paidEmiPercentage = (paidEmi / totalEmi) * 100;
        return (
          <>
            <Typography.Text>
              <b>Tenure: </b>
              <Tooltip
                title={
                  <>
                    {t("Ends on ") +
                      momentDateTimeFormat(record?.endDate, "date")}
                  </>
                }
              >
                {paidEmi} / {totalEmi + " months"}
              </Tooltip>
            </Typography.Text>
            <br />
            <Typography.Text>
              {momentDateTimeFormat(record?.emiStartDate, "date")} -{" "}
              {momentDateTimeFormat(record?.emiEndDate, "date")}
            </Typography.Text>
            <br />
            <Progress
              percent={paidEmiPercentage}
              showInfo={false}
              format={() => (
                <>
                  <Typography.Text
                    strong
                  >{`${paidEmi} / ${totalEmi}`}</Typography.Text>
                </>
              )}
            />
          </>
        );
      },
    },
    {
      title: t("Last Emi Date"),
      key: "lastEmiDate",
      render: (_, record) => {
        return <> {momentDateTimeFormat(record?.lastEmiPaidDate, "date")}</>;
      },
    },
    {
      title: t("Next Emi Date"),
      key: "nextEmiDate",
      sorter: true,
      defaultSortOrder: "ascend",
      render: (_, record) => {
        return <> {momentDateTimeFormat(record?.customerEmiDate, "date")}</>;
      },
    },
    // {
    //   title: t("EMIs paid"),
    //   key: "emis",
    //   render: (_, record) => {
    //     const paidEmi =
    //       record?.emis?.filter((emi) => {
    //         return emi?.status === EmiStatus.PAID;
    //       }).length || 0;
    //     const totalEmi = record?.emis?.length || 0;
    //     const paidEmiPercentage = (paidEmi / totalEmi) * 100;
    //     return (
    //       <>
    //         <Progress
    //           percent={paidEmiPercentage}
    //           format={() => (
    //             <Typography.Text
    //               strong
    //             >{`${paidEmi} / ${totalEmi}`}</Typography.Text>
    //           )}
    //         />
    //       </>
    //     );
    //   },
    // },

    {
      title: t("Actions"),
      key: "actions",
      render: (_, record) => {
        return (
          <>
            <Button
              onClick={() => {
                window.open(`/orders/details/${record?.id}`, "_blank");
              }}
              type="link"
            >
              {t("View")}
            </Button>
          </>
        );
      },
    },
  ];
  return (
    <HiddenComponent
      hidden={error}
      fallBack={<ErrorRenderer status={errorStatus} />}
    >
      <Table
        bordered
        sticky={true}
        size={"small"}
        rowKey={(data) => data?.id}
        loading={loading}
        dataSource={details?.items}
        columns={columns}
        scroll={{
          x: "calc(100vw - 150px)",
          scrollToFirstRowOnChange: true,
        }}
        onChange={handleTableChange}
        pagination={{
          showSizeChanger: true,
          pageSize: loansList?.loansPagination?.pageSize,
          current: loansList?.loansPagination?.pageToken,
          total: details?.total,
          onChange: (pageNumber, pageSize) => {
            if (loansList?.loansPagination?.pageSize !== pageSize) {
              dispatch(
                LoansListActions.setLoansPagination({
                  pageSize: pageSize,
                  pageToken: 1,
                })
              );
            } else {
              dispatch(LoansListActions.setLoansPageToken(pageNumber));
            }
          },
          showTotal: () => {
            return (
              <Space>
                <Typography.Text>{`${t(
                  "labels.loans.totalCount"
                )}:`}</Typography.Text>
                <Typography.Text>{details?.pageSize || 0}</Typography.Text>
              </Space>
            );
          },
        }}
      />
    </HiddenComponent>
  );
};

export default LoansListActive;
