import { Button, Space, Typography } from "antd";
import RestrictedComponent, {
  PERMISSIONS,
} from "components/util-components/RestrictedComponent";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PlusOutlined } from "@ant-design/icons";
import { faCube } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ProductsHeader = () => {
  const { t } = useTranslation();
  const headerComponentFontSize = 24;
  const navigate = useNavigate();
  return (
    <Space style={{ justifyContent: "space-between", width: "100%" }}>
      <Typography.Text style={{ fontSize: headerComponentFontSize }}>
        <FontAwesomeIcon
          icon={faCube}
          color={"inherit"}
          fontSize={headerComponentFontSize}
        />{" "}
        {t("labels.products.title")}
      </Typography.Text>
      <RestrictedComponent permission={PERMISSIONS.PRODUCTS.create}>
        <Button
          type="primary"
          onClick={() => {
            navigate("/products/new");
          }}
          icon={<PlusOutlined />}
        >
          {t("labels.products.create")}
        </Button>
      </RestrictedComponent>
    </Space>
  );
};

export default ProductsHeader;
