import { IBranch, IUser, ROLES, ROLES_VIEW } from "store/types";
import {
  AutoComplete,
  Input,
  SelectProps,
  Spin,
  Table,
  Tag,
  Typography,
} from "antd";

import { ColumnsType } from "antd/es/table";
import ErrorRenderer from "components/util-components/ErrorRenderer";
import HiddenComponent from "components/util-components/HiddenComponent";
import { RootState } from "store/store";
import { useAppDispatch } from "store/hooks";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { LoansActions } from "store/slices/loansSlice";

const LoanDashboardDispatcherList = (props: {
  onUserSelection: (userId: string) => void;
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const dispatchers = useSelector(
    (state: RootState) => state.loans.dispatchersList
  );
  const branches = useSelector((state: RootState) => state.loans.branchesList);

  const [options, setOptions] = useState<SelectProps<object>["options"]>([]);

  const onSelect = (value: string) => {
    console.log("onSelect", value);
    dispatch(
      LoansActions.fetchDispatchers({
        role: `${ROLES.ADMIN},${ROLES.DELIVERY_MANAGER}`,
        branchId: value,
      })
    );
  };

  const handleOnChange = (e: any) => {
    if (!e.target.value) {
      console.log("target value: ", e.target.value);
      dispatch(
        LoansActions.fetchDispatchers({
          role: `${ROLES.ADMIN},${ROLES.DELIVERY_MANAGER}`,
        })
      );
    }
  };

  useEffect(() => {
    if (branches) {
      const branchOptions: SelectProps<object>["options"] = [];
      branches.details.forEach((branch: IBranch) => {
        let branchDetail = { value: "", label: "" };
        branchDetail.value = branch.id;
        branchDetail.label = branch.name;
        branchOptions.push(branchDetail);
      });
      setOptions(branchOptions);
    }
  }, [branches]);

  useEffect(() => {
    dispatch(
      LoansActions.fetchDispatchers({
        role: `${ROLES.ADMIN},${ROLES.DELIVERY_MANAGER}`,
      })
    );
    dispatch(LoansActions.fetchBranches({}));
  }, []);

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any) => {
      props.onUserSelection(selectedRowKeys + "");
    },
    getCheckboxProps: (record: any) => ({
      disabled: record.name === "Disabled User", // Column configuration not to be checked
      name: record.name,
    }),
  };

  const columns: ColumnsType<IUser> = [
    {
      title: t("labels.users.name"),
      key: "userName",
      render: (_, record) => {
        return (
          <>
            <Typography.Text>
              {record?.firstName + " " + record?.lastName}
            </Typography.Text>
            <Typography.Text style={{ marginLeft: "10px" }}>
              <Tag color="default">{ROLES_VIEW[record?.role]}</Tag>
            </Typography.Text>
          </>
        );
      },
    },
    {
      title: t("labels.users.phoneNumber"),
      dataIndex: "phoneNumber",
      key: "phoneNumber",
    },
    {
      title: t("labels.users.email"),
      dataIndex: "emailId",
      key: "email",
    },
    {
      title: t("Branch"),
      dataIndex: "branchId",
      key: "branch",
    },
  ];

  return (
    <HiddenComponent hidden={dispatchers.error} fallBack={<ErrorRenderer />}>
      <AutoComplete
        dropdownMatchSelectWidth={252}
        style={{
          width: 300,
          marginTop: "15px",
          marginBottom: "10px",
          float: "right",
        }}
        options={options}
        notFoundContent={<Spin />}
        onSelect={onSelect}
        filterOption={(inputValue, option) => {
          return option!.value
            ? (option!.value + "")
                .toUpperCase()
                .indexOf(inputValue.toUpperCase()) !== -1
            : true;
        }}
      >
        <Input.Search
          size="large"
          placeholder="Search branch"
          enterButton
          allowClear
          onChange={handleOnChange}
        />
      </AutoComplete>

      <Table
        bordered
        rowSelection={{
          type: "radio",
          ...rowSelection,
        }}
        rowKey={(data) => data?.id}
        loading={dispatchers?.loading}
        dataSource={dispatchers?.details}
        columns={columns}
        size="small"
        style={{ width: "100%" }}
      />
    </HiddenComponent>
  );
};

export default LoanDashboardDispatcherList;
