import "styles/LoanDashboard.less";

import {
  Alert,
  Button,
  Divider,
  Space,
  Table,
  Tooltip,
  Typography,
  message,
} from "antd";
import {
  EditOutlined,
  EyeOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import {
  IEmiDetail,
  ILoanType,
  ILoansStatus,
  IManagedAsset,
  LOADING,
} from "store/types";
import { Requester, formatError, formatURL } from "api/requester";
import RestrictedComponent, {
  PERMISSIONS,
} from "components/util-components/RestrictedComponent";

import { API_END_POINTS } from "api/urls";
import { ColumnsType } from "antd/es/table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import HiddenComponent from "components/util-components/HiddenComponent";
import LoanDashboardAccessoriesProductDetails from "./loan.dashboard.accessoriesProductDetails";
import LoanDashboardLicenseKeyForm, {
  LicenseKeyModes,
} from "./loan.dashboard.licenseKeyForm";
import LoanDashboardLicenseKeyTable from "./loan.dashboard.licenseKey.table";
import LoanDashboardMacForm from "./loan.dashboard.macForm";
import LoanDashboardProductDetailsHeading from "./loan.dashboard.productDetails.heading";
import { LoansActions } from "store/slices/loansSlice";
import { RootState } from "store/store";
import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import { useAppDispatch } from "store/hooks";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export const LOAN_PRODUCT_ACTIONS = { SEND_LICENSE: "SEND_LICENSE" };

interface ILoanDashboardProductDetailsProps {
  actionType?: typeof LOAN_PRODUCT_ACTIONS.SEND_LICENSE;
  emiRecord?: IEmiDetail;
}

const LoanDashboardProductDetails = (
  props: ILoanDashboardProductDetailsProps
) => {
  const { details, loading } = useSelector(
    (state: RootState) => state.loans.loanDashboard
  );
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [isLicenseKeyFormOpen, setIsLicenseKeyFormOpen] = useState(false);
  const [isMacKeyFormOpen, setIsMacKeyFormOpen] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState<IManagedAsset>(
    {} as IManagedAsset
  );
  const [isLicenseKeysModalOpen, setIsLicenseKeysModalOpen] = useState(false);
  const [shareLicenseKeySelectedID, setShareLicenseKeySelectedID] = useState(
    null as string | null
  );
  const [licenseKeyMode, setLicenseKeyMode] = useState(
    LicenseKeyModes.ADD as LicenseKeyModes
  );
  const [shareLicenseKeyRequestStatus, setShareLicenseKeyRequestStatus] =
    useState("");
  const isShareLicenseKeyLoading = shareLicenseKeyRequestStatus === LOADING;

  const handleSendLicense = async (record: IManagedAsset) => {
    setShareLicenseKeySelectedID(record?.id);
    setShareLicenseKeyRequestStatus(LOADING);
    const formattedUrl = `${formatURL(
      API_END_POINTS.LOANS.sendLicenseKeyForAnAsset,
      { assetId: record?.id }
    )}?customerId=${details.customerId}`;

    const response = await Requester.post(formattedUrl).catch(
      ({ response }) => response
    );

    if (response?.status === 204) {
      message.success(t("messages.success.shareLicenseKey"));
      setShareLicenseKeyRequestStatus("");
      setShareLicenseKeySelectedID(null);
    } else {
      const errMsg: string =
        formatError(response) || t("messages.error.shareLicenseKey");
      setShareLicenseKeyRequestStatus(errMsg);
      setShareLicenseKeySelectedID(null);
    }
  };

  const columns: ColumnsType<IManagedAsset> = [
    {
      title: t("Product Name"),
      key: "productName",
      dataIndex: "productName",
    },
    {
      title: (
        <>
          {t("Invoice & MAC")}{" "}
          <Tooltip title={t("Map the Invoice and MAC of the device")}>
            <InfoCircleOutlined />
          </Tooltip>
        </>
      ),
      key: "erpId",
      render: (_, record) => {
        const isAddMacEnabled =
          details?.status === ILoansStatus.DEVICE_MAPPING_PENDING &&
          !record?.erpId;
        return (
          <>
            {record?.erpId ? (
              <Space size={40}>
                <Typography.Text>
                  <>{t("Invoice : ")}</>
                  {record?.erpId}
                </Typography.Text>
                <Divider type="vertical" />
                <Typography.Text>
                  <>{t("MAC : ")}</>
                  {record?.mac}
                </Typography.Text>
              </Space>
            ) : (
              <RestrictedComponent
                permission={PERMISSIONS.LOAN.mapToErp}
                withAlert
              >
                <Button
                  onClick={() => {
                    setIsMacKeyFormOpen(true);
                    setSelectedRecord(record);
                  }}
                  type="primary"
                  disabled={!isAddMacEnabled}
                >
                  {t("Add")}
                </Button>
              </RestrictedComponent>
            )}
          </>
        );
      },
    },
    {
      title: t("License Keys"),
      key: "licenseKeys",
      // @ts-ignore
      hidden: props?.actionType === LOAN_PRODUCT_ACTIONS.SEND_LICENSE,
      render: (_, record) => {
        const isAddLicenseKeyEnabled =
          details?.status === ILoansStatus.LICENSE_KEY_PENDING;

        return (
          <Space size={15}>
            {record?.licenseKeys ? (
              <>
                {/* <Tag icon={<CheckCircleFilled />} color='green'>
                  {t('Mapped')}
                </Tag> */}

                <Typography.Link
                  onClick={() => {
                    dispatch(LoansActions.setSelectedAssetId(record.id));
                    setIsLicenseKeysModalOpen(true);
                  }}
                >
                  <EyeOutlined /> {t("View")}
                </Typography.Link>
                <Typography.Link
                  onClick={() => {
                    setIsLicenseKeyFormOpen(true);
                    setSelectedRecord(record);
                    setLicenseKeyMode(LicenseKeyModes.UPDATE);
                  }}
                >
                  <EditOutlined /> {t("Update")}
                </Typography.Link>
              </>
            ) : (
              <RestrictedComponent
                permission={PERMISSIONS.LOAN.addLicenseKeys}
                withAlert
              >
                <Button
                  onClick={() => {
                    setIsLicenseKeyFormOpen(true);
                    setSelectedRecord(record);
                    setLicenseKeyMode(LicenseKeyModes.ADD);
                  }}
                  disabled={!isAddLicenseKeyEnabled}
                  type="primary"
                >
                  {t("Add")}
                </Button>
              </RestrictedComponent>
            )}
          </Space>
        );
      },
    },
    {
      title: t("Actions"),
      key: "actions",
      // @ts-ignore
      hidden: props?.actionType !== LOAN_PRODUCT_ACTIONS.SEND_LICENSE,
      render: (_, record) => {
        return (
          <Button
            type={"primary"}
            icon={
              <FontAwesomeIcon
                icon={faArrowUpRightFromSquare}
                style={{ marginRight: 5 }}
              />
            }
            onClick={() => handleSendLicense(record)}
            loading={
              isShareLicenseKeyLoading &&
              record?.id === shareLicenseKeySelectedID
            }
            disabled={isShareLicenseKeyLoading}
          >
            {t("labels.loans.dashboard.sendLicense")}
          </Button>
        );
      },
    },
  ];

  return (
    <>
      {!isShareLicenseKeyLoading && shareLicenseKeyRequestStatus && (
        <Alert
          style={{ marginBlock: 10 }}
          message={shareLicenseKeyRequestStatus}
          type="error"
          showIcon
          closable
        />
      )}

      {props?.actionType !== LOAN_PRODUCT_ACTIONS.SEND_LICENSE && (
        <LoanDashboardProductDetailsHeading />
      )}
      <HiddenComponent
        hidden={details?.loanType === ILoanType.MORTGAGE}
        fallBack={<LoanDashboardAccessoriesProductDetails />}
      >
        <Table
          bordered
          rowKey={(data) => data?.id}
          // @ts-ignore
          columns={columns.filter((item) => !item.hidden)}
          pagination={false}
          loading={loading}
          dataSource={details?.managedAssets}
        />
      </HiddenComponent>
      <LoanDashboardLicenseKeyForm
        mode={licenseKeyMode}
        record={selectedRecord}
        open={isLicenseKeyFormOpen}
        close={() => {
          setIsLicenseKeyFormOpen(false);
        }}
      />
      <LoanDashboardMacForm
        record={selectedRecord}
        open={isMacKeyFormOpen}
        close={() => {
          setIsMacKeyFormOpen(false);
        }}
      />
      <LoanDashboardLicenseKeyTable
        record={selectedRecord}
        open={isLicenseKeysModalOpen}
        close={() => {
          setIsLicenseKeysModalOpen(false);
        }}
      />
    </>
  );
};
export default LoanDashboardProductDetails;
