import {
  Messaging,
  Unsubscribe,
  getMessaging,
  getToken,
  isSupported,
  onMessage,
} from "firebase/messaging";
import { useEffect, useRef, useState } from "react";

import { RootState } from "store/store";
import { initializeApp } from "firebase/app";
import { notification } from "antd";
import { useSelector } from "react-redux";

export interface FirebaseConfig {
  apiKey: string;
  authDomain: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
  measurementId: string;
  vapidKey: string;
}

const firebaseConfig = {
  apiKey: process.env.FIREBASE_API_KEY,
  appId: process.env.FIREBASE_APP_ID,
  authDomain: process.env.FIREBASE_AUTH_DOMAIN,
  measurementId: process.env.FIREBASE_MEASUREMENT_ID,
  messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
  projectId: process.env.FIREBASE_PROJECT_ID,
  storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
  vapidKey: process.env.FIREBASE_VAPID_KEY,
};

export const useFirebase = () => {
  const { authorized } = useSelector((state: RootState) => state.auth);
  const messaging = useRef({} as Messaging);
  const unSubscribe = useRef(undefined as Unsubscribe | undefined);
  const [token, setToken] = useState("");

  useEffect(() => {
    if (authorized) {
      initializeFirebase();
    } else if (token) {
      unSubscribe.current?.();
    }
  }, [authorized]);

  useEffect(() => {
    if (token && !unSubscribe.current && messaging.current) {
      unSubscribe.current = onMessage(messaging.current, onMessageHandler);
    }
  }, [token]);

  const initializeFirebase = async () => {
    let token = null;
    if (await isSupported()) {
      const firebaseApp = initializeApp(firebaseConfig);
      messaging.current = getMessaging(firebaseApp);
      await getToken(messaging.current, {
        vapidKey: firebaseConfig?.vapidKey,
      })
        .then((currentToken: any) => {
          if (currentToken) {
            console.log("current token for client: ", currentToken);
            token = currentToken;
            setToken(currentToken);
          } else {
            console.log(
              "Empty token returned. Request permission to generate one."
            );
            setToken("");
          }
        })
        .catch((err: any) => {
          console.log("An error occurred while creating client token. ", err);
          // catch error while creating client token
          setToken("");
        });
    } else {
      notification.open({
        message: "Warning!",
        type: "info",
        description:
          "Notifications feature is not yet available for your device.",
      });
    }
    return token;
  };

  const onMessageHandler = (payload: any) => {
    console.log(payload);
    notification.open({
      message: payload?.notification?.title,
      description: payload?.notification?.body,
      duration: 0,
    });
  };

  return { token, initializeFirebase, unSubscribe };
};
