import { Typography } from "antd";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const LoansRequestDashboardCustomerHeader = () => {
  const { t } = useTranslation();
  const headerComponentFontSize = 40;
  const params = useParams();
  return (
    <>
      <div style={{ display: "flex" }}>
        <Typography.Text
          style={{
            fontSize: headerComponentFontSize,
            color: "#104359",
            fontWeight: 600,
          }}
        >
          {t("Order Requests")}{" "}
          <Typography.Text
            style={{
              fontSize: 24,
              lineHeight: 0,
            }}
          >
            {"#" + params?.loanRequestId}
          </Typography.Text>{" "}
        </Typography.Text>
      </div>
    </>
  );
};

export default LoansRequestDashboardCustomerHeader;
