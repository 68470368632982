import "styles/CustomerProfile.less";

import { Button, Divider, Spin, Typography } from "antd";
import {
  CUSTOMER_DOCUMENT_STATUS,
  CUSTOMER_DOCUMENT_STATUS_VIEW,
  CUSTOMER_DOCUMENT_TYPE,
  CUSTOMER_KYC_STATUS,
  CUSTOMER_STATUS,
} from "store/types";
import {
  CheckCircleFilled,
  CloseCircleFilled,
  WarningFilled,
} from "@ant-design/icons";
import RestrictedComponent, {
  PERMISSIONS,
} from "components/util-components/RestrictedComponent";

import CustomerDocVerifier from "./customer.docVerifier";
import CustomerDocViewer from "./customer.docViewer";
import CustomerProfileCibil from "./customer.profile.cibil";
import CustomerProfileErpMapping from "./customer.profile.erpMapping";
import CustomerProfileRejectionMessage from "./customer.profile.rejectionMessage";
import HiddenComponent from "components/util-components/HiddenComponent";
import { NOT_APPLICABLE } from "appConstants";
import { RootState } from "store/store";
import { momentDateTimeFormat } from "components/util-components/dateTimeFormattor";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const CustomerProfileVerification = () => {
  const { t } = useTranslation();
  const { customerDetails, customerDocumnetDetails, loading } = useSelector(
    (state: RootState) => state.customers.customerProfile
  );
  const [docVerifierDetails, setDocVerifierDetails] = useState({
    visible: false,
    docType: CUSTOMER_DOCUMENT_TYPE.AADHAR_CARD,
  });

  const isKycPending = [
    CUSTOMER_KYC_STATUS.SUBMITTED,
    CUSTOMER_KYC_STATUS.RESUBMITTED,
    CUSTOMER_KYC_STATUS.REJECTED,
  ].includes(customerDetails?.kyc?.status);

  const getDocumentStatusIcon = (status: CUSTOMER_DOCUMENT_STATUS) => {
    switch (status) {
      case CUSTOMER_DOCUMENT_STATUS.PENDING:
        return <CloseCircleFilled style={{ color: "red" }} />;
      case CUSTOMER_DOCUMENT_STATUS.REJECTED:
        return <CloseCircleFilled style={{ color: "red" }} />;
      case CUSTOMER_DOCUMENT_STATUS.RESUBMITTED:
        return <WarningFilled style={{ color: "orange" }} />;
      case CUSTOMER_DOCUMENT_STATUS.SUBMITTED:
        return <WarningFilled style={{ color: "orange" }} />;
      case CUSTOMER_DOCUMENT_STATUS.VERIFIED:
        return <CheckCircleFilled style={{ color: "green" }} />;
      default:
        break;
    }
  };

  return (
    <Spin spinning={loading}>
      <HiddenComponent
        hidden={customerDetails?.status !== CUSTOMER_STATUS.REJECTED}
      >
        <CustomerProfileRejectionMessage />
        <br />
      </HiddenComponent>
      <section>
        <Typography.Title level={3} style={{ marginBottom: 0 }}>
          {t("KYC Details")}{" "}
          <WarningFilled hidden={!isKycPending} style={{ color: "orange" }} />
        </Typography.Title>
        {isKycPending ? (
          <Typography.Text style={{ color: "#888", fontSize: 20 }}>
            {t("Approve the KYC documents")}
          </Typography.Text>
        ) : (
          <>
            <Typography.Text style={{ color: "#888" }}>
              {t("Last Verified")}
            </Typography.Text>{" "}
            <Typography.Text>
              {momentDateTimeFormat(
                customerDetails?.kyc?.verificationDate,
                "date"
              ) || NOT_APPLICABLE}
            </Typography.Text>
          </>
        )}
        <br />
        <br />
        <section className="customer-profile-verification-kycDocuments">
          <div>
            <Typography.Title level={4} style={{ marginBottom: 0 }}>
              {t("Aadhar Card")}
            </Typography.Title>
            <Typography.Text style={{ color: "#888" }}>
              <>
                {
                  CUSTOMER_DOCUMENT_STATUS_VIEW[
                    customerDocumnetDetails?.AADHAR_CARD?.status
                  ]
                }{" "}
                {getDocumentStatusIcon(
                  customerDocumnetDetails?.AADHAR_CARD?.status
                )}
              </>
            </Typography.Text>
            <br />
            <br />
            <CustomerDocViewer
              docType={CUSTOMER_DOCUMENT_TYPE.AADHAR_CARD}
              frontPage={customerDocumnetDetails?.AADHAR_CARD?.frontPageLink}
              backPage={customerDocumnetDetails?.AADHAR_CARD?.backPageLink}
            />
            <br />
            <RestrictedComponent permission={PERMISSIONS.CUSTOMERS.verifier}>
              <Button
                style={{
                  width: 120,
                  borderTopLeftRadius: 0,
                  borderTopRightRadius: 0,
                }}
                onClick={() => {
                  setDocVerifierDetails({
                    visible: true,
                    docType: CUSTOMER_DOCUMENT_TYPE.AADHAR_CARD,
                  });
                }}
                hidden={[
                  CUSTOMER_DOCUMENT_STATUS.VERIFIED,
                  CUSTOMER_DOCUMENT_STATUS.PENDING,
                ].includes(customerDocumnetDetails?.AADHAR_CARD?.status)}
                type="primary"
                disabled={[CUSTOMER_STATUS.REJECTED].includes(
                  customerDetails?.status
                )}
              >
                {t("VERIFY")}
              </Button>
            </RestrictedComponent>
          </div>
          <div>
            <Typography.Title level={4} style={{ marginBottom: 0 }}>
              {t("Pan Card")}
            </Typography.Title>
            <Typography.Text style={{ color: "#888" }}>
              <>
                {
                  CUSTOMER_DOCUMENT_STATUS_VIEW[
                    customerDocumnetDetails?.PAN_CARD?.status
                  ]
                }{" "}
                {getDocumentStatusIcon(
                  customerDocumnetDetails?.PAN_CARD?.status
                )}
              </>
            </Typography.Text>
            <br />
            <br />
            <CustomerDocViewer
              docType={CUSTOMER_DOCUMENT_TYPE.PAN_CARD}
              frontPage={customerDocumnetDetails?.PAN_CARD?.frontPageLink}
              backPage={customerDocumnetDetails?.PAN_CARD?.backPageLink}
            />

            <br />
            <RestrictedComponent permission={PERMISSIONS.CUSTOMERS.verifier}>
              <Button
                style={{
                  width: 120,
                  borderTopLeftRadius: 0,
                  borderTopRightRadius: 0,
                }}
                onClick={() => {
                  setDocVerifierDetails({
                    visible: true,
                    docType: CUSTOMER_DOCUMENT_TYPE.PAN_CARD,
                  });
                }}
                hidden={[
                  CUSTOMER_DOCUMENT_STATUS.VERIFIED,
                  CUSTOMER_DOCUMENT_STATUS.PENDING,
                ].includes(customerDocumnetDetails?.PAN_CARD?.status)}
                type="primary"
                disabled={[CUSTOMER_STATUS.REJECTED].includes(
                  customerDetails?.status
                )}
              >
                {t("VERIFY")}
              </Button>
            </RestrictedComponent>
          </div>
          <div>
            <Typography.Title level={4} style={{ marginBottom: 0 }}>
              {t("Bank Statement")}
            </Typography.Title>
            <Typography.Text style={{ color: "#888" }}>
              <>
                {
                  CUSTOMER_DOCUMENT_STATUS_VIEW[
                    customerDocumnetDetails?.BANK_STATEMENT?.status
                  ]
                }{" "}
                {getDocumentStatusIcon(
                  customerDocumnetDetails?.BANK_STATEMENT?.status
                )}
              </>
            </Typography.Text>
            <br />
            <br />
            <CustomerDocViewer
              docType={CUSTOMER_DOCUMENT_TYPE.BANK_STATEMENT}
              frontPage={customerDocumnetDetails?.BANK_STATEMENT?.frontPageLink}
              backPage={customerDocumnetDetails?.BANK_STATEMENT?.backPageLink}
            />
            <br />
            <RestrictedComponent permission={PERMISSIONS.CUSTOMERS.verifier}>
              <Button
                style={{
                  width: 120,
                  borderTopLeftRadius: 0,
                  borderTopRightRadius: 0,
                }}
                onClick={() => {
                  setDocVerifierDetails({
                    visible: true,
                    docType: CUSTOMER_DOCUMENT_TYPE.BANK_STATEMENT,
                  });
                }}
                hidden={[
                  CUSTOMER_DOCUMENT_STATUS.VERIFIED,
                  CUSTOMER_DOCUMENT_STATUS.PENDING,
                ].includes(customerDocumnetDetails?.BANK_STATEMENT?.status)}
                type="primary"
                disabled={[CUSTOMER_STATUS.REJECTED].includes(
                  customerDetails?.status
                )}
              >
                {t("VERIFY")}
              </Button>
            </RestrictedComponent>
          </div>
        </section>
        <Divider dashed />
        <CustomerProfileCibil />
        <Divider dashed />
        <CustomerProfileErpMapping />
        <CustomerDocVerifier
          visible={docVerifierDetails?.visible}
          docType={docVerifierDetails?.docType}
          onClose={() => {
            setDocVerifierDetails({ ...docVerifierDetails, visible: false });
          }}
        />
      </section>
    </Spin>
  );
};

export default CustomerProfileVerification;
