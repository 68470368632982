import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactNode } from "react";
import { Typography } from "antd";
import { faIndianRupeeSign } from "@fortawesome/free-solid-svg-icons";

export const IndianRuppeeView = (props: {
  value: string;
  fontSize?: number;
  fontColor?: string;
  strong?: boolean;
  fontWeight?: number;
}) => {
  return (
    <Typography.Text
      style={{
        fontSize: props?.fontSize,
        color: props?.fontColor,
        fontWeight: props?.fontWeight,
      }}
      strong={props?.strong}
    >
      <FontAwesomeIcon icon={faIndianRupeeSign} /> {""}
      {IndianRuppeNumberFormattor(props?.value)}
    </Typography.Text>
  );
};

export const IndianRuppeNumberFormattor = (
  value: string | undefined,
  info?: { userTyping: boolean; input: string }
) => {
  return new Intl.NumberFormat("en-IN", {
    maximumFractionDigits: 2,
    currency: "INR",
  }).format(parseFloat(value?.toString() || "0"));
};

export const DetailDescriptionItem = (props: {
  label: string | ReactNode;
  children: ReactNode;
}) => {
  return (
    <>
      <Typography.Text>{props?.label}</Typography.Text>
      <br />
      <Typography.Title level={4}>{props?.children}</Typography.Title>
    </>
  );
};
