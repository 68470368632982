import { Button, Table } from "antd";

import { ColumnsType } from "antd/es/table";
import ErrorRenderer from "components/util-components/ErrorRenderer";
import HiddenComponent from "components/util-components/HiddenComponent";
import { ICustomer } from "store/types";
import { RootState } from "store/store";
import { useCustomerListPagination } from "./useCustomerPagination";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const CustomersListRejected = () => {
  const { t } = useTranslation();
  const { customers } = useSelector((state: RootState) => state);
  const navigate = useNavigate();
  const { pagination } = useCustomerListPagination();

  const columns: ColumnsType<ICustomer> = [
    {
      title: t("labels.customers.name"),
      dataIndex: "firstName",
      key: "firstName",
    },
    {
      title: t("labels.customers.phoneNumber"),
      dataIndex: "phoneNumber",
      key: "customerName",
    },
    {
      title: t("labels.customers.email"),
      dataIndex: "email",
      key: "email",
    },
    {
      title: t("Action"),
      key: "email",
      render: (value, record) => {
        return (
          <Button
            type="link"
            onClick={() => {
              navigate(`/customers/${record?.id}/profile`);
            }}
          >
            {t("View")}
          </Button>
        );
      },
    },
  ];

  return (
    <HiddenComponent
      hidden={customers?.customerList?.error}
      fallBack={<ErrorRenderer status={customers?.customerList?.errorStatus} />}
    >
      <Table
        bordered
        sticky={true}
        rowKey={(data) => data?.id}
        loading={customers.customerList?.loading}
        size={"small"}
        dataSource={customers?.customerList?.customersListRejected}
        columns={columns}
        scroll={{
          x: "calc(100vw - 150px)",
          scrollToFirstRowOnChange: true,
        }}
        pagination={pagination}
      />
    </HiddenComponent>
  );
};

export default CustomersListRejected;
