import { ColumnsType } from "antd/es/table";
import { IProductItem } from "store/types";
import { IndianRuppeeView } from "components/products/product.util.components";
import { RootState } from "store/store";
import { Table } from "antd";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const LoanDashboardAccessoriesProductDetails = () => {
  const { t } = useTranslation();
  const { details, loading } = useSelector(
    (state: RootState) => state.loans.loanDashboard
  );

  const columns: ColumnsType<IProductItem> = [
    {
      title: t("Product Name"),
      key: "productName",
      dataIndex: "name",
    },
    {
      title: t("Price"),
      key: "amount",
      render: (_, record) => {
        return <IndianRuppeeView value={record?.price?.toString()} />;
      },
    },
    {
      title: t("Processing Fees"),
      key: "processingFee",
      render: (_, record) => {
        return <IndianRuppeeView value={record?.processingFee?.toString()} />;
      },
    },
    {
      title: t("Quantity"),
      key: "quantity",
      dataIndex: "count",
    },
  ];
  return (
    <>
      <Table
        loading={loading}
        bordered
        columns={columns}
        dataSource={details?.purchasedItems}
        pagination={false}
      />
    </>
  );
};

export default LoanDashboardAccessoriesProductDetails;
