import { Space, Typography } from "antd";
import Table, { ColumnsType } from "antd/es/table";

import ErrorRenderer from "components/util-components/ErrorRenderer";
import HiddenComponent from "components/util-components/HiddenComponent";
import { ILoan } from "store/types";
import { IndianRuppeeView } from "components/products/product.util.components";
import { LoansListActions } from "store/slices/loansListSlice";
import { RootState } from "store/store";
import { ShowProductsInTable } from "./loans.util-components";
import { useAppDispatch } from "store/hooks";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const LoansListClosed = () => {
  const { loansList } = useSelector((state: RootState) => state);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { details, loading, error, errorStatus } = loansList.closedLoansTable;

  const columns: ColumnsType<ILoan> = [
    {
      title: t("Order Id"),
      key: "loanId",
      render: (_, record) => {
        return <Typography.Text copyable>{record?.id}</Typography.Text>;
      },
    },
    {
      title: t("Customer Name"),
      key: "customerName",
      render: (_, record) => {
        return (
          <Typography.Link
            onClick={() => {
              navigate(`/customers/${record?.customerId}/dashboard`);
            }}
            strong
          >
            {record?.customerDetails?.firstName +
              " " +
              record?.customerDetails?.lastName}
          </Typography.Link>
        );
      },
    },
    {
      title: t("Products"),
      key: "products",
      render: (_, record) => {
        return <ShowProductsInTable productList={record?.purchasedItems} />;
      },
    },
    {
      title: t("Amount"),
      key: "amount",
      render: (_, record) => {
        return (
          <>
            <IndianRuppeeView value={record?.loanAmount?.toString()} />
          </>
        );
      },
    },
    // {
    //   title: t("Closed On"),
    //   key: "amount",
    //   render: (_, record) => {
    //     return <>{momentDateTimeFormat(record?.applyTime)}</>;
    //   },
    // },
    // {
    //   title: t("Closed By"),
    //   key: "amount",
    //   render: (_, record) => {
    //     return <>{record?.applyTime}</>;
    //   },
    // },
    {
      title: t("Actions"),
      key: "actions",
      render: (_, record) => {
        return (
          <>
            <Typography.Link
              onClick={() => {
                navigate(`/orders/details/${record?.id}`);
              }}
            >
              {t("View")}
            </Typography.Link>
          </>
        );
      },
    },
  ];
  return (
    <HiddenComponent
      hidden={error}
      fallBack={<ErrorRenderer status={errorStatus} />}
    >
      <Table
        bordered
        sticky={true}
        rowKey={(data) => data?.id}
        loading={loading}
        size={"small"}
        dataSource={details?.items}
        columns={columns}
        scroll={{
          x: "calc(100vw - 150px)",
          scrollToFirstRowOnChange: true,
        }}
        pagination={{
          showSizeChanger: true,
          pageSize: loansList?.loansPagination?.pageSize,
          current: loansList?.loansPagination?.pageToken,
          total: details?.total,
          onChange: (pageNumber, pageSize) => {
            if (loansList?.loansPagination?.pageSize !== pageSize) {
              dispatch(
                LoansListActions.setLoansPagination({
                  pageSize: pageSize,
                  pageToken: 1,
                })
              );
            } else {
              dispatch(LoansListActions.setLoansPageToken(pageNumber));
            }
          },
          showTotal: () => {
            return (
              <Space>
                <Typography.Text>{`${t(
                  "labels.loans.totalCount"
                )}:`}</Typography.Text>
                <Typography.Text>{details?.pageSize || 0}</Typography.Text>
              </Space>
            );
          },
        }}
      />
    </HiddenComponent>
  );
};
export default LoansListClosed;
