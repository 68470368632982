import "styles/LoanDashboard.less";

import { Progress, Spin, Tooltip, Typography } from "antd";

import { EmiStatus } from "store/types";
import HiddenComponent from "components/util-components/HiddenComponent";
import { IndianRuppeeView } from "components/products/product.util.components";
import { ReactNode } from "react";
import { RootState } from "store/store";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { InfoCircleOutlined } from "@ant-design/icons";

const LoanDashboardCards = () => {
  const { t } = useTranslation();
  const { details } = useSelector(
    (state: RootState) => state?.loans?.loanDashboard
  );
  const emisPaid =
    details?.emis?.filter((emi) => {
      return emi?.status === EmiStatus.PAID;
    })?.length || 0;
  const totalEmis = details?.tenure;
  const percentage = parseFloat(((emisPaid / totalEmis) * 100).toFixed(0));
  const downPayment = details?.optedForZeroDownPayment
    ? 0
    : details?.downPaymentDetails?.amount;

  return (
    <div className="loan-dashboard-cards-wrapper">
      <DetailCard
        title={t("Order Amount")}
        tooltip="Order amount includes processing fee"
      >
        <IndianRuppeeView
          fontSize={28}
          fontColor={"#104359"}
          fontWeight={500}
          value={details?.loanAmount?.toString()}
        />
      </DetailCard>
      <DetailCard title={t("EMI")}>
        <IndianRuppeeView
          fontSize={28}
          fontColor={"#104359"}
          fontWeight={500}
          value={details?.monthlyEmi?.toString()}
        />
      </DetailCard>
      <DetailCard title={t("Down Payment")}>
        <IndianRuppeeView
          fontSize={28}
          fontColor={"#104359"}
          fontWeight={500}
          value={downPayment?.toString()}
        />
      </DetailCard>

      <DetailCard title="Outstanding Amount">
        <IndianRuppeeView
          fontSize={28}
          fontColor={"#104359"}
          fontWeight={500}
          value={details?.outstandingAmount?.toString()}
        />
      </DetailCard>
      <DetailCard title="EMI Paid" extraSpace>
        <div className="loan-dashboard-cards-emiPaid-container">
          <Progress percent={percentage} />
          <Typography.Text
            style={{ fontSize: 20 }}
          >{`${emisPaid}/${totalEmis}`}</Typography.Text>
        </div>
      </DetailCard>
    </div>
  );
};
export default LoanDashboardCards;

const DetailCard = (props: {
  children?: ReactNode;
  title?: string;
  extraSpace?: boolean;
  tooltip?: string;
}) => {
  const { loading } = useSelector(
    (state: RootState) => state?.loans?.loanDashboard
  );
  return (
    <div
      className={
        props?.extraSpace
          ? "loan-dashboard-detail-card extraSpace"
          : "loan-dashboard-detail-card"
      }
    >
      <HiddenComponent hidden={loading} fallBack={<Spin />}>
        <div>
          <Typography.Text className="loan-dashboard-detail-card-title">
            {props?.title}
            {props?.tooltip && (
              <Tooltip title={props.tooltip} style={{ marginLeft: "5px" }}>
                <InfoCircleOutlined />
              </Tooltip>
            )}
          </Typography.Text>
        </div>
        <div className="loan-dashboard-detail-card-content-container">
          {props?.children}
        </div>
      </HiddenComponent>
    </div>
  );
};
