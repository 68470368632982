import { ReactNode } from "react";

const HiddenComponent = (props: {
  hidden: boolean;
  children: ReactNode;
  fallBack?: ReactNode;
}) => {
  return props?.hidden ? <>{props?.fallBack || ""}</> : <>{props?.children}</>;
};

export default HiddenComponent;
