import {
  Alert,
  Button,
  Card,
  Divider,
  Form,
  Image,
  Input,
  Modal,
  Radio,
  Row,
  Typography,
  message,
} from "antd";
import {
  CUSTOMER_DOCUMENT_STATUS,
  CUSTOMER_DOCUMENT_TYPE,
  CUSTOMER_DOCUMENT_TYPE_VIEW,
} from "store/types";
import { Requester, formatURL } from "api/requester";

import { API_END_POINTS } from "api/urls";
import { CustomersActions } from "store/slices/customersSlice";
import Meta from "antd/es/card/Meta";
import { RootState } from "store/store";
import { useAppDispatch } from "store/hooks";
import { useForm } from "antd/es/form/Form";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { wrapWithAuthToken } from "components/util-components/utilFunctions";

interface ICustomerDocVerifierProps {
  visible: boolean;
  docType: CUSTOMER_DOCUMENT_TYPE;
  onClose: () => void;
}
const CustomerDocVerifier = (props: ICustomerDocVerifierProps) => {
  const { t } = useTranslation();
  const { customerDetails, customerDocumnetDetails } = useSelector(
    (state: RootState) => state?.customers?.customerProfile
  );
  const documentDetails = customerDocumnetDetails[props?.docType];
  const docExtension = documentDetails?.frontPageLink?.split(".")?.at(-1) || "";
  const [form] = useForm();
  const acceptanceStatusWatch = Form.useWatch("acceptanceStatus", form);
  const isDocVerified =
    acceptanceStatusWatch === CUSTOMER_DOCUMENT_STATUS.VERIFIED;
  const [alertDescription, setAlertDescription] = useState("");
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const dispatch = useAppDispatch();

  const handleClose = () => {
    props?.onClose();
    form.resetFields();
    setAlertDescription("");
  };

  const handleFormSubmit = async (values: any) => {
    setIsSubmitLoading(true);
    const url =
      values?.acceptanceStatus === CUSTOMER_DOCUMENT_STATUS.VERIFIED
        ? API_END_POINTS.CUSTOMERS.approveDocumnets
        : API_END_POINTS.CUSTOMERS.rejectDocumnets;
    const formattedUrl = formatURL(url, { customerId: customerDetails?.id });
    const response = await Requester.post(formattedUrl, {
      documentType: props?.docType,
      remark: values?.remark,
    }).catch(({ response }) => response);
    if (response?.status === 204) {
      handleClose();
      message.success(t("Successfully Updated"));
      dispatch(CustomersActions.fetchCustomerById(customerDetails?.id));
    } else {
      const error = t("messages.error.generic");
      setAlertDescription(error);
    }
    setIsSubmitLoading(false);
  };
  return (
    <>
      <Modal
        open={props?.visible}
        onCancel={handleClose}
        footer={
          <>
            <Button
              loading={isSubmitLoading}
              type="primary"
              onClick={form.submit}
            >
              {t("Submit")}
            </Button>
          </>
        }
        maskClosable={false}
        title={t("Verify") + " " + CUSTOMER_DOCUMENT_TYPE_VIEW[props?.docType]}
        width={1200}
      >
        {(alertDescription && (
          <>
            <Alert type="error" description={alertDescription} />
            <br />
          </>
        )) || <></>}
        {docExtension === "pdf" ? (
          <>
            <iframe
              title={t("Front Page")}
              width={1000}
              height={600}
              src={wrapWithAuthToken(documentDetails?.frontPageLink)}
            />
            {(documentDetails?.backPageLink && (
              <iframe
                title={t("Back Page")}
                width={1000}
                height={600}
                src={wrapWithAuthToken(documentDetails?.backPageLink)}
              />
            )) || <></>}
          </>
        ) : (
          <>
            <FrontNBackDocumentView
              frontPageLink={documentDetails?.frontPageLink}
              backPageLink={documentDetails?.backPageLink}
            />
          </>
        )}
        <Divider />
        <Form form={form} onFinish={handleFormSubmit}>
          <Form.Item
            name={"acceptanceStatus"}
            rules={[{ required: true, message: "Please select an option" }]}
          >
            <Radio.Group optionType="default">
              <Radio.Button
                className="verifiedRadio"
                value={CUSTOMER_DOCUMENT_STATUS.VERIFIED}
              >
                {t("Verify")}
              </Radio.Button>
              <Radio.Button
                className="rejectedRadio"
                value={CUSTOMER_DOCUMENT_STATUS.REJECTED}
              >
                {t("Reject")}
              </Radio.Button>
            </Radio.Group>
          </Form.Item>
          {acceptanceStatusWatch ? (
            <Form.Item
              name={"remark"}
              label={isDocVerified ? "Remarks" : "Reason for rejection"}
              required={!isDocVerified}
              rules={[
                {
                  required: !isDocVerified,
                  message: "Please enter reason for rejection",
                },
              ]}
            >
              <Input
                placeholder={
                  isDocVerified ? "Enter remarks" : "Enter reason for rejection"
                }
              />
            </Form.Item>
          ) : (
            <></>
          )}
        </Form>
      </Modal>
    </>
  );
};

export default CustomerDocVerifier;

const FrontNBackDocumentView = (props: {
  frontPageLink?: string;
  backPageLink?: string;
}) => {
  return (
    <Row justify={"space-between"}>
      <Card
        cover={
          <Image
            onContextMenu={(e) => {
              e.preventDefault();
            }}
            preview={!!props?.frontPageLink}
            style={{ objectFit: "cover" }}
            width={500}
            height={250}
            src={wrapWithAuthToken(props?.frontPageLink)}
          />
        }
      >
        <Meta title="Front" />
      </Card>
      {props?.backPageLink ? (
        <Card
          cover={
            <Image
              onContextMenu={(e) => {
                e.preventDefault();
              }}
              preview={!!props?.backPageLink}
              style={{ objectFit: "cover" }}
              width={500}
              height={250}
              src={wrapWithAuthToken(props?.backPageLink)}
              placeholder={
                <div className="centerDiv height-100P">
                  <Typography.Text>Not Avaliable</Typography.Text>
                </div>
              }
            />
          }
        >
          <Meta title="Back" />
        </Card>
      ) : (
        <></>
      )}
    </Row>
  );
};
