import { Button, Form, Input, InputNumber, Modal, Upload, message } from "antd";
import { Requester, formatURL } from "api/requester";

import { API_END_POINTS } from "api/urls";
import { CustomersActions } from "store/slices/customersSlice";
import { RootState } from "store/store";
import { UploadOutlined } from "@ant-design/icons";
import { uploadFilesToS3 } from "components/util-components/utilFunctions";
import { useAppDispatch } from "store/hooks";
import { useForm } from "antd/es/form/Form";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const CustomerProfileCibilUploadForm = (props: {
  open: boolean;
  close: () => void;
}) => {
  const { t } = useTranslation();
  const [form] = useForm();
  const [isFormUploading, setIsFormUploading] = useState(false);
  const [isImageTypeCorrect, setIsImageTypeCorrect] = useState(false);
  const { customerDetails } = useSelector(
    (state: RootState) => state?.customers?.customerProfile
  );
  const dispatch = useAppDispatch();

  const handleModalClose = () => {
    props?.close();
    form.resetFields();
  };

  const handleSubmit = async (formValues: any) => {
    setIsFormUploading(true);
    const url = formatURL(API_END_POINTS.CUSTOMERS.creditScore, {
      customerId: customerDetails?.id,
    });

    uploadFilesToS3(
      formValues?.credit_score_doc?.fileList,
      () => {
        setIsFormUploading(false);
      },
      async (links) => {
        const data = {
          ...formValues,
          bureau: "CIBIL",
          documentLink: links?.at(0), //only 1 document is uploaded
        };
        const response = await Requester.post(url, data).catch(
          ({ response }) => response
        );
        if (response?.status === 204) {
          props?.close();
          message.success(t("Successfully Updated"));
          setIsFormUploading(false);
          dispatch(CustomersActions.fetchCustomerById(customerDetails?.id));
        } else {
          setIsFormUploading(false);
          message.error(t("Upload failed"));
        }
      }
    );
  };

  return (
    <Modal
      title={t("Credit Score Details")}
      onCancel={handleModalClose}
      open={props?.open}
      maskClosable={false}
      footer={[
        <Button type="primary" onClick={form.submit} loading={isFormUploading}>
          {t("Submit")}
        </Button>,
      ]}
    >
      <br />
      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        <Form.Item
          name={"score"}
          label={t("Cibil score")}
          rules={[
            {
              required: true,
              message: t("Cibil score cannot be empty"),
            },
            {
              validator(_, value) {
                if (value > 900) {
                  return Promise.reject(t("Cibil cannot be greater than 900"));
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <InputNumber
            style={{ width: "100%" }}
            controls={false}
            placeholder={t("Enter Cibil score")}
          />
        </Form.Item>
        <Form.Item
          name={"remark"}
          label={t("Remarks")}
          rules={[
            {
              required: true,
              message: t("Remarks cannot be empty"),
            },
          ]}
        >
          <Input placeholder={t("Enter remarks")} />
        </Form.Item>
        <Form.Item
          label={t("Upload supporting document")}
          name="credit_score_doc"
          rules={[
            {
              required: true,
              message: t("Please upload supporting document"),
            },
            {
              validator() {
                if (!isImageTypeCorrect) {
                  return Promise.reject(t("File type not supported"));
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <Upload
            beforeUpload={(file) => {
              const isProperFile = [
                "image/png",
                "image/jpeg",
                "image/jpg",
                "application/pdf",
              ].includes(file.type);
              if (!isProperFile) {
                setIsImageTypeCorrect(false);
              } else {
                setIsImageTypeCorrect(true);
              }
              return false;
            }}
            maxCount={1}
          >
            <Button
              style={{ width: 100 }}
              icon={<UploadOutlined />}
              type="primary"
            >
              {t("Upload")}
            </Button>
          </Upload>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CustomerProfileCibilUploadForm;
