import { Col, Drawer, Row, Typography } from "antd";
import { useNavigate, useParams } from "react-router-dom";

import { DetailDescriptionItem } from "components/products/product.util.components";
import ErrorRenderer from "components/util-components/ErrorRenderer";
import HiddenComponent from "components/util-components/HiddenComponent";
import Loader from "components/util-components/Loader";
import { momentDateTimeFormat } from "components/util-components/dateTimeFormattor";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useAppDispatch } from "store/hooks";
import { UsersActions } from "store/slices/usersSlice";
import { RootState } from "store/store";
import { ROLES, ROLES_VIEW } from "store/types";
import { getHomeByRole } from "store/slices/authSlice";

const UserDetails = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const params = useParams();
  const { details, loading, error, errorStatus } = useSelector(
    (state: RootState) => state?.users?.userById
  );
  const { auth } = useSelector((state: RootState) => state);

  useEffect(() => {
    dispatch(UsersActions.fetchUserById(params?.userId || ""));
  }, []);

  return (
    <Drawer
      width={800}
      open={true}
      onClose={() => {
        if (auth.userInfo.distributorId) {
          navigate(`/distributors/${auth.userInfo.distributorId}/dashboard`);
        } else if (auth.userInfo.role !== ROLES.ADMIN) {
          navigate(getHomeByRole(auth.userInfo));
        } else {
          navigate(`/users`);
        }
      }}
      closable={!loading}
      maskClosable={loading}
      title={
        <HiddenComponent hidden={loading} fallBack={<Loader />}>
          <div>
            <span>{`${details.firstName}'s Details`}</span>
            <Typography.Link
              underline
              onClick={() => {
                if (auth.userInfo.role !== ROLES.ADMIN) {
                  navigate(`/user-profile/${details.id}/edit`);
                } else {
                  navigate(`/users/${details.id}/edit`);
                }
              }}
              style={{ float: "right" }}
            >
              {t("Edit")}
            </Typography.Link>
          </div>
        </HiddenComponent>
      }
    >
      <HiddenComponent hidden={loading} fallBack={<Loader />}>
        <HiddenComponent
          hidden={error}
          fallBack={<ErrorRenderer status={errorStatus} />}
        >
          <Row>
            <Col span={12}>
              <DetailDescriptionItem label={t("Name")}>
                {details?.firstName + " " + details?.lastName}
              </DetailDescriptionItem>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <DetailDescriptionItem label={t("Role")}>
                {ROLES_VIEW[details?.role]}
              </DetailDescriptionItem>
            </Col>
            <HiddenComponent
              hidden={auth.userInfo.distributorId ? true : false}
            >
              <Col span={12}>
                <DetailDescriptionItem label={t("Branch")}>
                  {details?.branchId}
                </DetailDescriptionItem>
              </Col>
            </HiddenComponent>
          </Row>
          <Row>
            <Col span={12}>
              <DetailDescriptionItem label={t("Email")}>
                {details?.emailId || "-"}
              </DetailDescriptionItem>
            </Col>
            <Col span={12}>
              <DetailDescriptionItem label={t("Phone number")}>
                {details?.phoneNumber || "-"}
              </DetailDescriptionItem>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <DetailDescriptionItem label={t("Created by")}>
                {details?.createdBy}
              </DetailDescriptionItem>
            </Col>
            <Col span={12}>
              <DetailDescriptionItem label={t("Created on")}>
                {momentDateTimeFormat(details?.createTime)}
              </DetailDescriptionItem>
            </Col>
          </Row>
        </HiddenComponent>
      </HiddenComponent>
    </Drawer>
  );
};

export default UserDetails;
