import { Table, Tag, Typography } from "antd";

import { ColumnsType } from "antd/es/table";
import { NOT_APPLICABLE } from "appConstants";
import ErrorRenderer from "components/util-components/ErrorRenderer";
import HiddenComponent from "components/util-components/HiddenComponent";
import { momentDateTimeFormat } from "components/util-components/dateTimeFormattor";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import {
  CUSTOMER_STATUS_DISPLAY_TEXT,
  ICustomer,
} from "store/types";

const DistributorCustomersList = () => {
  const { t } = useTranslation();

  const { distributors } = useSelector((state: RootState) => state);

  const columns: ColumnsType<ICustomer> = [
    {
      title: t("labels.customers.name"),
      key: "firstName",
      render: (_, record) => {
        return (
          <>
            <Typography.Text>
              {record?.firstName + " " + record?.lastName}
              {/* {record.vip && (
                <Tag style={{ marginLeft: "5px" }} color="gold">
                  VIP
                </Tag>
              )} */}
            </Typography.Text>
            <br />
            <Typography.Text style={{ color: "green" }}>
              {CUSTOMER_STATUS_DISPLAY_TEXT[record.status]}
            </Typography.Text>
          </>
        );
      },
    },
    {
      title: t("labels.customers.email"),
      dataIndex: "email",
      key: "email",
    },
    {
      title: t("Network name"),
      key: "networkName",
      render: (_, record) => {
        return <>{record?.networkName || NOT_APPLICABLE}</>;
      },
    },
    {
      title: t("Customer since"),
      key: "customerSince",
      render: (_, record) => {
        return <>{momentDateTimeFormat(record?.createTime, "date")}</>;
      },
    },
    {
      title: t("Orders"),
      key: "loans",
      render: (_, record) => {
        return (
          <>
            <Tag color={"green"}>{`Active : ${
              record?.loanSummary?.activeLoanCount || 0
            }`}</Tag>
            <Tag color={"orange"}>{`Pending :  ${
              record?.loanSummary?.pendingLoanCount || 0
            }`}</Tag>
          </>
        );
      },
    },
  ];

  return (
    <HiddenComponent
      hidden={distributors?.distributorCustomers.error}
      fallBack={
        <ErrorRenderer
          status={distributors?.distributorCustomers?.errorStatus}
        />
      }
    >
      <Table
        bordered
        sticky={true}
        rowKey={(data) => data?.id}
        size={"small"}
        loading={distributors?.distributorCustomers?.loading}
        dataSource={distributors?.distributorCustomers?.details.items}
        columns={columns}
        scroll={{
          x: "calc(100vw - 150px)",
          scrollToFirstRowOnChange: true,
        }}
      />
    </HiddenComponent>
  );
};
export default DistributorCustomersList;
