import { Form, Input, Modal, message } from "antd";
import { Requester, formatURL } from "api/requester";

import { API_END_POINTS } from "api/urls";
import { IManagedAsset } from "store/types";
import { LoansActions } from "store/slices/loansSlice";
import { useAppDispatch } from "store/hooks";
import { useForm } from "antd/es/form/Form";
import { useState } from "react";
import { useTranslation } from "react-i18next";

interface ILoanDashboardMacForm {
  open: boolean;
  close: () => void;
  record: IManagedAsset;
}
const LoanDashboardMacForm = (props: ILoanDashboardMacForm) => {
  const { t } = useTranslation();
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [form] = useForm();
  const dispatch = useAppDispatch();

  const handleFormSubmit = async (values: any) => {
    const data = {
      ...values,
      deviceId: props?.record?.id,
    };
    setIsSubmitLoading(true);
    const url = formatURL(API_END_POINTS.LOANS.deviceDetails, {
      loanId: props?.record?.loanId,
    });
    const response = await Requester.post(url, data).catch(
      ({ reponse }) => reponse
    );
    if (response?.status === 204) {
      props?.close();
      form.resetFields();
      message.success(t("Device details updated successfully"));
      dispatch(LoansActions.fetchLoanById(props?.record?.loanId || ""));
    } else {
      message.error(t("Error in updating device details"));
    }
    setIsSubmitLoading(false);
  };
  return (
    <>
      <Modal
        open={props?.open}
        maskClosable={false}
        onCancel={props?.close}
        title={t("Device Details")}
        okText={t("Update")}
        onOk={form.submit}
        confirmLoading={isSubmitLoading}
      >
        <Form onFinish={handleFormSubmit} form={form} layout="vertical">
          <Form.Item
            name={"invoiceId"}
            rules={[
              {
                required: true,
                message: t("Please enter Invoice Id"),
              },
            ]}
            label={t("Invoice Id")}
          >
            <Input placeholder={t("Enter Invoice Id")} />
          </Form.Item>
          <Form.Item name={"mac"} label={t("MAC Address")}>
            <Input placeholder={t("Enter MAC address")} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default LoanDashboardMacForm;
