import "styles/DistributorDashboard.less";

import { Typography } from "antd";
import { useParams } from "react-router-dom";

import {
  faMoneyBill,
  faMoneyBillTransfer,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "store/hooks";
import { DistributorsActions } from "store/slices/distributorsSlice";
import { RootState } from "store/store";
import DistributorCustomersList from "components/distributors/distributor.customers.list";
import DistributorDashboardCard from "components/distributors/distributor.dashboard.card";
import { zeroPad } from "components/util-components/utilFunctions";

const DistributorDashboard = () => {
  const dispatch = useAppDispatch();
  const params = useParams();
  const distributorState = useSelector(
    (state: RootState) => state?.distributors
  );

  useEffect(() => {
    console.log("details are: ", distributorState);
  }, [distributorState]);

  useEffect(() => {
    dispatch(
      DistributorsActions.fetchDistributorById(params?.distributorId || "")
    );
    dispatch(
      DistributorsActions.fetchDistributorStats(params?.distributorId || "")
    );
    const requestParams = { distributorId: params?.distributorId };
    const paginationParams = {
      ...requestParams,
    };
    dispatch(
      DistributorsActions.fetchCustomersByDistributor({
        paginationParams,
        requestParams,
      })
    );
  }, []);

  return (
    <>
      <div>
        <Typography.Title
          hidden={distributorState.distributorById.loading}
          style={{ fontSize: 30 }}
        >{`${distributorState.distributorById.details.networkName}'s Dashboard`}</Typography.Title>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <DistributorDashboardCard
          loading={distributorState.distributorCustomers?.loading}
          icon={faUsers}
          title={"Subscribers"}
          description={
            `${zeroPad(
              distributorState.distributorCustomers?.details?.items?.length +
                "",
              2
            )}` || "0"
          }
        />
        <DistributorDashboardCard
          loading={distributorState.distributorStats?.loading}
          icon={faMoneyBillTransfer}
          title={"Total Loans"}
          description={zeroPad(
            (distributorState.distributorStats?.details?.paidCommissions || 0) +
              (distributorState.distributorStats?.details?.pendingCommissions ||
                0),
            2
          )}
          additionalStyle={{ marginLeft: 15 }}
        />
        <DistributorDashboardCard
          loading={distributorState.distributorStats?.loading}
          icon={faMoneyBillTransfer}
          title={"Settled Loans"}
          description={zeroPad(
            distributorState.distributorStats?.details?.paidCommissions?.toString() ||
              "0",
            2
          )}
          additionalStyle={{ marginLeft: 15 }}
        />
        <DistributorDashboardCard
          loading={distributorState.distributorStats?.loading}
          icon={faMoneyBillTransfer}
          title={"Pending Commission"}
          description={zeroPad(
            distributorState.distributorStats?.details?.pendingCommissionAmount?.toString() ||
              "0",
            2
          )}
          additionalStyle={{ marginLeft: 15 }}
        />
        <DistributorDashboardCard
          loading={distributorState.distributorStats?.loading}
          icon={faMoneyBill}
          title={"Paid Commission"}
          description={zeroPad(
            distributorState.distributorStats?.details?.paidCommissionAmount?.toString() ||
              "0",
            2
          )}
          additionalStyle={{ marginLeft: 15 }}
        />
      </div>
      <div style={{ marginTop: 30 }}>
        <Typography.Title style={{ fontSize: 18 }}>
          Subscribers
        </Typography.Title>
        <DistributorCustomersList />
      </div>
    </>
  );
};

export default DistributorDashboard;
