const STORAGE_KEY_NAME = 'session_token';

export const storeSessionToken = (sessionToken: any) => {
  localStorage.setItem(STORAGE_KEY_NAME, sessionToken);
};

export const getSessionToken = () => {
  return localStorage.getItem(STORAGE_KEY_NAME) || '';
};

export const resetSecurityToken = () => {
  localStorage.removeItem(STORAGE_KEY_NAME);
};
