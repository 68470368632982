import { Divider, Result, Skeleton } from "antd";

import HiddenComponent from "components/util-components/HiddenComponent";
import { LoansActions } from "store/slices/loansSlice";
import LoansRequestDashboardActiveLoans from "./loans.request.dashboard.activeLoans";
import LoansRequestDashboardCards from "./loans.request.dashboard.cards";
import LoansRequestDashboardCustomerDetails from "./loans.request.dashboard.customersDetails";
import LoansRequestDashboardCustomerHeader from "./loans.request.dashboard.header";
import LoansRequestDashboardForm from "./loans.request.dashboard.form";
import { RootState } from "store/store";
import { useAppDispatch } from "store/hooks";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

const LoanRequestDashboard = () => {
  const params = useParams();
  const dispatch = useAppDispatch();
  const { requestDetails, customerDetails } = useSelector(
    (state: RootState) => state?.loans?.loansRequestDashboard
  );

  useEffect(() => {
    dispatch(LoansActions.fetchLoanRequestById(params?.loanRequestId || ""));
  }, []);

  useEffect(() => {
    if (!requestDetails?.loading && requestDetails?.details?.id) {
      dispatch(
        LoansActions.fetchCustomerById(requestDetails?.details?.customerId)
      );
      dispatch(
        LoansActions.fetchCustomerLoansById(requestDetails?.details?.customerId)
      );
    }
  }, [requestDetails?.loading]);

  return (
    <HiddenComponent
      hidden={requestDetails?.error}
      fallBack={
        <Result
          status="500"
          title="500"
          subTitle="Sorry, something went wrong."
        />
      }
    >
      <section
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <div>
          <LoansRequestDashboardCustomerHeader />
        </div>
        <br />
        <div style={{ display: "flex", flexGrow: 1, paddingBottom: 20 }}>
          <div style={{ width: "300px" }}>
            {customerDetails?.loading || requestDetails?.loading ? (
              <Skeleton />
            ) : (
              <LoansRequestDashboardCustomerDetails />
            )}
          </div>
          <Divider type="vertical" style={{ height: "100%" }} />
          <div style={{ flexGrow: 1, paddingInline: 24 }}>
            <LoansRequestDashboardCards />
            <br />
            <br />
            <LoansRequestDashboardActiveLoans />
            <br />
            <br />
            <LoansRequestDashboardForm />
          </div>
        </div>
      </section>
    </HiddenComponent>
  );
};

export default LoanRequestDashboard;
