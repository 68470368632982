import { Button, Drawer, Image, Modal, Table } from "antd";

import { ColumnsType } from "antd/es/table";
import { ICreditScore } from "store/types";
import { RootState } from "store/store";
import { momentDateTimeFormat } from "components/util-components/dateTimeFormattor";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { wrapWithAuthToken } from "components/util-components/utilFunctions";

const CustomerProfileCibilHistory = () => {
  const { t } = useTranslation();
  const [isViewDocModalOpen, setIsViewDocModalOpen] = useState(false);
  const [selectedDocLink, setSelectedDocLink] = useState("");
  const { customerDetails } = useSelector(
    (state: RootState) => state?.customers?.customerProfile
  );
  const navigate = useNavigate();

  const columns: ColumnsType<ICreditScore> = [
    {
      title: t("Date"),
      dataIndex: "date",
      key: "date",
      render: (_, record) => momentDateTimeFormat(record?.updateTime, "date"),
    },
    {
      title: t("Score"),
      dataIndex: "score",
      key: "score",
    },
    {
      title: t("Updated By"),
      dataIndex: "updatedBy",
    },
    {
      title: t("Remarks"),
      dataIndex: "remark",
    },
    {
      title: t("Document"),
      key: "document",
      render: (_, record) => {
        return (
          <>
            <Button
              type="link"
              onClick={() => {
                setIsViewDocModalOpen(true);
                setSelectedDocLink(record?.supportingDocumentLink || "");
              }}
            >
              {t("View Report")}
            </Button>
          </>
        );
      },
    },
  ];
  return (
    <Drawer
      title={t("Cibil Score History")}
      maskClosable={false}
      size="large"
      open={true}
      onClose={() => {
        navigate(`/customers/${customerDetails?.id}/profile`);
      }}
    >
      <Table
        bordered
        columns={columns}
        pagination={false}
        rowKey={(record) => record?.updateTime}
        dataSource={customerDetails?.creditScoreHistory?.slice()?.reverse()} //sliced to make a copy and then reversed, because .reverse() mutates the original array
      />
      <SingleDocView
        open={isViewDocModalOpen}
        close={() => {
          setIsViewDocModalOpen(false);
        }}
        docLink={selectedDocLink}
      />
    </Drawer>
  );
};

export default CustomerProfileCibilHistory;

const SingleDocView = (props: {
  open: boolean;
  docLink: string;
  close: () => void;
}) => {
  const docExtension = props?.docLink?.split(".")?.at(-1) || "";

  return (
    <>
      {docExtension === "pdf" ? (
        <>
          <Modal
            open={props?.open}
            onCancel={props?.close}
            width={1000}
            footer={null}
            maskClosable={false}
          >
            <iframe
              title={"Report"}
              width={900}
              height={600}
              src={props?.docLink}
            ></iframe>
          </Modal>
        </>
      ) : (
        <>
          <Image
            width={200}
            style={{ display: "none" }}
            preview={{
              visible: props?.open,
              src: wrapWithAuthToken(props?.docLink),
              onVisibleChange: (visible: boolean) => {
                props?.close();
              },
            }}
          />
        </>
      )}
    </>
  );
};
