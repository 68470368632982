import "styles/Loans.less";

import {
  DetailDescriptionItem,
  getCustomerKycStatusIcon,
} from "./loans.util-components";
import { Result, Typography } from "antd";

import { CUSTOMER_KYC_STATUS_VIEW } from "store/types";
import { RootState } from "store/store";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const LoansRequestDashboardCustomerDetails = () => {
  const { t } = useTranslation();
  const { details, error } = useSelector(
    (state: RootState) => state.loans.loansRequestDashboard?.customerDetails
  );
  return error ? (
    <div>
      <Result status="500" title="500" subTitle={t("messages.error.generic")} />
    </div>
  ) : (
    <>
      <DetailDescriptionItem label={t("Name")}>
        {details?.firstName + " " + details?.lastName}
      </DetailDescriptionItem>
      <br />
      <DetailDescriptionItem label={t("Customer Code")} copyable={true}>
        {details?.customerId || "-"}
      </DetailDescriptionItem>
      <br />
      <DetailDescriptionItem label={t("Network")}>
        {details?.networkName || "-"}
      </DetailDescriptionItem>
      <br />
      <DetailDescriptionItem label={t("Address")}>
        <>
          {details?.address?.addressLine1} {details?.address?.addressLine2}{" "}
          <br />
          {details?.address?.city} {details?.address?.state}{" "}
          {details?.address?.pincode}
        </>
      </DetailDescriptionItem>
      <br />
      <DetailDescriptionItem label={t("Contact")}>
        <>
          {details?.phoneNumber}
          <br />
          {details?.email}
        </>
      </DetailDescriptionItem>
      <br />
      <DetailDescriptionItem label={t("KYC Status")}>
        <>
          {CUSTOMER_KYC_STATUS_VIEW[details?.kyc?.status]}{" "}
          {getCustomerKycStatusIcon(details?.kyc?.status)}
          <br />
          <Typography.Link
            onClick={() => {
              window.open(`/customers/${details?.id}/profile`);
            }}
            style={{ fontSize: 20 }}
            underline
          >
            {"View Documents"}
          </Typography.Link>
        </>
      </DetailDescriptionItem>
    </>
  );
};
export default LoansRequestDashboardCustomerDetails;
