import { IAuthState, ILoginResponse, ROLES, UserInfo } from "store/types";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getSessionToken,
  resetSecurityToken,
  storeSessionToken,
} from "store/sessionStore";

import { API_END_POINTS } from "../../api/urls";
import { AxiosResponse } from "axios";
import { Requester } from "api/requester";
import { SECURITY_HEADER_NAME } from "../../appConstants";

const emptyUser = {} as UserInfo;
const initialState: IAuthState = {
  loading: true,
  authorized: !!getSessionToken(),
  userInfo: emptyUser,
  error: false,
  homeLink: "/customers/list",
};

export const getHomeByRole = (userInfo: UserInfo) => {
  console.log("user info is: ", userInfo);
  switch (userInfo.role) {
    case ROLES.ADMIN:
      return "/customers/list";
    case ROLES.CREDIT_APPROVER:
      return "/customers/list";
    case ROLES.DELIVERY_MANAGER:
      return "/orders/accepted-list/dispatch-pending";
    case ROLES.LICENSE_KEY_MANAGER:
      return "/orders/accepted-list/license-key-pending";
    case ROLES.VIEW_ADMIN:
      return "/customers/list";
    case ROLES.DISTRIBUTOR_ADMIN:
      return `/distributors/${userInfo.distributorId}/dashboard`;
    default:
      return "/customers/list";
  }
};

const loadExistingSession = createAsyncThunk(
  "auth/loadExistingSession",
  async (payload: ILoginResponse | null, thunkAPI) => {
    let token = payload?.accessToken || getSessionToken();
    if (token) {
      const response: AxiosResponse = await Requester.get(
        API_END_POINTS.OPERATORS.whoAmI,
        {
          headers: {
            [SECURITY_HEADER_NAME]: token,
          },
        }
      ).catch(({ request }) => request);

      if (response?.status === 200) {
        return response.data;
      }
    }
    return false;
  }
);

const registerFirebaseToken = createAsyncThunk(
  "firebase/register",
  async (payload: { token: string; userId: string }, thunkAPI) => {
    if (payload.token) {
      const response: AxiosResponse = await Requester.post(
        API_END_POINTS.OPERATORS.notifications,
        { ...payload, fcmToken: payload.token }
      ).catch(({ response }) => response);
      if (response?.status === 204) {
        console.log("Notification token registered successfully");
      } else {
        console.error("Error in registering Notification token");
      }
    }
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUserAuthorized: (state, { payload }) => {
      state.authorized = payload;
    },
    setSession: (
      state: IAuthState,
      { payload }: { payload: { accessToken: string } }
    ) => {
      storeSessionToken(payload.accessToken);
      state.authorized = true;
    },
    destroySession: (state: IAuthState) => {
      state.authorized = false;
      resetSecurityToken();
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      loadExistingSession.fulfilled,
      (state, { payload }: { payload: UserInfo }) => {
        state.loading = false;
        if (payload) {
          state.homeLink = getHomeByRole(payload);
          state.userInfo = payload;
          state.authorized = true;
        } else {
          state.authorized = false;
          state.userInfo = emptyUser;
          state.error = true;
        }
      }
    );
  },
});

export const AuthActions = {
  ...authSlice.actions,
  registerFirebaseToken,
  loadExistingSession,
};

export const AuthReducer = authSlice.reducer;
