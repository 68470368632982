import { Button, Space, Typography } from "antd";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LoansListActions } from "store/slices/loansListSlice";
import { SyncOutlined } from "@ant-design/icons";
import { faMoneyBill } from "@fortawesome/free-solid-svg-icons";
import { useAppDispatch } from "store/hooks";
import { useTranslation } from "react-i18next";

const LoansRequestHeader = () => {
  const { t } = useTranslation();
  const headerComponentFontSize = 24;
  const dispatch = useAppDispatch();

  const handleRefresh = () => {
    dispatch(LoansListActions.fetchNewLoansRequestsList({}));
    dispatch(LoansListActions.fetchRejectedLoansList({}));
  };
  return (
    <Space
      align="center"
      style={{ justifyContent: "space-between", width: "100%" }}
    >
      <Typography.Text style={{ fontSize: headerComponentFontSize }}>
        <FontAwesomeIcon
          icon={faMoneyBill}
          color={"inherit"}
          fontSize={headerComponentFontSize}
        />{" "}
        {t("Order Requests")}
      </Typography.Text>
      <Button
        onClick={() => {
          handleRefresh();
        }}
        icon={<SyncOutlined />}
      />
    </Space>
  );
};

export default LoansRequestHeader;
