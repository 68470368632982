import { Button, Form, Input, Modal, Space, Typography, message } from "antd";
import { COLORS, NOT_APPLICABLE } from "appConstants";
import { Requester, formatURL } from "api/requester";
import RestrictedComponent, {
  PERMISSIONS,
} from "components/util-components/RestrictedComponent";

import { API_END_POINTS } from "api/urls";
import { CUSTOMER_STATUS } from "store/types";
import { CustomersActions } from "store/slices/customersSlice";
import { ReactComponent as ErpIcon } from "../../assets/images/erpIcon.svg";
import { RootState } from "store/store";
import { momentDateTimeFormat } from "components/util-components/dateTimeFormattor";
import { useAppDispatch } from "store/hooks";
import { useForm } from "antd/es/form/Form";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const CustomerProfileErpMapping = () => {
  const { t } = useTranslation();
  const { customerDetails } = useSelector(
    (state: RootState) => state?.customers?.customerProfile
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFormUploading, setIsFormUploading] = useState(false);
  const [form] = useForm();
  const dispatch = useAppDispatch();

  const handleModalClose = () => {
    form.resetFields();
    setIsModalOpen(false);
  };

  const handleSubmit = async (formValues: any) => {
    setIsFormUploading(true);
    const url = formatURL(API_END_POINTS.CUSTOMERS.erpMapping, {
      customerId: customerDetails?.id,
    });
    const response = await Requester.post(url, formValues).catch(
      ({ response }) => response
    );
    if (response?.status === 204) {
      form.resetFields();
      setIsModalOpen(false);
      message.success(t("Successfully Updated"));
      dispatch(CustomersActions.fetchCustomerById(customerDetails?.id));
    } else {
      setIsFormUploading(false);
      message.error(t("messages.generic"));
    }
    setIsFormUploading(false);
  };

  return (
    <>
      <section>
        {[
          CUSTOMER_STATUS.CREDIT_SCORE_VERIFICATION_PENDING,
          CUSTOMER_STATUS.KYC_PENDING,
          CUSTOMER_STATUS.ERP_MAPPING_PENDING,
        ].includes(customerDetails?.status) ? (
          <RestrictedComponent permission={PERMISSIONS.CUSTOMERS.verifier}>
            <Typography.Title level={3} style={{ marginBottom: 0 }}>
              {t("ERP Mapping")}
            </Typography.Title>
            <Typography.Text style={{ color: "#888", fontSize: 16 }}>
              {t("Map the customer code generated from ERP")}
            </Typography.Text>
            <br />
            <br />
            <Space align="center" direction="vertical">
              <ErpIcon id="creditScore" width={64} height={64} />
              <Button
                onClick={() => {
                  setIsModalOpen(true);
                }}
                style={{ width: 100 }}
                disabled={[
                  CUSTOMER_STATUS.CREDIT_SCORE_VERIFICATION_PENDING,
                  CUSTOMER_STATUS.KYC_PENDING,
                ].includes(customerDetails?.status)}
                type="primary"
              >
                {t("ADD")}
              </Button>
            </Space>
          </RestrictedComponent>
        ) : (
          <>
            <Typography.Title level={3} style={{ marginBottom: 0 }}>
              {t("ERP Mapping")}
            </Typography.Title>
            <Typography.Text style={{ color: "#888" }}>
              {t("Last Updated")}
            </Typography.Text>{" "}
            <Typography.Text>
              {momentDateTimeFormat(
                customerDetails?.erpCustomer?.updateTime,
                "date"
              ) || NOT_APPLICABLE}
            </Typography.Text>
            <br />
            <br />
            <Typography.Title
              level={3}
              style={{
                marginBottom: 0,
                fontSize: 20,
                color: COLORS.LOAN_REQUEST_DASHBOARD.LOAN_REQUEST_TITLE,
              }}
            >
              {t("Customer Code")}
            </Typography.Title>
            <Typography.Title
              level={3}
              style={{ marginTop: 0, fontSize: 20 }}
              copyable
            >
              {customerDetails?.customerId || "-"}
            </Typography.Title>
          </>
        )}
      </section>
      <Modal
        title={t("ERP Mapping")}
        onCancel={handleModalClose}
        open={isModalOpen}
        maskClosable={false}
        footer={
          <Button
            type="primary"
            onClick={form.submit}
            loading={isFormUploading}
          >
            {t("Submit")}
          </Button>
        }
      >
        <br />
        <Form form={form} layout="vertical" onFinish={handleSubmit}>
          <Form.Item
            name={"externalCustomerId"}
            label={t("ERP Id")}
            rules={[
              {
                required: true,
                message: t("ERP Id cannot be empty"),
              },
            ]}
          >
            <Input placeholder={t("Enter ERP Id")} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default CustomerProfileErpMapping;
