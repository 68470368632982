export const API_END_POINTS = {
  AUTH: {
    login: "/auth/login",
    logout: "/auth/logout",
  },
  OPERATORS: {
    notifications: "notification/register",
    users: "/users",
    distributors: "/distributors",
    whoAmI: "/whoami",
  },
  DISTRIBUTORS: {
    list: "/distributors",
    distributorById: "/distributors/{distributorId}",
  },
  CUSTOMERS: {
    list: "/customers",
    customerById: "/customers/{customerId}",
    rejectDocumnets: "/customers/{customerId}/documents/reject",
    approveDocumnets: "/customers/{customerId}/documents/verify",
    creditScore: "/customers/{customerId}/credit-score",
    erpMapping: "/customers/{customerId}/erp",
    allLoans: "/customers/{customerId}/loans",
    activeAssets: "/customers/{customerId}/active-assets",
    approveCustomer: "/customers/{customerId}/approve",
    rejectCustomer: "/customers/{customerId}/reject",
    dashboardStats: "/dashboard/web/{customerId}/stats",
    edit: "/customers/{customerId}",
  },
  PRODUCTS: {
    list: "/products",
    productById: "/products/{productId}",
    newUploadLink: "/files/links/generate",
  },
  LOANS: {
    activelist: "/loans",
    activeById: "/loans/{loanId}",
    requestList: "/loan-request",
    requestById: "/loan-request/{loanRequestId}",
    approveLoanRequest: "/loan-request/{loanRequestId}/approve",
    rejectLoanRequest: "/loan-request/{loanRequestId}/reject",
    addPayment: "/loans/{loanId}/pay",
    deviceDetails: "/loans/{loanId}/assets",
    licenseKeys: "/loans/{loanId}/license-keys",
    markAsDelivered: "/loans/{loanId}/delivered",
    assignDispatcher: "/loans/{loanId}/dispatcher",
    markDistributorFeePaid: "/loans/{loanId}/distributor-fee-paid",
    distributorStats: "/loans/{distributorId}/distributor-stats",
    licenseKeysByAssetId: "/loans/assets/{assetId}/license-keys",
    sendLicenseKeyForAnAsset: "/loans/assets/{assetId}/license-keys/send",
  },
  BRANCHES: {
    list: "/branches",
    branchById: "/branches/{branchId}",
  },
  USERS: {
    list: "/users",
    userById: "/users/{userId}",
  },
};
