import {
  Col,
  Divider,
  Progress,
  Row,
  Skeleton,
  Space,
  Statistic,
  Typography,
} from "antd";
import {
  IndianRuppeNumberFormattor,
  IndianRuppeeView,
} from "components/products/product.util.components";

import { COLORS } from "appConstants";
import ErrorRenderer from "components/util-components/ErrorRenderer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import HiddenComponent from "components/util-components/HiddenComponent";
import { RootState } from "store/store";
import { faIndianRupeeSign } from "@fortawesome/free-solid-svg-icons";
import { momentDateTimeFormat } from "components/util-components/dateTimeFormattor";
import { useSelector } from "react-redux";

const CustomerStatsLoanStats = () => {
  const { details, loading, error } = useSelector(
    (state: RootState) => state.customerStats.customerStatsDetails
  );
  const paidToTotalPercentage =
    (details?.activeLoanStats?.paidAmount /
      details?.activeLoanStats?.totalAmount) *
    100;
  const sectionHeader = (title: string) => {
    return (
      <Typography.Text
        style={{
          fontWeight: "bold",
          color: COLORS.LOAN_REQUEST_DASHBOARD.LOAN_REQUEST_TITLE,
          fontSize: 20,
          textDecoration: "underline",
        }}
      >
        {title}
      </Typography.Text>
    );
  };
  return (
    <section>
      <HiddenComponent hidden={error} fallBack={<ErrorRenderer />}>
        <div>
          {sectionHeader("Orders")}
          <Row gutter={12}>
            <Col span={8}>
              <Statistic
                title={"Active"}
                value={details?.loansCount?.activeLoanCount}
                loading={loading}
              />
            </Col>
            <Col span={8}>
              <Statistic
                title={"Closed"}
                value={details?.loansCount?.closedLoanCount}
                loading={loading}
              />
            </Col>
            <Col span={8}>
              <Statistic
                title={"Rejected"}
                value={details?.loansCount?.rejectedLoanCount}
                loading={loading}
              />
            </Col>
          </Row>
          <br />
        </div>
        <Divider />
        <div>
          {sectionHeader("EMI Details")}
          <Row>
            <Col span={24}>
              <Statistic
                title={"Monthly EMI"}
                prefix={<FontAwesomeIcon icon={faIndianRupeeSign} />}
                value={IndianRuppeNumberFormattor(
                  details?.activeLoanStats?.nextEmiAmount?.toString()
                )}
                loading={loading}
              />
            </Col>
          </Row>
          <br />
          <Row>
            <Col span={24}>
              <Statistic
                title={"Next EMI Due"}
                value={momentDateTimeFormat(
                  details?.activeLoanStats?.nextEmiDueDate,
                  "date"
                )}
                loading={loading}
              />
            </Col>
          </Row>
          <br />
        </div>
        <Divider />
        <div>
          {sectionHeader("Payment Progress")}
          <br />
          <br />
          <Row>
            <Col
              span={24}
              style={{ display: "flex", alignItems: "center", gap: 32 }}
            >
              <Skeleton loading={loading}>
                <Progress
                  type="circle"
                  percent={parseFloat(paidToTotalPercentage?.toFixed(1))}
                />
                <Space
                  direction="vertical"
                  style={{ flexGrow: 1, display: "flex" }}
                >
                  <div>
                    <Typography.Text>{"Total Borrowed: "}</Typography.Text>
                    <IndianRuppeeView
                      value={details?.activeLoanStats?.totalAmount?.toString()}
                      fontSize={20}
                    />
                  </div>
                  <div>
                    <Typography.Text>{"Total Paid: "}</Typography.Text>
                    <IndianRuppeeView
                      value={details?.activeLoanStats?.paidAmount?.toString()}
                      fontSize={20}
                    />
                  </div>
                </Space>
              </Skeleton>
            </Col>
          </Row>
        </div>
      </HiddenComponent>
    </section>
  );
};

export default CustomerStatsLoanStats;
