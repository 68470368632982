import { ICommonState } from 'store/types';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

const initialState: ICommonState = {
  tabTitle: '',
  sideBarCollapsed: true,
  offline: false,
};

export const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setTitle: (state: ICommonState, { payload }: PayloadAction<string>) => {
      state.tabTitle = payload;
    },
    toggleSideBar: (
      state: ICommonState,
      { payload }: PayloadAction<boolean | undefined>
    ) => {
      if (payload !== undefined) {
        state.sideBarCollapsed = payload;
      } else {
        state.sideBarCollapsed = !state.sideBarCollapsed;
      }
    },
  },
});

export const CommonActions = { ...commonSlice.actions };

export const CommonReducer = commonSlice.reducer;
