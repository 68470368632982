import { Spin, Tabs, TabsProps } from "antd";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { useLocation, useNavigate } from "react-router-dom";

import { ILoanRequestScreenTabs } from "store/types";
import { LoansListActions } from "store/slices/loansListSlice";
import LoansRequestListPending from "./loans.request.list.pending";
import LoansRequestListRejected from "./loans.request.list.rejected";
import { RootState } from "store/store";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const LoansRequestList = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { currentRequestTab, newRequestsTable, rejectedRequestsTable } =
    useAppSelector((state: RootState) => state.loansList);
  const location = useLocation();
  const currentTab = location?.pathname?.split("/")?.at(3) || "";

  const tabPaths = {
    [ILoanRequestScreenTabs.NEW_REQUESTS]: "new",
    [ILoanRequestScreenTabs.REJECTED_REQUESTS]: "rejected",
  };

  useEffect(() => {
    if (Object.values(tabPaths).includes(currentTab)) {
      navigate(
        `/orders/requests-list/${
          tabPaths[currentRequestTab as keyof typeof tabPaths]
        }`,
        { replace: true }
      );
    } else {
      navigate(`/orders/requests-list/new`, { replace: true });
    }
  }, [currentRequestTab]);

  const handleTabChange = (key: string) => {
    dispatch(LoansListActions.setCurrentRequestTab(key));
  };

  const items: TabsProps["items"] = [
    {
      key: ILoanRequestScreenTabs.NEW_REQUESTS,
      label: (
        <>
          {t("New Requests")}{" "}
          <Spin size="small" spinning={newRequestsTable?.loading} />
        </>
      ),
      children: <LoansRequestListPending />,
      disabled: newRequestsTable?.loading,
    },
    {
      key: ILoanRequestScreenTabs.REJECTED_REQUESTS,
      label: (
        <>
          {t("Rejected Requests")}{" "}
          <Spin size="small" spinning={rejectedRequestsTable?.loading} />
        </>
      ),
      children: <LoansRequestListRejected />,
      disabled: rejectedRequestsTable?.loading,
    },
  ];
  return (
    <Tabs
      onChange={handleTabChange}
      defaultActiveKey={currentRequestTab}
      items={items}
    />
  );
};

export default LoansRequestList;
