import { Result } from "antd";
import { useTranslation } from "react-i18next";

const ErrorRenderer = (props: { status?: number | undefined }) => {
  const { t } = useTranslation();
  const getErrorComponent = (status?: number | undefined) => {
    switch (status) {
      case 404:
        return (
          <Result
            status="404"
            title="404"
            subTitle={t("Could not find the page you are looking for.")}
          />
        );
      case 403:
        return (
          <Result
            status="403"
            title="403"
            subTitle={t(
              "Sorry, You donot have the required permissions to access this information."
            )}
          />
        );
      default:
        return (
          <Result
            status="500"
            title="500"
            subTitle={t("messages.error.generic")}
          />
        );
    }
  };
  return <>{getErrorComponent(props?.status)}</>;
};

export default ErrorRenderer;
