// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../node_modules/css-loader/dist/cjs.js!../../node_modules/antd/dist/reset.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "body {\n  height: 100vh;\n  width: 100vw;\n  overflow: hidden;\n}\nbody .app-baseLayout {\n  min-height: 100vh;\n  overflow: auto;\n}\n", "",{"version":3,"sources":["webpack://./src/styles/App.less"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,YAAA;EACA,gBAAA;AAAF;AAHA;EAKI,iBAAA;EACA,cAAA;AACJ","sourcesContent":["@import \"~antd/dist/reset.css\";\n\nbody {\n  height: 100vh;\n  width: 100vw;\n  overflow: hidden;\n  .app-baseLayout {\n    min-height: 100vh;\n    overflow: auto;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
