import {
  ILoan,
  ILoanRequestScreenTabs,
  ILoanRequestStatus,
  ILoanScreenTabs,
  ILoansList,
  ILoansReduxState,
  ILoansRequest,
  ILoansStatus,
  IPagination,
  LOAN_LIST_SEARCH_CATEGORIES,
} from "store/types";
import axios, { AxiosResponse } from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { API_END_POINTS } from "api/urls";
import { Requester } from "api/requester";

let loansListByStatusCancelTokenSource = axios.CancelToken.source();

const fetchNewLoansRequestsList = createAsyncThunk(
  "loans/fetchNewLoansRequestsList",
  async (payload: IPagination, thunkAPI) => {
    const response: AxiosResponse = await Requester.get(
      API_END_POINTS.LOANS.requestList,
      {
        params: { ...payload, status: ILoanRequestStatus.PENDING },
      }
    ).catch(({ response }) => response);
    if (response?.status === 200) {
      return response?.data;
    } else {
      return thunkAPI.rejectWithValue({
        error: true,
        errorStatus: response?.status,
      });
    }
  }
);

const fetchRejectedLoansList = createAsyncThunk(
  "loans/fetchRejectedLoansList",
  async (payload: IPagination, thunkAPI) => {
    const response: AxiosResponse = await Requester.get(
      API_END_POINTS.LOANS.requestList,
      {
        params: { ...payload, status: ILoanRequestStatus.REJECTED },
      }
    ).catch(({ response }) => response);
    if (response?.status === 200) {
      return response?.data;
    } else {
      return thunkAPI.rejectWithValue({
        error: true,
        errorStatus: response?.status,
      });
    }
  }
);

const fetchLoansListByStatus = createAsyncThunk(
  "loans/fetchLoansListByStatus",
  async (_, thunkAPI) => {
    const { loansList }: { loansList: ILoansReduxState } =
      thunkAPI.getState() as any;
    loansListByStatusCancelTokenSource = axios.CancelToken.source();

    let reqParams = {};
    if (
      loansList.currentLoansTab === "ACTIVE,CHILD_LOAN_PENDING" ||
      loansList.currentLoansTab === "EMI_PENDING"
    ) {
      reqParams = {
        status: loansList?.currentLoansTab,
        // ...loansList?.loansPagination,
        ...loansList?.loanSearchValues,
        ...loansList?.loanSortValues,
      };
    } else {
      reqParams = {
        status: loansList?.currentLoansTab,
        ...loansList?.loanSearchValues,
      };
    }
    const response: AxiosResponse = await Requester.get(
      API_END_POINTS.LOANS.activelist,
      {
        cancelToken: loansListByStatusCancelTokenSource.token,
        params: reqParams,
      }
    ).catch(({ response }) => response);
    if (response?.status === 200) {
      return response?.data;
    } else {
      return thunkAPI.rejectWithValue({
        error: true,
        errorStatus: response?.status,
      });
    }
  }
);

const initialState: ILoansReduxState = {
  newRequestsTable: {
    details: [] as ILoansRequest[],
    loading: false,
    error: false,
    errorStatus: 0,
  },
  rejectedRequestsTable: {
    details: [] as ILoansRequest[],
    loading: false,
    error: false,
    errorStatus: 0,
  },
  activeLoansTable: {
    details: {} as ILoansList,
    loading: false,
    error: false,
    errorStatus: 0,
  },
  closedLoansTable: {
    details: {} as ILoansList,
    loading: false,
    error: false,
    errorStatus: 0,
  },
  deviceMappingPending: {
    details: {} as ILoansList,
    loading: false,
    error: false,
    errorStatus: 0,
  },
  downPaymentPending: {
    details: {} as ILoansList,
    loading: false,
    error: false,
    errorStatus: 0,
  },
  licenseKeyPending: {
    details: {} as ILoansList,
    loading: false,
    error: false,
    errorStatus: 0,
  },
  emiPending: {
    details: {} as ILoansList,
    loading: false,
    error: false,
    errorStatus: 0,
  },
  dispatchPending: {
    details: {} as ILoansList,
    loading: false,
    error: false,
    errorStatus: 0,
  },
  assignDispatcherPending: {
    details: {} as ILoansList,
    loading: false,
    error: false,
    errorStatus: 0,
  },
  currentRequestTab: ILoanRequestScreenTabs.NEW_REQUESTS,
  currentLoansTab: "",
  loansPagination: { pageSize: 10, pageToken: 1 },
  loanSearchValues: {
    customerId: undefined,
    loanNumber: undefined,
  },
  loanSortValues: {
    sort: "customerEmiDate:asc",
  },
};

export const loansListSlice = createSlice({
  name: "loansListSlice",
  initialState,
  reducers: {
    resetToInit: () => initialState,
    setCurrentRequestTab: (state, { payload }) => {
      state.currentRequestTab = payload;
    },
    setCurrentLoansTab: (state, { payload }: { payload: ILoanScreenTabs }) => {
      state.currentLoansTab = payload;
    },
    setLoansPageToken: (state, { payload }: { payload: number }) => {
      state.loansPagination.pageToken = payload;
    },
    setLoansPagination: (state, { payload }: { payload: IPagination }) => {
      state.loansPagination = payload || undefined;
    },
    setLoanSearchValues: (
      state,
      {
        payload,
      }: { payload: { value: string; category: LOAN_LIST_SEARCH_CATEGORIES } }
    ) => {
      switch (payload?.category) {
        case LOAN_LIST_SEARCH_CATEGORIES.CUSTOMER:
          state.loanSearchValues.customerId = payload?.value || undefined;
          state.loanSearchValues.loanNumber = undefined;
          break;
        case LOAN_LIST_SEARCH_CATEGORIES.LOAN_NUMBER:
          state.loanSearchValues.customerId = undefined;
          state.loanSearchValues.loanNumber = payload?.value || undefined;
          break;
        default:
          state.loanSearchValues = {
            customerId: undefined,
            loanNumber: undefined,
          };
          break;
      }
    },
    setLoanSortValues: (state, { payload }) => {
      state.loanSortValues = payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchNewLoansRequestsList.pending, (state) => {
      state.newRequestsTable.loading = true;
    });
    builder.addCase(fetchNewLoansRequestsList.fulfilled, (state, action) => {
      state.newRequestsTable.loading = false;
      state.newRequestsTable.error = false;
      state.newRequestsTable.details = action?.payload?.items;
    });
    builder.addCase(
      fetchNewLoansRequestsList.rejected,
      (state, action: any) => {
        state.newRequestsTable.loading = false;
        state.newRequestsTable.error = true;
        state.newRequestsTable.errorStatus = action?.payload?.errorStatus;
      }
    );

    builder.addCase(fetchRejectedLoansList.pending, (state) => {
      state.rejectedRequestsTable.loading = true;
    });
    builder.addCase(fetchRejectedLoansList.fulfilled, (state, action) => {
      state.rejectedRequestsTable.loading = false;
      state.rejectedRequestsTable.error = false;
      state.rejectedRequestsTable.details = action?.payload?.items;
    });
    builder.addCase(fetchRejectedLoansList.rejected, (state, action: any) => {
      state.rejectedRequestsTable.loading = false;
      state.rejectedRequestsTable.error = true;
      state.rejectedRequestsTable.errorStatus = action?.payload?.errorStatus;
    });

    builder.addCase(fetchLoansListByStatus.pending, (state, action) => {
      // loansListByStatusCancelTokenSource.cancel();
      switch (state.currentLoansTab) {
        case ILoanScreenTabs.ACTIVE:
          state.activeLoansTable.loading = true;
          break;
        case ILoanScreenTabs.CLOSED:
          state.closedLoansTable.loading = true;
          break;
        case ILoanScreenTabs.DEVICE_MAPPING_PENDING:
          state.deviceMappingPending.loading = true;
          break;
        case ILoanScreenTabs.DOWN_PAYMENT_PENDING:
          state.downPaymentPending.loading = true;
          break;
        case ILoanScreenTabs.EMI_PENDING:
          state.emiPending.loading = true;
          break;
        case ILoanScreenTabs.LICENSE_KEY_PENDING:
          state.licenseKeyPending.loading = true;
          break;
        case ILoanScreenTabs.DISPATCH_PENDING:
          state.dispatchPending.loading = true;
          break;
        case ILoanScreenTabs.ASSIGN_DISPATCHER_PENDING:
          state.assignDispatcherPending.loading = true;
          break;
        default:
          state.activeLoansTable.loading = true;
          state.closedLoansTable.loading = true;
          state.deviceMappingPending.loading = true;
          state.downPaymentPending.loading = true;
          state.emiPending.loading = true;
          state.licenseKeyPending.loading = true;
          state.dispatchPending.loading = true;
          state.assignDispatcherPending.loading = true;
          break;
      }
    });
    builder.addCase(fetchLoansListByStatus.fulfilled, (state, action) => {
      switch (state.currentLoansTab) {
        case ILoanScreenTabs.ACTIVE:
          state.activeLoansTable.details = action?.payload;
          state.activeLoansTable.loading = false;
          break;
        case ILoanScreenTabs.CLOSED:
          state.closedLoansTable.details = action?.payload;
          state.closedLoansTable.loading = false;
          break;
        case ILoanScreenTabs.DEVICE_MAPPING_PENDING:
          state.deviceMappingPending.details = action?.payload;
          state.deviceMappingPending.loading = false;
          break;
        case ILoanScreenTabs.DOWN_PAYMENT_PENDING:
          state.downPaymentPending.details = action?.payload;
          state.downPaymentPending.loading = false;
          break;
        case ILoanScreenTabs.EMI_PENDING:
          state.emiPending.details = action?.payload;
          state.emiPending.loading = false;
          break;
        case ILoanScreenTabs.LICENSE_KEY_PENDING:
          state.licenseKeyPending.details = action?.payload;
          state.licenseKeyPending.loading = false;
          break;
        case ILoanScreenTabs.DISPATCH_PENDING:
          state.dispatchPending.details = action?.payload;
          state.dispatchPending.loading = false;
          break;
        case ILoanScreenTabs.ASSIGN_DISPATCHER_PENDING:
          state.assignDispatcherPending.details = action?.payload;
          state.assignDispatcherPending.loading = false;
          break;
        default:
          const items: ILoan[] = action?.payload?.items;
          const closedLoansList = items?.filter(
            (item) => item.status === ILoansStatus.CLOSED
          );
          const deviceMappingPendingList = items?.filter(
            (item) => item.status === ILoansStatus.DEVICE_MAPPING_PENDING
          );
          const downPaymentPendingList = items?.filter(
            (item) => item.status === ILoansStatus.DOWN_PAYMENT_PENDING
          );
          const emiPendingList = items?.filter(
            (item) => item.status === ILoansStatus.EMI_PENDING
          );
          const licenseKeyPendingList = items?.filter(
            (item) => item.status === ILoansStatus.LICENSE_KEY_PENDING
          );
          const dispatchPendingList = items?.filter(
            (item) => item.status === ILoansStatus.DISPATCH_PENDING
          );
          const assignDispatcherPendingList = items?.filter(
            (item) => item.status === ILoansStatus.ASSIGN_DISPATCHER_PENDING
          );

          state.activeLoansTable.details.items = items?.filter(
            (item) => item.status === ILoansStatus.ACTIVE
          );
          state.activeLoansTable.loading = false;

          state.closedLoansTable.details = {
            items: closedLoansList,
            total: closedLoansList?.length,
          };
          state.closedLoansTable.loading = false;

          state.deviceMappingPending.details = {
            items: deviceMappingPendingList,
            total: deviceMappingPendingList?.length,
          };
          state.deviceMappingPending.loading = false;

          state.downPaymentPending.details = {
            items: downPaymentPendingList,
            total: downPaymentPendingList?.length,
          };
          state.downPaymentPending.loading = false;

          state.emiPending.details = {
            items: emiPendingList,
            total: emiPendingList?.length,
          };
          state.emiPending.loading = false;

          state.licenseKeyPending.details = {
            items: licenseKeyPendingList,
            total: licenseKeyPendingList?.length,
          };
          state.licenseKeyPending.loading = false;

          state.dispatchPending.details = {
            items: dispatchPendingList,
            total: dispatchPendingList?.length,
          };
          state.dispatchPending.loading = false;

          state.assignDispatcherPending.details = {
            items: assignDispatcherPendingList,
            total: assignDispatcherPendingList?.length,
          };
          state.assignDispatcherPending.loading = false;
          break;
      }
    });
    builder.addCase(fetchLoansListByStatus.rejected, (state, action: any) => {
      switch (state.currentLoansTab) {
        case ILoanScreenTabs.ACTIVE:
          state.activeLoansTable.error = true;
          state.activeLoansTable.loading = false;
          state.activeLoansTable.errorStatus = action?.payload?.errorStatus;
          break;
        case ILoanScreenTabs.CLOSED:
          state.closedLoansTable.error = true;
          state.closedLoansTable.loading = false;
          state.closedLoansTable.errorStatus = action?.payload?.errorStatus;
          break;
        case ILoanScreenTabs.DEVICE_MAPPING_PENDING:
          state.deviceMappingPending.error = true;
          state.deviceMappingPending.loading = false;
          state.deviceMappingPending.errorStatus = action?.payload?.errorStatus;
          break;
        case ILoanScreenTabs.DOWN_PAYMENT_PENDING:
          state.downPaymentPending.error = true;
          state.downPaymentPending.loading = false;
          state.downPaymentPending.errorStatus = action?.payload?.errorStatus;
          break;
        case ILoanScreenTabs.EMI_PENDING:
          state.emiPending.error = true;
          state.emiPending.loading = false;
          state.emiPending.errorStatus = action?.payload?.errorStatus;
          break;
        case ILoanScreenTabs.LICENSE_KEY_PENDING:
          state.licenseKeyPending.error = true;
          state.licenseKeyPending.loading = false;
          state.licenseKeyPending.errorStatus = action?.payload?.errorStatus;
          break;
        case ILoanScreenTabs.DISPATCH_PENDING:
          state.dispatchPending.error = true;
          state.dispatchPending.loading = false;
          state.dispatchPending.errorStatus = action?.payload?.errorStatus;
          break;
        case ILoanScreenTabs.ASSIGN_DISPATCHER_PENDING:
          state.assignDispatcherPending.error = true;
          state.assignDispatcherPending.loading = false;
          state.assignDispatcherPending.errorStatus =
            action?.payload?.errorStatus;
          break;
        default:
          state.activeLoansTable.error = true;
          state.closedLoansTable.error = true;
          state.deviceMappingPending.error = true;
          state.downPaymentPending.error = true;
          state.emiPending.error = true;
          state.licenseKeyPending.error = true;
          state.dispatchPending.error = true;
          state.assignDispatcherPending.error = true;
          state.activeLoansTable.errorStatus = action?.payload?.errorStatus;
          state.closedLoansTable.errorStatus = action?.payload?.errorStatus;
          state.deviceMappingPending.errorStatus = action?.payload?.errorStatus;
          state.downPaymentPending.errorStatus = action?.payload?.errorStatus;
          state.emiPending.errorStatus = action?.payload?.errorStatus;
          state.licenseKeyPending.errorStatus = action?.payload?.errorStatus;
          state.dispatchPending.errorStatus = action?.payload?.errorStatus;
          state.assignDispatcherPending.errorStatus =
            action?.payload?.errorStatus;
          state.activeLoansTable.loading = false;
          state.closedLoansTable.loading = false;
          state.deviceMappingPending.loading = false;
          state.downPaymentPending.loading = false;
          state.emiPending.loading = false;
          state.licenseKeyPending.loading = false;
          state.dispatchPending.loading = false;
          state.assignDispatcherPending.loading = false;
          break;
      }
    });
  },
});

export const LoansListActions = {
  ...loansListSlice.actions,
  fetchNewLoansRequestsList,
  fetchRejectedLoansList,
  fetchLoansListByStatus,
};

export const LoansListReducer = loansListSlice.reducer;
