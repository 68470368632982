import "styles/Loans.less";

import { Divider, Typography } from "antd";

import { DetailDescriptionItem } from "components/loans/loans.util-components";
import HiddenComponent from "components/util-components/HiddenComponent";
import { ILoanType } from "store/types";
import Loader from "components/util-components/Loader";
import { RootState } from "store/store";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const LoanDashboardCustomerDetails = () => {
  const { t } = useTranslation();
  const { details, loading } = useSelector(
    (state: RootState) => state?.loans?.loanDashboard
  );

  return (
    <HiddenComponent hidden={loading} fallBack={<Loader />}>
      <DetailDescriptionItem label={t("Name")}>
        <Typography.Link
          onClick={() => {
            window.open(
              `/customers/${details?.customerDetails.id}/dashboard`,
              "_blank"
            );
          }}
          style={{ fontSize: 20 }}
        >
          {details?.customerDetails?.firstName +
            " " +
            details?.customerDetails?.lastName}
        </Typography.Link>
      </DetailDescriptionItem>
      <br />
      <DetailDescriptionItem label={t("Customer Code")} copyable={true}>
        {details?.customerDetails?.externalCustomerId || "-"}
      </DetailDescriptionItem>
      <br />
      <DetailDescriptionItem label={t("Network")}>
        {details?.customerDetails?.networkName || "-"}
      </DetailDescriptionItem>
      <br />
      <DetailDescriptionItem label={t("Address")}>
        {details?.customerDetails?.address?.addressLine1 +
          "," +
          (details?.customerDetails?.address?.addressLine2
            ? details?.customerDetails?.address?.addressLine2 + ","
            : "") +
          details?.customerDetails?.address?.city}
        <br />
        {details?.customerDetails?.address?.state +
          "," +
          details?.customerDetails?.address?.pincode}
      </DetailDescriptionItem>
      <br />
      <DetailDescriptionItem label={t("Contact")}>
        <>
          {details?.customerDetails?.phoneNumber || "-"}
          <br />
          {details?.customerDetails?.emailId || "-"}
        </>
      </DetailDescriptionItem>
      <HiddenComponent hidden={details?.loanType === ILoanType.PRIMARY}>
        <Divider />
        <DetailDescriptionItem label={t("Mapped Asset Name")}>
          {details?.mappedAssetDetails?.assetName}
        </DetailDescriptionItem>
        <br />
        <DetailDescriptionItem label={t("Mapped Asset MAC")}>
          {details?.mappedAssetDetails?.assetMac}
        </DetailDescriptionItem>
        <br />
        <DetailDescriptionItem label={t("Parent Loan")}>
          <Typography.Link
            style={{
              marginTop: 0,
              fontSize: 20,
            }}
            onClick={() => {
              window.open(
                `/orders/details/${details?.mappedAssetDetails?.parentLoanId}`,
                "_blank"
              );
            }}
          >
            {details?.mappedAssetDetails?.parentLoanId}
          </Typography.Link>
        </DetailDescriptionItem>
      </HiddenComponent>
    </HiddenComponent>
  );
};
export default LoanDashboardCustomerDetails;
