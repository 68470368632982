import {
  Drawer,
  Row,
  Col,
  Form,
  Button,
  Input,
  Radio,
  message,
  Select,
} from "antd";
import { useForm } from "antd/es/form/Form";
import { formatURL, Requester } from "api/requester";
import { API_END_POINTS } from "api/urls";
import RestrictedComponent, {
  PERMISSIONS,
} from "components/util-components/RestrictedComponent";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "store/hooks";
import { DistributorsActions } from "store/slices/distributorsSlice";
import { RootState } from "store/store";
import { ICustomer, IDistributor } from "store/types";

interface ICustomerUpdate {
  selectedCustomer: ICustomer;
  open: boolean;
  onClose: () => void;
}

const CustomersUpdateForm = (props: ICustomerUpdate) => {
  const [form] = useForm();
  const [updateInProgress, setUpdateInProgress] = useState(false);
  const dispatch = useAppDispatch();

  const { distributorsList } = useSelector(
    (state: RootState) => state.distributors
  );

  useEffect(() => {
    dispatch(DistributorsActions.fetchDistributorsList({}));
  }, []);

  useEffect(() => {
    if (props.selectedCustomer) {
      form.setFieldsValue({
        firstName: props.selectedCustomer.firstName,
        lastName: props.selectedCustomer.lastName,
        email: props.selectedCustomer.email,
        networkName: props.selectedCustomer.networkName,
        vip: props.selectedCustomer.vip,
        distributorId: props.selectedCustomer.distributorId,
      });
    }
  }, [props.selectedCustomer]);

  const handleSubmit = async (formValues: any) => {
    let url = formatURL(API_END_POINTS.CUSTOMERS.edit, {
      customerId: props.selectedCustomer.id,
    });

    setUpdateInProgress(true);
    const response = await Requester.patch(url, formValues).catch(
      ({ response }) => response
    );
    if (response?.status === 200) {
      message.success(t("Customer update done successfully"));
      setUpdateInProgress(false);
      props.onClose();
    } else {
      message.error(t("Customer update failed"));
      setUpdateInProgress(false);
    }
  };

  return (
    <Drawer
      open={props.open}
      maskClosable={false}
      size={"large"}
      title={t("labels.customers.edit.title")}
      footer={
        <RestrictedComponent permission={PERMISSIONS.CUSTOMERS.update}>
          <Row>
            <Col span={24} style={{ textAlign: "right" }}>
              <Form.Item className="form-actionbuttons">
                <Button
                  type="primary"
                  onClick={form.submit}
                  loading={updateInProgress}
                >
                  {t("labels.submit")}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </RestrictedComponent>
      }
      onClose={props.onClose}
    >
      <Form form={form} onFinish={handleSubmit} layout="vertical">
        <Form.Item
          rules={[
            {
              required: true,
              message: t("messages.validations.firstNameRequired"),
            },
          ]}
          name={"firstName"}
          label={t("labels.customers.edit.firstName")}
        >
          <Input placeholder={t("placeholders.firstName")} />
        </Form.Item>

        <Form.Item
          rules={[
            {
              required: true,
              message: t("messages.validations.lastNameRequired"),
            },
          ]}
          name={"lastName"}
          label={t("labels.customers.edit.lastName")}
        >
          <Input placeholder={t("placeholders.lastName")} />
        </Form.Item>

        <Form.Item
          name={"email"}
          label={t("labels.customers.edit.email")}
          rules={[
            {
              required: true,
              message: t("messages.validations.emailRequired"),
            },
            {
              type: "email",
              message: t("messages.validations.emailPattern"),
            },
          ]}
        >
          <Input placeholder={t("placeholders.email")}></Input>
        </Form.Item>

        <Form.Item
          name={"networkName"}
          label={t("labels.customers.edit.networkName")}
          rules={[
            {
              required: true,
              message: t("messages.validations.networkNameRequired"),
            },
          ]}
        >
          <Input placeholder={t("placeholders.networkName")}></Input>
        </Form.Item>

        <Form.Item
          name={"vip"}
          label={"VIP Customer"}
          rules={[
            {
              required: true,
              message: t("messages.validations.vipRequired"),
            },
          ]}
        >
          <Radio.Group>
            <Radio value={true}>Yes</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
        </Form.Item>

        {/* <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 12 }}
              xl={{ span: 12 }}
              xxl={{ span: 12 }}
            > */}
        <Form.Item
          name={"distributorId"}
          label={t("Distributor (Referred By)")}
        >
          <Select showSearch allowClear placeholder={t("Select Distributor")}>
            {distributorsList.details.map((distributor: IDistributor) => {
              return (
                <Select.Option
                  key={distributor?.distributorId}
                  value={distributor?.distributorId}
                >
                  {distributor?.networkName}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
        {/* </Col> */}
      </Form>
    </Drawer>
  );
};

export default CustomersUpdateForm;
