import "styles/CustomerProfile.less";

import { Button, Form, Typography, message } from "antd";
import { CUSTOMER_KYC_STATUS, CUSTOMER_STATUS } from "store/types";
import { Requester, formatURL } from "api/requester";

import { API_END_POINTS } from "api/urls";
import { CustomersActions } from "store/slices/customersSlice";
import { RootState } from "store/store";
import TextArea from "antd/es/input/TextArea";
import { useAppDispatch } from "store/hooks";
import { useForm } from "antd/es/form/Form";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const CustomerProfileAcceptance = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [form] = useForm();
  const [isApproveLoading, setIsApproveLoading] = useState(false);
  const [isRejectLoading, setIsRejectLoading] = useState(false);
  const [remarks, setRemarks] = useState("");
  const { customerDetails } = useSelector(
    (state: RootState) => state?.customers?.customerProfile
  );
  const dispatch = useAppDispatch();
  const isApproveButtonActive = [
    CUSTOMER_STATUS.VERIFICATION_COMPLETED,
  ].includes(customerDetails?.status);

  const handleSubmit = async (type: "APPROVE" | "REJECT") => {
    const typeMap = {
      APPROVE: {
        status: 200,
        url: API_END_POINTS.CUSTOMERS.approveCustomer,
        message: t("Approved Successfully"),
        dataKey: "remarks",
      },
      REJECT: {
        status: 204,
        url: API_END_POINTS.CUSTOMERS.rejectCustomer,
        message: t("Rejected Successfully"),
        dataKey: "rejectionReason",
      },
    };
    if (type === "APPROVE") {
      setIsApproveLoading(true);
    } else {
      setIsRejectLoading(true);
    }
    const url = formatURL(typeMap[type].url, {
      customerId: customerDetails?.id,
    });
    const data = {
      [typeMap[type].dataKey]: remarks,
    };
    const response = await Requester.post(url, data).catch(
      ({ response }) => response
    );
    if (response?.status === typeMap[type].status) {
      message.success(typeMap[type].message);
      dispatch(CustomersActions.setSelectedTab(CUSTOMER_KYC_STATUS.COMPLETED));
      navigate("/customers/list/");
    } else {
      message.error(t("Failed"));
    }
    if (type === "APPROVE") {
      setIsApproveLoading(false);
    } else {
      setIsRejectLoading(false);
    }
  };
  return (
    <section>
      <Typography.Title level={3} style={{ marginBottom: 0 }}>
        {t("Customer Approval")}
      </Typography.Title>
      <Typography.Text style={{ color: "#888", fontSize: 16 }}>
        {t("Approve / Reject customer with valid remarks")}
      </Typography.Text>
      <br />
      <br />
      <Form form={form}>
        <Form.Item
          name={"remarks"}
          rules={[
            {
              required: true,
              message: t("Please enter remarks"),
            },
          ]}
        >
          <TextArea
            onChange={(e) => {
              setRemarks(e.target.value);
            }}
            className="customer-profile-acceptance-textArea"
            placeholder={t("Remarks")}
          />
        </Form.Item>
      </Form>
      <div className="customer-profile-acceptance-button-group">
        <Button
          onClick={() => {
            if (remarks === "") {
              form.validateFields();
            } else {
              handleSubmit("REJECT");
            }
          }}
          loading={isRejectLoading}
          disabled={isApproveLoading}
        >
          {t("REJECT")}
        </Button>
        <Button
          type="primary"
          loading={isApproveLoading}
          disabled={isRejectLoading || !isApproveButtonActive}
          onClick={() => {
            if (remarks === "") {
              form.validateFields();
            } else {
              handleSubmit("APPROVE");
            }
          }}
        >
          {t("APPROVE")}
        </Button>
      </div>
    </section>
  );
};

export default CustomerProfileAcceptance;
