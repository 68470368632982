import { Button, Tag, Typography } from "antd";
import RestrictedComponent, {
  PERMISSIONS,
} from "components/util-components/RestrictedComponent";

import HiddenComponent from "components/util-components/HiddenComponent";
import { ILoansStatus } from "store/types";
import LoanDashboardDeliveryStatusForm from "./loan.dashboard.deliveryStatusForm";
import { RootState } from "store/store";
import { SafetyCertificateOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import LoanDashboardAssignDispatcherForm from "components/loanDashboard/loan.dashboard.assignDispatcherForm";

const LoanDashboardProductDetailsHeading = () => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAssignDispatcherModalOpen, setIsAssignDispatcherModalOpen] =
    useState(false);
  const { details } = useSelector(
    (state: RootState) => state.loans.loanDashboard
  );
  useEffect(() => {
    console.log("details are: ", details);
  }, []);
  return (
    <section
      style={{
        display: "flex",
        gap: "16px",
      }}
    >
      <Typography.Title level={3} style={{ color: "#104359" }}>
        {t("Product Details")}
      </Typography.Title>
      <RestrictedComponent permission={PERMISSIONS.LOAN.assignDispatcher}>
        <HiddenComponent
          hidden={[
            ILoansStatus.ACTIVE,
            ILoansStatus.EMI_PENDING,
            ILoansStatus.CLOSED,
          ].includes(details?.status)}
          fallBack={
            <Tag
              style={{ alignSelf: "center" }}
              color="green"
              icon={<SafetyCertificateOutlined />}
            >
              {t("Delivered")}
            </Tag>
          }
        >
          <Button
            type="primary"
            onClick={() => {
              setIsAssignDispatcherModalOpen(true);
            }}
            disabled={
              ![ILoansStatus.ASSIGN_DISPATCHER_PENDING].includes(
                details?.status
              )
            }
          >
            {t("Assign Dispatcher")}
          </Button>
        </HiddenComponent>
      </RestrictedComponent>
      <RestrictedComponent permission={PERMISSIONS.LOAN.addDeliveryStatus}>
        <HiddenComponent
          hidden={[
            ILoansStatus.ACTIVE,
            ILoansStatus.EMI_PENDING,
            ILoansStatus.CLOSED,
          ].includes(details?.status)}
          // fallBack={
          //   <Tag
          //     style={{ alignSelf: "center" }}
          //     color="green"
          //     icon={<SafetyCertificateOutlined />}
          //   >
          //     {t("Delivered")}
          //   </Tag>
          // }
        >
          <Button
            type="primary"
            onClick={() => {
              setIsModalOpen(true);
            }}
            disabled={
              ![ILoansStatus.DISPATCH_PENDING].includes(details?.status)
            }
          >
            {t("Add Delivery Status")}
          </Button>
        </HiddenComponent>
      </RestrictedComponent>
      <LoanDashboardDeliveryStatusForm
        open={isModalOpen}
        close={() => {
          setIsModalOpen(false);
        }}
      />
      <LoanDashboardAssignDispatcherForm
        open={isAssignDispatcherModalOpen}
        close={() => {
          setIsAssignDispatcherModalOpen(false);
        }}
      />
    </section>
  );
};

export default LoanDashboardProductDetailsHeading;
