import {
  CUSTOMER_DOCUMENT_TYPE,
  CUSTOMER_DOCUMENT_TYPE_VIEW,
} from "store/types";
import { Image, Modal } from "antd";

import AadharCardLogo from "assets/images/aadhar.png";
import BankStatemnetLogo from "assets/images/bankStatement.png";
import { FALL_BACK_IMAGE } from "appConstants";
import HiddenComponent from "components/util-components/HiddenComponent";
import PanCardLogo from "assets/images/pan.png";
import { useState } from "react";
import { wrapWithAuthToken } from "components/util-components/utilFunctions";

interface ICustomerDocViewerProps {
  docType: CUSTOMER_DOCUMENT_TYPE;
  frontPage?: string;
  backPage?: string;
}
const CustomerDocViewer = (props: ICustomerDocViewerProps) => {
  const docExtension = props?.frontPage?.split(".")?.at(-1) || "";
  const [visible, setVisible] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const getIconPlaceholder = (docType: CUSTOMER_DOCUMENT_TYPE) => {
    switch (docType) {
      case CUSTOMER_DOCUMENT_TYPE.AADHAR_CARD:
        return AadharCardLogo;
      case CUSTOMER_DOCUMENT_TYPE.PAN_CARD:
        return PanCardLogo;
      case CUSTOMER_DOCUMENT_TYPE.BANK_STATEMENT:
        return BankStatemnetLogo;
      default:
        return AadharCardLogo;
    }
  };
  return (
    <HiddenComponent
      hidden={!props?.frontPage}
      fallBack={
        <Image
          preview={{ visible: false, mask: <></> }}
          width={120}
          src={FALL_BACK_IMAGE}
        />
      }
    >
      {docExtension === "pdf" ? (
        <>
          <Image
            onClick={() => {
              setIsModalOpen(true);
            }}
            preview={{ visible: false }}
            width={120}
            height={80}
            src={getIconPlaceholder(props?.docType)}
          />
          <Modal
            title={CUSTOMER_DOCUMENT_TYPE_VIEW[props?.docType]}
            open={isModalOpen}
            width={1000}
            maskClosable={false}
            footer={null}
            onCancel={() => {
              setIsModalOpen(false);
            }}
          >
            <iframe
              title={CUSTOMER_DOCUMENT_TYPE_VIEW[props?.docType]}
              width={900}
              height={600}
              src={wrapWithAuthToken(props?.frontPage)}
            ></iframe>
          </Modal>
        </>
      ) : (
        <>
          <Image
            preview={{ visible: false }}
            width={120}
            height={80}
            src={getIconPlaceholder(props?.docType)}
            onClick={() => {
              setVisible(true);
            }}
          />
          <div style={{ display: "none" }}>
            <Image.PreviewGroup
              preview={{ visible, onVisibleChange: (vis) => setVisible(vis) }}
            >
              <Image src={wrapWithAuthToken(props?.frontPage)} />
              {(props?.backPage && (
                <Image src={wrapWithAuthToken(props?.backPage)} />
              )) ||
                ""}
            </Image.PreviewGroup>
          </div>
        </>
      )}
    </HiddenComponent>
  );
};

export default CustomerDocViewer;
