import { NOT_APPLICABLE } from "appConstants";
import moment from "moment";

type DATE_FORMAT_TYPE =
  | "date"
  | "time"
  | "dateTime"
  | "dateTimeSec"
  | "dateTimeMilliSec";

export function momentDateTimeFormat(
  value: any,
  formatType: DATE_FORMAT_TYPE = "dateTime"
) {
  let timeValue = moment(value + "Z").local();
  if (value?.includes("+05:30") || value?.includes("Z")) {
    timeValue = moment(value);
  }
  if (value && value !== "NA" && value !== NOT_APPLICABLE) {
    switch (formatType) {
      case "time":
        return timeValue.format("LTS");
      case "date":
        return timeValue.format("MMM D YYYY");
      case "dateTime":
        return timeValue.format("MMM DD YYYY HH:mm");
      case "dateTimeSec":
        return timeValue.format("MMM DD YYYY HH:mm:ss");
      case "dateTimeMilliSec":
        return timeValue.format("MMM DD YYYY HH:mm:ss.SSS");
      default:
        return timeValue.format("DD-MMM-YYYY");
    }
  } else {
    return NOT_APPLICABLE;
  }
}

export const mergeDateAndTime = (date: any, time: any) => {
  const formattedDate = date?.format("YYYY-MM-DDT");
  const formattedTime = time?.format("HH:mm:ssZ");
  return `${formattedDate}${formattedTime}`;
};
