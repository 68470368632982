import "../../styles/login.less";

import { Button, Card, Col, Form, Input, Row, Typography } from "antd";

import { API_END_POINTS } from "../../api/urls";
import { AuthActions } from "store/slices/authSlice";
import { ReactComponent as AuthBackground } from "../../assets/images/authBackground.svg";
import { AxiosResponse } from "axios";
import { ReactComponent as Logo } from "../../assets/images/logo_New.svg";
import { Requester } from "api/requester";
import { useAppDispatch } from "store/hooks";
import { useFirebase } from "components/hooks/useFirebase";
import { useForm } from "antd/es/form/Form";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const LoginScreen = () => {
  const [loginRequestStatus, setLoginRequestStatus] = useState("");
  const [loginForm] = useForm();
  const dispatch = useAppDispatch();
  const firebase = useFirebase();
  const { t } = useTranslation();
  const isLoading = loginRequestStatus === "loading";

  const handleSubmit = async (data: any) => {
    const payload = {
      username: data?.emailId,
      password: data?.password,
    };
    setLoginRequestStatus("loading");
    const response: AxiosResponse = await Requester.post(
      API_END_POINTS.AUTH.login,
      payload
    ).catch(({ response }) => response);
    if (response?.status === 200) {
      setLoginRequestStatus("");
      dispatch(AuthActions.setSession(response.data));
      const whoAmIResponse = await dispatch(
        AuthActions.loadExistingSession(response.data)
      );
      if (whoAmIResponse.payload) {
        const token = await firebase.initializeFirebase();
        dispatch(
          AuthActions.registerFirebaseToken({
            token: token || "",
            userId: whoAmIResponse?.payload?.id,
          })
        );
      }
    } else {
      const errorMessage = response?.data?.message || t("messages.error.login");
      setLoginRequestStatus(errorMessage);
    }
  };

  return (
    <>
      <Row className="login-bg" align={"middle"} justify={"center"}>
        <Col
          xs={{ span: 0 }}
          sm={{ span: 0 }}
          md={{ span: 0 }}
          lg={{ span: 12 }}
          xl={{ span: 12 }}
          xxl={{ span: 12 }}
        >
          <AuthBackground />
        </Col>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 12 }}
          xl={{ span: 12 }}
          xxl={{ span: 12 }}
          className="login-form-card-container"
        >
          <Card className="login-card">
            <Logo className="login-form-logo" />
            <Form
              initialValues={{
                emailId: "",
                password: "",
                userId: "ee31e9aa92854d1f9f1a315051b9f03b",
                clientId: "fii-viz-app",
              }}
              layout={"vertical"}
              form={loginForm}
              onFinish={handleSubmit}
              onFinishFailed={(error) => {
                console.log(error);
              }}
            >
              <Form.Item name={"userId"} hidden={true}>
                <Input />
              </Form.Item>
              <Form.Item name={"clientId"} hidden={true}>
                <Input />
              </Form.Item>
              <Form.Item
                label={t("labels.login.email")}
                name={"emailId"}
                rules={[
                  {
                    required: true,
                    message: t("messages.validations.emailRequired"),
                  },
                ]}
              >
                <Input placeholder={t("placeholders.email")} />
              </Form.Item>
              <Form.Item
                label={t("labels.login.password")}
                name={"password"}
                rules={[
                  {
                    required: true,
                    message: t("messages.validations.passwordRequired"),
                  },
                ]}
              >
                <Input.Password placeholder={t("placeholders.password")} />
              </Form.Item>
              {!isLoading && loginRequestStatus && (
                <Typography.Text type="danger">
                  {loginRequestStatus}
                </Typography.Text>
              )}
              <Form.Item>
                <Button type="primary" htmlType="submit" loading={isLoading}>
                  {t("labels.login.title")}
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default LoginScreen;
