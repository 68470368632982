import { Empty, Form, Input, Select, Space, Typography } from "antd";
import { ICustomer, LOAN_LIST_SEARCH_CATEGORIES } from "store/types";
import { MailOutlined, MobileOutlined } from "@ant-design/icons";

import { CustomersActions } from "store/slices/customersSlice";
import { LoansListActions } from "store/slices/loansListSlice";
import { RootState } from "store/store";
import { useAppDispatch } from "store/hooks";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const LoansSeachComponent = () => {
  const { loansList } = useSelector((state: RootState) => state);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { customers } = useSelector((state: RootState) => state);

  const [loanSearchForm] = Form.useForm();

  const onSearch = (value: string, category: LOAN_LIST_SEARCH_CATEGORIES) => {
    dispatch(LoansListActions.setLoanSearchValues({ value, category }));
  };

  useEffect(() => {
    const params = { status: "ACTIVE", pageSize: 1000, pageToken: null };
    dispatch(
      CustomersActions.fetchCustomersList({
        paginationParams: params,
        tab: "COMPLETED",
      })
    );
  }, []);

  useEffect(() => {
    console.log("Customers are: ", customers);
  }, [customers]);

  useEffect(() => {
    updateFormData();
  }, [loansList.loanSearchValues]);

  const updateFormData = () => {
    loanSearchForm.setFieldsValue({
      customerId: loansList.loanSearchValues?.customerId || undefined,
      loanNumber: loansList.loanSearchValues?.loanNumber || undefined,
    });
  };

  return (
    <Form
      key={"filter-drawer-option"}
      form={loanSearchForm}
      layout={"vertical"}
      className="olt-filter-form"
      style={{
        background: "#fafafa",
        paddingLeft: 20,
        paddingRight: 20,
        borderRadius: 5,
        marginTop: 10,
      }}
    >
      <Space className={"loans-list-search-container"}>
        <Form.Item label={t("labels.loans.customer")} name={"customerId"}>
          <Select
            showSearch
            allowClear
            style={{ width: 350 }}
            placeholder={
              customers.customerList.loading
                ? `${t("labels.loans.loading")}...`
                : customers.customerList.error ||
                  t("placeholders.searchByCustomer")
            }
            optionLabelProp={"label"}
            loading={customers.customerList.loading}
            disabled={customers.customerList.loading}
            options={customers.customerList.customersListActive?.map(
              (customer: ICustomer) => {
                return {
                  key: customer?.id,
                  label: (
                    <Space direction={"vertical"} size={0}>
                      <Space split>
                        <Typography.Text>
                          {`${customer?.firstName || ""} ${
                            customer?.lastName || ""
                          }`}
                        </Typography.Text>
                        <Typography.Text
                          type={"secondary"}
                          style={{ fontSize: 12 }}
                        >
                          <MobileOutlined /> {customer?.phoneNumber}
                        </Typography.Text>
                      </Space>

                      <Typography.Text type={"secondary"}>
                        <MailOutlined /> {customer?.email}
                      </Typography.Text>
                    </Space>
                  ),
                  value: customer?.id,
                  data: customer,
                };
              }
            )}
            notFoundContent={
              <Empty
                description={
                  <Typography.Text
                    type={
                      !customers.customerList.loading &&
                      customers.customerList.error
                        ? "danger"
                        : undefined
                    }
                  >
                    {customers.customerList.loading
                      ? `${t("labels.loans.loading")}...`
                      : customers.customerList.error
                      ? t("messages.error.fetchCustomerList")
                      : t("messages.noContent")}
                  </Typography.Text>
                }
              />
            }
            onChange={(value: string) =>
              onSearch(value, LOAN_LIST_SEARCH_CATEGORIES.CUSTOMER)
            }
            filterOption={(inputValue, options: any) => {
              console.log(inputValue, options, options?.data?.firstName);
              return [
                options?.data?.firstName?.toLowerCase(),
                options?.data?.lastName?.toLowerCase(),
                options?.data?.phoneNumber,
                options?.data?.email?.toLowerCase(),
              ]
                ?.join(" ")
                ?.includes(inputValue?.toLowerCase());
            }}
          />
        </Form.Item>

        <Form.Item label={t("labels.loans.orderId")} name={"loanNumber"}>
          <Input.Search
            placeholder={t("placeholders.searchByOrderId")}
            allowClear
            enterButton="Search"
            onSearch={(value: string) =>
              onSearch(value, LOAN_LIST_SEARCH_CATEGORIES.LOAN_NUMBER)
            }
          />
        </Form.Item>
      </Space>
    </Form>
  );
};

export default LoansSeachComponent;
