import { Alert } from "antd";
import { ROLES } from "store/types";
import { ReactNode } from "react";
import { RootState } from "store/store";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

export const PERMISSIONS = {
  USERS: {
    create: "users.create",
    edit: "users.edit",
    delete: "users.delete",
    editMine: "users.edit.mine",
    getAll: "users.getAll",
  },
  PRODUCTS: {
    create: "products.create",
  },
  CUSTOMERS: {
    viewDashboard: "customers.viewDashboard", //includes Credit History
    viewTabs: "customers.viewTabs",
    verifier: "customers.verifier",
    update: "customer.update",
  },
  LOAN_REQUESTS: {
    viewTabs: "loanRequests.viewTabs",
    viewRequest: "loanRequests.viewRequest",
  },
  LOAN: {
    addLicenseKeys: "loan.addLicenseKeys",
    addDeliveryStatus: "loan.addDeliveryStatus",
    mapToErp: "loan.mapToErp",
    assignDispatcher: "loan.assignDispatcher",
  },
  DISTRIBUTORS: {
    create: "distributors.create",
    edit: "distributors.edit",
    view: "distributors.view",
    delete: "distributors.delete",
    dashboard: "distributors.dashboard",
  },
};

export const AllowedPermission: { [key in ROLES]: string[] } = {
  [ROLES.ADMIN]: [
    PERMISSIONS.USERS.create,
    PERMISSIONS.USERS.edit,
    PERMISSIONS.PRODUCTS.create,
    PERMISSIONS.DISTRIBUTORS.create,
    PERMISSIONS.DISTRIBUTORS.edit,
    PERMISSIONS.DISTRIBUTORS.delete,
    PERMISSIONS.DISTRIBUTORS.dashboard,
    PERMISSIONS.CUSTOMERS.viewDashboard,
    PERMISSIONS.CUSTOMERS.viewTabs,
    PERMISSIONS.CUSTOMERS.verifier,
    PERMISSIONS.CUSTOMERS.update,
    PERMISSIONS.LOAN_REQUESTS.viewTabs,
    PERMISSIONS.LOAN_REQUESTS.viewRequest,
    PERMISSIONS.LOAN.addLicenseKeys,
    PERMISSIONS.LOAN.addDeliveryStatus,
    PERMISSIONS.LOAN.mapToErp,
    PERMISSIONS.LOAN.assignDispatcher,
    PERMISSIONS.USERS.getAll,
  ],
  [ROLES.CREDIT_APPROVER]: [
    PERMISSIONS.USERS.editMine,
    PERMISSIONS.CUSTOMERS.viewDashboard,
    PERMISSIONS.CUSTOMERS.viewTabs,
    PERMISSIONS.CUSTOMERS.verifier,
    PERMISSIONS.LOAN_REQUESTS.viewTabs,
    PERMISSIONS.LOAN_REQUESTS.viewRequest,
    PERMISSIONS.LOAN.addDeliveryStatus,
    PERMISSIONS.LOAN.mapToErp,
  ],
  [ROLES.LICENSE_KEY_MANAGER]: [
    PERMISSIONS.LOAN.addLicenseKeys,
    PERMISSIONS.LOAN.assignDispatcher,
    PERMISSIONS.USERS.editMine,
  ],
  [ROLES.DELIVERY_MANAGER]: [
    PERMISSIONS.LOAN.addDeliveryStatus,
    PERMISSIONS.USERS.editMine,
  ],
  [ROLES.VIEW_ADMIN]: [
    PERMISSIONS.USERS.editMine,
    PERMISSIONS.CUSTOMERS.viewDashboard,
    PERMISSIONS.CUSTOMERS.viewTabs,
    PERMISSIONS.LOAN_REQUESTS.viewTabs,
    PERMISSIONS.LOAN_REQUESTS.viewRequest,
  ],
  [ROLES.DISTRIBUTOR_ADMIN]: [
    PERMISSIONS.DISTRIBUTORS.edit,
    PERMISSIONS.DISTRIBUTORS.view,
    PERMISSIONS.DISTRIBUTORS.dashboard,
    PERMISSIONS.USERS.editMine,
  ],
};

interface IPermissinProps {
  permission?: string;
  permissions?: string[];
  children: ReactNode;
  withAlert?: boolean;
}
const RestrictedComponent = (props: IPermissinProps) => {
  const { auth } = useSelector((state: RootState) => state);
  const { t } = useTranslation();

  let permissionExist = false;
  if (props.permissions) {
    props.permissions.forEach((permission: string) => {
      if (AllowedPermission[auth?.userInfo?.role]?.includes(permission)) {
        permissionExist = true;
      }
    });
  } else if (
    props.permission &&
    AllowedPermission[auth?.userInfo?.role]?.includes(props.permission)
  ) {
    permissionExist = true;
  }

  if (permissionExist) {
    return <>{props?.children}</>;
  } else {
    if (props?.withAlert) {
      return (
        <Alert
          type="error"
          description={t(
            "Current user is not authorized to perform this action"
          )}
        />
      );
    } else {
      return <></>;
    }
  }
};

export default RestrictedComponent;
