import React, { useEffect } from 'react';

import { CommonActions } from 'store/slices/commonSlice';
import { Helmet } from 'react-helmet-async';
import { RootState } from 'store/store';
import { useAppDispatch } from 'store/hooks';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

export const useBrowserTitle = (title: string) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(CommonActions.setTitle(t(title)));
  }, []);
};

const BrowserTabTitle = () => {
  const title = useSelector((state: RootState) => state.common.tabTitle);
  const { t } = useTranslation();

  return (
    <Helmet>
      <meta charSet='utf-8' />
      {(title && <title>{`${t(title)} | ${t('common.appName')}`}</title>) || (
        <title>{t('common.appName')}</title>
      )}
    </Helmet>
  );
};

export default React.memo(BrowserTabTitle);
