import "styles/Loans.less";

import { Card, Col, Row, Space, Tooltip, Typography } from "antd";

import { COLORS } from "appConstants";
import { ILoanScreenTabs } from "store/types";
import { IndianRuppeeView } from "components/products/product.util.components";
import { InfoCircleOutlined } from "@ant-design/icons";
import { LoansListActions } from "store/slices/loansListSlice";
import { ReactNode } from "react";
import { RootState } from "store/store";
import { momentDateTimeFormat } from "components/util-components/dateTimeFormattor";
import { useAppDispatch } from "store/hooks";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const headerNActionFontSize = 14;
const valueFontSize = 24;
const onlyValueFontSize = valueFontSize + 8;
const cardHeight = 100;
const cardBorderRadius = 10;

const LoansRequestDashboardCards = () => {
  const { t } = useTranslation();
  const { customerDetails } = useSelector(
    (state: RootState) => state.loans.loansRequestDashboard
  );
  const dispatch = useAppDispatch();
  return (
    <>
      <Row gutter={20} style={{ marginBottom: 20 }}>
        <Col span={5}>
          <CardWithAction
            title={t("CIBIL SCORE")}
            value={
              customerDetails?.details?.creditScore?.score?.toString() || "-"
            }
            actionTitle={"View Report"}
            action={() => {
              window.open(
                `/customers/${customerDetails?.details?.id}/profile/credit-score-history`
              );
            }}
          />
        </Col>
        <Col span={5}>
          <CardWithAction
            title={t("Failed EMIs")}
            value={customerDetails?.details?.loanSummary?.failedEmiCount}
            actionTitle={t("View Details")}
          />
        </Col>
        <Col span={5}>
          <CardWithAction
            title={t("Active Orders")}
            toolTip={t("Current Active Orders")}
            value={customerDetails?.details?.loanSummary?.activeLoanCount}
          />
        </Col>
        <Col span={9}>
          <CardWithAction
            title={t("Total Liabilities")}
            toolTip={t("Current Total Liabilities")}
            value={
              <IndianRuppeeView
                value={
                  customerDetails?.details?.loanSummary?.totalOutstandingAmount?.toString() ||
                  ""
                }
                fontSize={onlyValueFontSize}
                fontColor={COLORS.LOAN_REQUEST_DASHBOARD.LOAN_REQUEST_VALUES}
              />
            }
          />
        </Col>
      </Row>
      <Row gutter={20}>
        <Col span={5}>
          <CardWithAction
            title={t("Rejected Orders")}
            value={customerDetails?.details?.loanSummary?.rejectedLoanCount}
            actionTitle={"View Details"}
            action={() => {
              window.open(`/orders/requests-list/rejected`);
            }}
          />
        </Col>
        <Col span={5}>
          <CardWithAction
            title={t("Closed Orders")}
            value={customerDetails?.details?.loanSummary?.closedLoanCount}
            actionTitle={t("View Details")}
            action={() => {
              dispatch(
                LoansListActions.setCurrentLoansTab(ILoanScreenTabs.CLOSED)
              );
              window.open(`/orders/accepted-list/closed`);
            }}
          />
        </Col>
        <Col span={9}>
          <CardWithAction
            title={t("Monthly EMIs")}
            toolTip={t("Current Monthly EMIs")}
            value={
              <IndianRuppeeView
                value={
                  customerDetails?.details?.loanSummary?.monthlyEmi?.toString() ||
                  ""
                }
                fontSize={onlyValueFontSize}
                fontColor={COLORS.LOAN_REQUEST_DASHBOARD.LOAN_REQUEST_VALUES}
              />
            }
          />
        </Col>
        <Col span={5}>
          <CardWithAction
            title={t("Orders End At")}
            toolTip={t("Current Orders End At")}
            value={momentDateTimeFormat(
              customerDetails?.details?.loanSummary?.loanEndingDate,
              "date"
            )}
          />
        </Col>
      </Row>
    </>
  );
};

export default LoansRequestDashboardCards;

interface ILoanRequestDashboardCards {
  title: string;
  value: string | ReactNode;
  actionTitle?: string;
  action?: () => void;
  toolTip?: string;
}

const CardWithAction = (props: ILoanRequestDashboardCards) => {
  const { customerDetails, requestDetails } = useSelector(
    (state: RootState) => state.loans.loansRequestDashboard
  );

  return (
    <Card
      loading={customerDetails?.loading || requestDetails?.loading}
      style={{
        width: "100%",
        height: cardHeight,
        borderRadius: cardBorderRadius,
      }}
      className="loan-request-details-card"
    >
      <Space align="center" direction="vertical">
        <Typography.Text
          style={{
            fontSize: headerNActionFontSize,
            color: COLORS.LOAN_REQUEST_DASHBOARD.LOAN_REQUEST_TITLE,
          }}
        >
          {props?.title}{" "}
          {props?.toolTip ? (
            <Tooltip title={props?.toolTip}>
              <InfoCircleOutlined />
            </Tooltip>
          ) : (
            <></>
          )}
        </Typography.Text>

        {props?.actionTitle ? (
          <>
            <Typography.Text
              style={{
                fontSize: valueFontSize,
                color: COLORS.LOAN_REQUEST_DASHBOARD.LOAN_REQUEST_VALUES,
              }}
            >
              {props?.value}
            </Typography.Text>
            <Typography.Link
              onClick={props?.action}
              style={{ fontSize: headerNActionFontSize }}
              underline
            >
              {props?.actionTitle}
            </Typography.Link>
          </>
        ) : (
          <>
            <Typography.Text
              style={{
                fontSize: onlyValueFontSize,
                color: COLORS.LOAN_REQUEST_DASHBOARD.LOAN_REQUEST_VALUES,
              }}
            >
              {props?.value}
            </Typography.Text>
          </>
        )}
      </Space>
    </Card>
  );
};
