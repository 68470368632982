import {
  ICustomersList,
  IDistributor,
  IDistributorStats,
  IDistributorsList,
} from "store/types";
import { Requester, formatURL } from "api/requester";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { API_END_POINTS } from "api/urls";
import { AxiosResponse } from "axios";

const initialState = {
  distributorsList: {
    details: [] as IDistributor[],
    loading: false,
    error: false,
    errorStatus: 0,
  },
  distributorById: {
    details: {} as IDistributor,
    loading: false,
    error: false,
    errorStatus: 0,
  },
  distributorStats: {
    details: {} as IDistributorStats,
    loading: false,
    error: false,
    errorStatus: 0,
  },
  distributorCustomers: {
    details: {} as ICustomersList,
    loading: false,
    error: false,
    errorStatus: 0,
  },
};

const fetchApprovedLoansListByDistributor = createAsyncThunk(
  "distributor/fetchLoansListByDistributor",
  async (payload: any, thunkAPI) => {
    const response: AxiosResponse = await Requester.get(
      API_END_POINTS.LOANS.activelist,
      {
        params: payload?.requestParams,
      }
    ).catch(({ response }) => response);
    if (response?.status === 200) {
      return response?.data;
    } else {
      return thunkAPI.rejectWithValue({
        error: true,
        status: response?.status,
      });
    }
  }
);

const fetchRequestedLoansListByDistributor = createAsyncThunk(
  "distributor/fetchLoansListByDistributor",
  async (payload: any, thunkAPI) => {
    const response: AxiosResponse = await Requester.get(
      API_END_POINTS.LOANS.activelist,
      {
        params: payload?.requestParams,
      }
    ).catch(({ response }) => response);
    if (response?.status === 200) {
      return response?.data;
    } else {
      return thunkAPI.rejectWithValue({
        error: true,
        status: response?.status,
      });
    }
  }
);

const fetchDistributorsList = createAsyncThunk(
  "distributorList/list",
  async (payload: any, thunkAPI) => {
    try {
      const response: AxiosResponse = await Requester.get(
        API_END_POINTS.DISTRIBUTORS.list
      );
      return response?.data;
    } catch (e: any) {
      return thunkAPI.rejectWithValue({ errorStatus: e?.response?.status });
    }
  }
);

const fetchDistributorById = createAsyncThunk(
  "distributors/distributorById",
  async (payload: string, thunkAPI) => {
    const url = formatURL(API_END_POINTS.DISTRIBUTORS.distributorById, {
      distributorId: payload,
    });
    try {
      const response: AxiosResponse = await Requester.get(url).catch(
        ({ response }) => response
      );
      return response?.data;
    } catch (e: any) {
      return thunkAPI.rejectWithValue({ errorStatus: e?.response?.status });
    }
  }
);

const fetchDistributorStats = createAsyncThunk(
  "distributors/distributorStats",
  async (payload: string, thunkAPI) => {
    const url = formatURL(API_END_POINTS.LOANS.distributorStats, {
      distributorId: payload,
    });
    try {
      const response: AxiosResponse = await Requester.get(url).catch(
        ({ response }) => response
      );
      return response?.data;
    } catch (e: any) {
      return thunkAPI.rejectWithValue({ errorStatus: e?.response?.status });
    }
  }
);

const fetchCustomersByDistributor = createAsyncThunk(
  "distributors/customersByDistributor",
  async (payload: any, thunkAPI) => {
    try {
      const response = await Requester.get(API_END_POINTS.CUSTOMERS.list, {
        params: payload?.paginationParams,
      });
      return response?.data;
    } catch (e: any) {
      return thunkAPI.rejectWithValue({ errorStatus: e?.response?.status });
    }
  }
);

export const distributorsSlice = createSlice({
  name: "distributors",
  initialState,
  reducers: {
    resetState: (state) => {
      state.distributorById = initialState.distributorById;
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchDistributorsList.rejected, (state, action: any) => {
      state.distributorsList.error = true;
      state.distributorsList.loading = false;
      state.distributorsList.errorStatus = action?.payload?.errorStatus;
    });
    builder.addCase(fetchDistributorsList.pending, (state) => {
      state.distributorsList.loading = true;
    });
    builder.addCase(
      fetchDistributorsList.fulfilled,
      (state, { payload }: { payload: IDistributorsList }) => {
        state.distributorsList.loading = false;
        state.distributorsList.error = false;
        state.distributorsList.details = payload?.items;
      }
    );

    builder.addCase(fetchDistributorById.rejected, (state, action: any) => {
      state.distributorById.error = true;
      state.distributorById.loading = false;
      state.distributorById.errorStatus = action?.payload?.errorStatus;
    });
    builder.addCase(fetchDistributorById.pending, (state) => {
      state.distributorById.loading = true;
    });
    builder.addCase(
      fetchDistributorById.fulfilled,
      (state, { payload }: { payload: IDistributor }) => {
        state.distributorById.loading = false;
        state.distributorById.error = false;
        state.distributorById.details = payload;
      }
    );

    builder.addCase(fetchDistributorStats.rejected, (state, action: any) => {
      state.distributorStats.error = true;
      state.distributorStats.loading = false;
      state.distributorStats.errorStatus = action?.payload?.errorStatus;
    });
    builder.addCase(fetchDistributorStats.pending, (state) => {
      state.distributorStats.loading = true;
    });
    builder.addCase(
      fetchDistributorStats.fulfilled,
      (state, { payload }: { payload: any }) => {
        state.distributorStats.loading = false;
        state.distributorStats.error = false;
        state.distributorStats.details = payload;
      }
    );

    builder.addCase(
      fetchCustomersByDistributor.rejected,
      (state, action: any) => {
        state.distributorCustomers.error = true;
        state.distributorCustomers.loading = false;
        state.distributorCustomers.errorStatus = action?.payload?.errorStatus;
      }
    );
    builder.addCase(fetchCustomersByDistributor.pending, (state) => {
      state.distributorCustomers.loading = true;
    });
    builder.addCase(
      fetchCustomersByDistributor.fulfilled,
      (state, { payload }: { payload: any }) => {
        state.distributorCustomers.loading = false;
        state.distributorCustomers.error = false;
        state.distributorCustomers.details = payload;
      }
    );
  },
});

export const DistributorsActions = {
  ...distributorsSlice.actions,
  fetchDistributorById,
  fetchDistributorsList,
  fetchDistributorStats,
  fetchCustomersByDistributor,
  fetchApprovedLoansListByDistributor,
  fetchRequestedLoansListByDistributor,
};

export const DistributorsReducer = distributorsSlice.reducer;
