import {
  COLOR_BY_DOCUMENT_STATUS,
  ICON_BY_DOCUMNET_STATUS,
} from "appConstants";
import {
  CUSTOMER_DOCUMENT_STATUS,
  CUSTOMER_DOCUMENT_STATUS_VIEW,
} from "store/types";

import { Tag } from "antd";

export const VerifiedTag = () => {
  const Icon = ICON_BY_DOCUMNET_STATUS.VERIFIED;
  return (
    <Tag color={COLOR_BY_DOCUMENT_STATUS.VERIFIED} icon={<Icon />}>
      {CUSTOMER_DOCUMENT_STATUS_VIEW.VERIFIED}
    </Tag>
  );
};

export const PendingTag = () => {
  const Icon = ICON_BY_DOCUMNET_STATUS.PENDING;
  return (
    <Tag color={COLOR_BY_DOCUMENT_STATUS.PENDING} icon={<Icon />}>
      {CUSTOMER_DOCUMENT_STATUS_VIEW.PENDING}
    </Tag>
  );
};
export const SubmittedTag = () => {
  const Icon = ICON_BY_DOCUMNET_STATUS.SUBMITTED;
  return (
    <Tag color={COLOR_BY_DOCUMENT_STATUS.SUBMITTED} icon={<Icon />}>
      {CUSTOMER_DOCUMENT_STATUS_VIEW.SUBMITTED}
    </Tag>
  );
};
export const ReSubmittedTag = () => {
  const Icon = ICON_BY_DOCUMNET_STATUS.RESUBMITTED;
  return (
    <Tag color={COLOR_BY_DOCUMENT_STATUS.RESUBMITTED} icon={<Icon />}>
      {CUSTOMER_DOCUMENT_STATUS_VIEW.RESUBMITTED}
    </Tag>
  );
};
export const RejectedTag = () => {
  const Icon = ICON_BY_DOCUMNET_STATUS.REJECTED;
  return (
    <Tag color={COLOR_BY_DOCUMENT_STATUS.REJECTED} icon={<Icon />}>
      {CUSTOMER_DOCUMENT_STATUS_VIEW.REJECTED}
    </Tag>
  );
};

export const getTagByStatus = (status: CUSTOMER_DOCUMENT_STATUS) => {
  let tag = <></>;
  switch (status) {
    case CUSTOMER_DOCUMENT_STATUS.VERIFIED:
      tag = <VerifiedTag />;
      break;
    case CUSTOMER_DOCUMENT_STATUS.PENDING:
      tag = <PendingTag />;
      break;
    case CUSTOMER_DOCUMENT_STATUS.REJECTED:
      tag = <RejectedTag />;
      break;
    case CUSTOMER_DOCUMENT_STATUS.RESUBMITTED:
      tag = <ReSubmittedTag />;
      break;
    case CUSTOMER_DOCUMENT_STATUS.SUBMITTED:
      tag = <SubmittedTag />;
      break;
    default:
      break;
  }
  return tag;
};
