import { IBranch, IBranchesList, IUser, IUsersList } from "store/types";
import { Requester, formatURL } from "api/requester";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { API_END_POINTS } from "api/urls";
import { AxiosResponse } from "axios";

const initialState = {
  branches: {
    details: [] as IBranch[],
    loading: false,
    error: false,
    errorStatus: 0,
  },
  usersList: {
    details: [] as IUser[],
    loading: false,
    error: false,
    errorStatus: 0,
  },
  userById: {
    details: {} as IUser,
    loading: false,
    error: false,
    errorStatus: 0,
  },
};

const fetchBranchesList = createAsyncThunk(
  "branches/list",
  async (payload: any, thunkAPI) => {
    try {
      const response: AxiosResponse = await Requester.get(
        API_END_POINTS.BRANCHES.list
      );
      return response?.data;
    } catch (e: any) {
      return thunkAPI.rejectWithValue({ errorStatus: e?.response?.status });
    }
  }
);

const fetchUsersList = createAsyncThunk(
  "userList/list",
  async (payload: any, thunkAPI) => {
    try {
      const response: AxiosResponse = await Requester.get(
        API_END_POINTS.OPERATORS.users
      );
      return response?.data;
    } catch (e: any) {
      return thunkAPI.rejectWithValue({ errorStatus: e?.response?.status });
    }
  }
);

const fetchUserById = createAsyncThunk(
  "users/userById",
  async (payload: string, thunkAPI) => {
    const url = formatURL(API_END_POINTS.USERS.userById, { userId: payload });
    try {
      const response: AxiosResponse = await Requester.get(url).catch(
        ({ response }) => response
      );
      return response?.data;
    } catch (e: any) {
      return thunkAPI.rejectWithValue({ errorStatus: e?.response?.status });
    }
  }
);

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    resetInitialValues: (state) => {
      state.userById = initialState.userById;
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchBranchesList.rejected, (state, action: any) => {
      state.branches.error = true;
      state.branches.loading = false;
      state.branches.errorStatus = action?.payload?.errorStatus;
    });
    builder.addCase(fetchBranchesList.pending, (state) => {
      state.branches.loading = true;
    });
    builder.addCase(
      fetchBranchesList.fulfilled,
      (state, { payload }: { payload: IBranchesList }) => {
        state.branches.loading = false;
        state.branches.error = false;
        state.branches.details = payload?.items;
      }
    );

    builder.addCase(fetchUsersList.rejected, (state, action: any) => {
      state.usersList.error = true;
      state.usersList.loading = false;
      state.usersList.errorStatus = action?.payload?.errorStatus;
    });
    builder.addCase(fetchUsersList.pending, (state) => {
      state.usersList.loading = true;
    });
    builder.addCase(
      fetchUsersList.fulfilled,
      (state, { payload }: { payload: IUsersList }) => {
        state.usersList.loading = false;
        state.usersList.error = false;
        state.usersList.details = payload?.items;
      }
    );

    builder.addCase(fetchUserById.rejected, (state, action: any) => {
      state.userById.error = true;
      state.userById.loading = false;
      state.userById.errorStatus = action?.payload?.errorStatus;
    });
    builder.addCase(fetchUserById.pending, (state) => {
      state.userById.loading = true;
    });
    builder.addCase(
      fetchUserById.fulfilled,
      (state, { payload }: { payload: IUser }) => {
        state.userById.loading = false;
        state.userById.error = false;
        state.userById.details = payload;
      }
    );
  },
});

export const UsersActions = {
  ...usersSlice.actions,
  fetchBranchesList,
  fetchUserById,
  fetchUsersList,
};

export const UsersReducer = usersSlice.reducer;
