import { Button, Space, Tag, Typography } from "antd";

import { ILoansStatus } from "store/types";
import { ILoansStatus_View } from "store/types";
import { LoansActions } from "store/slices/loansSlice";
import { RootState } from "store/store";
import { SyncOutlined } from "@ant-design/icons";
import { useAppDispatch } from "store/hooks";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const LoanDashboardHeader = () => {
  const { t } = useTranslation();
  const headerComponentFontSize = 40;
  const params = useParams();
  const { details } = useSelector(
    (state: RootState) => state.loans.loanDashboard
  );
  const dispatch = useAppDispatch();
  const handleRefresh = () => {
    dispatch(LoansActions.fetchLoanById(params?.loanId || ""));
  };

  const getLoanStatusTag = (status: ILoansStatus) => {
    switch (status) {
      case ILoansStatus.ACTIVE:
        return <Tag color="green">{ILoansStatus_View.ACTIVE}</Tag>;
      case ILoansStatus.CLOSED:
        return <Tag color="grey">{ILoansStatus_View.CLOSED}</Tag>;
      case ILoansStatus.DEVICE_MAPPING_PENDING:
        return (
          <Tag color="orange">{ILoansStatus_View.DEVICE_MAPPING_PENDING}</Tag>
        );
      case ILoansStatus.DOWN_PAYMENT_PENDING:
        return <Tag color="red">{ILoansStatus_View.DOWN_PAYMENT_PENDING}</Tag>;
      case ILoansStatus.EMI_PENDING:
        return <Tag color="orange">{ILoansStatus_View.EMI_PENDING}</Tag>;
      case ILoansStatus.LICENSE_KEY_PENDING:
        return (
          <Tag color="orange">{ILoansStatus_View.LICENSE_KEY_PENDING}</Tag>
        );
      case ILoansStatus.ASSIGN_DISPATCHER_PENDING:
        return (
          <Tag color="orange">
            {ILoansStatus_View.ASSIGN_DISPATCHER_PENDING}
          </Tag>
        );
      case ILoansStatus.DISPATCH_PENDING:
        return <Tag color="orange">{ILoansStatus_View.DISPATCH_PENDING}</Tag>;
      case ILoansStatus.CHILD_LOAN_PENDING:
        return <Tag color="blue">{ILoansStatus_View.CHILD_LOAN_PENDING}</Tag>;
    }
  };

  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <Typography.Text
        style={{
          fontSize: headerComponentFontSize,
          color: "#104359",
          fontWeight: 600,
        }}
      >
        {t("Order")}{" "}
        <Typography.Text
          style={{
            fontSize: 24,
            lineHeight: 0,
          }}
        >
          {"#" + params?.loanId}
        </Typography.Text>{" "}
        {getLoanStatusTag(details?.status)}
      </Typography.Text>
      <Space>
        <Button onClick={handleRefresh} icon={<SyncOutlined />} />
      </Space>
    </div>
  );
};

export default LoanDashboardHeader;
