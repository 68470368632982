import { Card } from "antd";

import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Meta from "antd/es/card/Meta";

interface IDistributorDashboardProps {
  loading: boolean;
  icon: IconDefinition;
  title: string;
  description: string;
  additionalStyle?: any;
}

const DistributorDashboardCard = (props: IDistributorDashboardProps) => {
  return (
    <Card
      className="distributor-card"
      bodyStyle={{ padding: 15, paddingTop: 20, paddingBottom: 20 }}
      loading={props.loading}
      style={props.additionalStyle ? props.additionalStyle : {}}
    >
      <Meta description="" style={{ margin: 0 }} />
      <div style={{ display: "flex" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <span className="icon-container">
            <FontAwesomeIcon
              icon={props.icon}
              color={"#1692a1"}
              style={{ scale: "1.2" }}
            />
          </span>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "flex-start",
            flex: 1,
            flexDirection: "column",
            marginLeft: 15,
          }}
        >
          <div
            style={{
              fontSize: 14,
              color: "#797272",
              fontWeight: 400,
              lineHeight: "14px",
              marginBottom: 5,
            }}
          >
            {props.title}
          </div>
          <div
            style={{
              fontSize: 30,
              color: "#000",
              fontWeight: 600,
              lineHeight: "30px",
            }}
          >
            {props.description}
          </div>
        </div>
      </div>
    </Card>
  );
};

export default DistributorDashboardCard;
