import {
  CUSTOMER_KYC_STATUS,
  ILoansStatus,
  ILoansStatus_View,
  IProductItem,
} from "store/types";
import {
  CheckCircleFilled,
  CloseCircleFilled,
  InfoCircleOutlined,
  WarningFilled,
} from "@ant-design/icons";
import React, { ReactNode } from "react";
import { Tag, Tooltip, Typography } from "antd";

import { COLORS } from "appConstants";
import HiddenComponent from "components/util-components/HiddenComponent";
import { useTranslation } from "react-i18next";

export const DetailDescriptionItem = (props: {
  label: string;
  children: ReactNode;
  copyable?: boolean;
}) => {
  return (
    <>
      <Typography.Text
        style={{ color: COLORS.LOAN_REQUEST_DASHBOARD.LOAN_REQUEST_TITLE }}
      >
        {props?.label}
      </Typography.Text>
      <Typography.Title
        level={4}
        style={{
          marginTop: 0,
          color: COLORS.LOAN_REQUEST_DASHBOARD.LOAN_REQUEST_VALUES,
          fontSize: 20,
        }}
        copyable={props?.copyable}
      >
        {props?.children}
      </Typography.Title>
    </>
  );
};

export const getCustomerKycStatusIcon = (status: CUSTOMER_KYC_STATUS) => {
  switch (status) {
    case CUSTOMER_KYC_STATUS.COMPLETED:
      return <CheckCircleFilled style={{ color: "green" }} />;
    case CUSTOMER_KYC_STATUS.PENDING:
      return <CloseCircleFilled style={{ color: "red" }} />;
    case CUSTOMER_KYC_STATUS.REJECTED:
      return <CloseCircleFilled style={{ color: "green" }} />;
    case CUSTOMER_KYC_STATUS.RESUBMITTED:
      return <WarningFilled style={{ color: "orange" }} />;
    case CUSTOMER_KYC_STATUS.SUBMITTED:
      return <WarningFilled style={{ color: "green" }} />;
    default:
      break;
  }
};

export const getLoanStatusTagByStatus = (status: ILoansStatus) => {
  switch (status) {
    case ILoansStatus.ACTIVE:
      return <Tag color="green">{ILoansStatus_View.ACTIVE}</Tag>;
    case ILoansStatus.CHILD_LOAN_PENDING:
      return <Tag color="blue">{ILoansStatus_View.CHILD_LOAN_PENDING}</Tag>;
    case ILoansStatus.CLOSED:
      return <Tag>{ILoansStatus_View.CLOSED}</Tag>;
    case ILoansStatus.DEVICE_MAPPING_PENDING:
      return (
        <Tag color="orange">{ILoansStatus_View.DEVICE_MAPPING_PENDING}</Tag>
      );
    case ILoansStatus.DISPATCH_PENDING:
      return <Tag color="orange">{ILoansStatus_View.DISPATCH_PENDING}</Tag>;
    case ILoansStatus.ASSIGN_DISPATCHER_PENDING:
      return (
        <Tag color="orange">{ILoansStatus_View.ASSIGN_DISPATCHER_PENDING}</Tag>
      );
    case ILoansStatus.DOWN_PAYMENT_PENDING:
      return <Tag color="orange">{ILoansStatus_View.DOWN_PAYMENT_PENDING}</Tag>;
    case ILoansStatus.EMI_PENDING:
      return <Tag color="blue">{ILoansStatus_View.EMI_PENDING}</Tag>;
    case ILoansStatus.LICENSE_KEY_PENDING:
      return <Tag color="orange">{ILoansStatus_View.LICENSE_KEY_PENDING}</Tag>;
    default:
      break;
  }
};

export const ShowProductsInTable = (props: { productList: IProductItem[] }) => {
  const { t } = useTranslation();
  const firstPurchasedItem = props?.productList?.at(0);
  return (
    <>
      <Typography.Link
        onClick={() => {
          window.open(`/products/${firstPurchasedItem?.id}/details`, "_blank");
        }}
        style={{ fontWeight: 600 }}
      >
        {firstPurchasedItem?.name}
      </Typography.Link>
      <Typography.Text>{` (Qty : ${firstPurchasedItem?.count})`}</Typography.Text>
      <br />
      <HiddenComponent hidden={props?.productList?.length <= 1}>
        <Typography.Text>
          {t(`+ ${props?.productList?.length - 1} more... `)}
        </Typography.Text>
        <Tooltip
          title={
            <>
              {t("Products : ")}
              <br />
              {props?.productList?.map((item) => {
                return (
                  <React.Fragment key={item?.id}>
                    {item?.name}
                    {`(${item?.count})`}
                    <br />
                  </React.Fragment>
                );
              })}
            </>
          }
        >
          <InfoCircleOutlined />
        </Tooltip>
      </HiddenComponent>
    </>
  );
};
