import { Button, Table, Tag, Typography } from "antd";
import { IDistributorFeeTransaction, ILoansStatus } from "store/types";

import { CheckCircleFilled } from "@ant-design/icons";
import { ColumnsType } from "antd/es/table";
import LoanDashboardAddDistributorFeeForm from "components/loanDashboard/loan.dashboard.addDistributorFeeForm";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import { momentDateTimeFormat } from "components/util-components/dateTimeFormattor";

const LoanDashboardDistributorDetails = () => {
  console.log("loan distributor details loaded");
  const { t } = useTranslation();
  const [isAddDistributorFeeModalOpen, setisAddDistributorFeeModalOpen] =
    useState(false);

  const { loanDashboard } = useSelector((state: RootState) => state?.loans);

  const columns: ColumnsType<IDistributorFeeTransaction> = [
    {
      title: t("Transaction Id"),
      key: "transactionId",
      render: (_, record) => {
        return <>{record?.transactionId}</>;
      },
    },
    {
      title: t("Paid On"),
      key: "paidOn",
      render: (_, record) => {
        return <>{`${momentDateTimeFormat(record?.paidOn)}`}</>;
      },
    },
  ];
  return (
    <section>
      <Typography.Title level={3} style={{ color: "#104359" }}>
        {t("Distributor Fee Details")}
        {loanDashboard?.details.distributorFeePaid ? (
          <Tag
            style={{ marginLeft: 15, fontWeight: 100 }}
            icon={<CheckCircleFilled />}
            color="green"
          >
            {t("Paid")}
          </Tag>
        ) : (
          <Button
            type="primary"
            style={{ marginLeft: 10 }}
            icon={<CheckCircleFilled />}
            onClick={() => {
              console.log("clicked");
              setisAddDistributorFeeModalOpen(true);
            }}
            disabled={
              loanDashboard?.details.status === ILoansStatus.ACTIVE
                ? false
                : true
            }
            //   loading={isSubmitLoading}
          >
            {t("Mark Distributor Payment")}
          </Button>
        )}
      </Typography.Title>
      <Table
        bordered
        columns={columns}
        dataSource={loanDashboard?.details.distributorFeeTransactions}
        pagination={false}
        loading={loanDashboard?.loading}
      />
      <LoanDashboardAddDistributorFeeForm
        open={isAddDistributorFeeModalOpen}
        close={() => setisAddDistributorFeeModalOpen(false)}
        fee={loanDashboard?.details.distributorFee || 0}
      />
    </section>
  );
};

export default LoanDashboardDistributorDetails;
