import { Alert, AlertProps } from "antd";

import { ILoansStatus } from "store/types";
import { RootState } from "store/store";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const LoanDashboardStatusAlert = () => {
  const { t } = useTranslation();
  const { details } = useSelector(
    (state: RootState) => state?.loans?.loanDashboard
  );

  const alertStylesByStatus = {
    [ILoansStatus.DOWN_PAYMENT_PENDING]: {
      description: t(
        "The customer has not yet made the required down payment."
      ),
      type: "error",
    },
    [ILoansStatus.EMI_PENDING]: {
      description: t("The customer has not yet paid the EMI."),
      type: "error",
    },
    [ILoansStatus.LICENSE_KEY_PENDING]: {
      description: t("License key has not been mapped yet"),
      type: "error",
    },
    [ILoansStatus.ASSIGN_DISPATCHER_PENDING]: {
      description: t("Dispatcher has not been assigned yet"),
      type: "error",
    },
    [ILoansStatus.CHILD_LOAN_PENDING]: {
      description: t("Child Order has not been completed yet"),
      type: "info",
    },
  };

  const currentStatus =
    alertStylesByStatus[details?.status as keyof typeof alertStylesByStatus];
  return (
    <Alert
      className="loan-dashboard-status-alert"
      type={currentStatus?.type as AlertProps["type"]}
      description={<>{currentStatus?.description}</>}
    />
  );
};

export default LoanDashboardStatusAlert;
