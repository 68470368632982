import { Modal, Statistic } from "antd";

import { COLORS } from "appConstants";
import { ROLES_VIEW } from "store/types";
import { RootState } from "store/store";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { valueType } from "antd/es/statistic/utils";

const ProfileCard = (props: { open: boolean; close: () => void }) => {
  const { t } = useTranslation();
  const valueStyle = {
    marginTop: -8,
    color: COLORS.THEME_COLORS.primary,
    fontWeight: "bold",
  };
  const { userInfo } = useSelector((state: RootState) => state?.auth);
  return (
    <Modal
      open={props?.open}
      maskClosable={false}
      onCancel={props?.close}
      footer={null}
      title={t("User Details")}
    >
      <Statistic
        title={t("Name")}
        value={userInfo?.firstName + " " + userInfo?.lastName}
        valueStyle={valueStyle}
      />
      <br />
      <Statistic
        title={t("Phone")}
        value={userInfo?.phoneNumber || "-"}
        valueStyle={valueStyle}
        formatter={(value: valueType) => <>{value}</>}
      />
      <br />
      <Statistic
        title={t("Email")}
        value={userInfo?.emailId || "-"}
        valueStyle={valueStyle}
      />
      <br />
      <Statistic
        title={t("Role")}
        value={ROLES_VIEW[userInfo?.role]}
        valueStyle={valueStyle}
      />
    </Modal>
  );
};

export default ProfileCard;
