import { Collapse, Table, Typography } from "antd";
import { EmiStatus, ILoan } from "store/types";

import { ColumnsType } from "antd/es/table";
import { IndianRuppeeView } from "components/products/product.util.components";
import { NOT_APPLICABLE } from "appConstants";
import { RootState } from "store/store";
import { momentDateTimeFormat } from "components/util-components/dateTimeFormattor";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const LoansRequestDashboardActiveLoans = () => {
  const { t } = useTranslation();
  const { customerLoansDetails } = useSelector(
    (state: RootState) => state.loans.loansRequestDashboard
  );

  const columns: ColumnsType<ILoan> = [
    {
      title: t("Order Id"),
      key: "loanId",
      render: (_, record) => {
        return <Typography.Text copyable>{record?.id}</Typography.Text>;
      },
    },
    {
      title: t("Order Amount"),
      key: "loanAmount",
      render: (_, record) => {
        return (
          <IndianRuppeeView value={record?.loanAmount?.toString() || ""} />
        );
      },
    },
    {
      title: t("Tenure"),
      key: "tenure",
      render: (_, record) => {
        return <>{record?.emis?.length + " months" || NOT_APPLICABLE}</>;
      },
    },
    {
      title: t("Downpayment"),
      key: "downPayment",
      render: (_, record) => {
        return (
          <IndianRuppeeView value={record?.downPayment?.toString() || ""} />
        );
      },
    },
    {
      title: t("Processing Fee"),
      key: "processingFee",
      render: (_, record) => {
        return <IndianRuppeeView value={record?.processingFee?.toString()} />;
      },
    },
    {
      title: t("Order Start Date"),
      key: "loanStartDate",
      render: (_, record) => {
        return <>{momentDateTimeFormat(record?.approveTime, "date")}</>;
      },
    },
    {
      title: t("Order End Date"),
      key: "loanEndDate",
      render: (_, record) => {
        return <>{momentDateTimeFormat(record?.endDate, "date")}</>;
      },
    },
    {
      title: t("EMIs Paid"),
      key: "emisPaid",
      render: (_, record) => {
        const emisPaidCount = record?.emis?.filter((emi) => {
          return emi?.status === EmiStatus.PAID;
        })?.length;
        return <>{`${emisPaidCount} / ${record?.emis?.length}`}</>;
      },
    },
  ];
  return (
    <>
      <Collapse size="large">
        <Collapse.Panel
          header={
            <Typography.Text
              style={{ fontSize: 20, color: "#104359", fontWeight: 600 }}
            >
              {`${t("Active Order Details")} (${
                customerLoansDetails?.details?.activeLoans?.length
              })`}
            </Typography.Text>
          }
          key="1"
        >
          <Table
            bordered
            columns={columns}
            dataSource={customerLoansDetails?.details?.activeLoans}
            pagination={false}
            loading={customerLoansDetails?.loading}
          />
        </Collapse.Panel>
      </Collapse>
    </>
  );
};

export default LoansRequestDashboardActiveLoans;
