import {
  Button,
  Form,
  Modal,
  Space,
  Tag,
  Typography,
  Upload,
  UploadFile,
  message,
} from "antd";
import {
  CheckCircleFilled,
  CloseCircleFilled,
  WarningFilled,
} from "@ant-design/icons";
import { Requester, formatURL } from "api/requester";

import { API_END_POINTS } from "api/urls";
import { IManagedAsset } from "store/types";
import { LoansActions } from "store/slices/loansSlice";
import { useAppDispatch } from "store/hooks";
import { useForm } from "antd/es/form/Form";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export enum LicenseKeyModes {
  ADD = "add",
  UPDATE = "update",
}

interface ILoanDashboardLicenseKeyFormProps {
  open: boolean;
  close: () => void;
  record: IManagedAsset;
  mode: LicenseKeyModes;
}

type MacMatchState = "pending" | "match" | "misMatch";
const LoanDashboardLicenseKeyForm = (
  props: ILoanDashboardLicenseKeyFormProps
) => {
  const { t } = useTranslation();
  const [macMatchStatus, setMacMatchStatus] =
    useState<MacMatchState>("pending");
  const [form] = useForm();
  const [isImageTypeCorrect, setIsImageTypeCorrect] = useState(true);
  const dispatch = useAppDispatch();
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);

  const handleModalClose = () => {
    props?.close();
    form.resetFields();
    setMacMatchStatus("pending");
  };

  const handleSubmit = async (formValues: any) => {
    setIsSubmitLoading(true);
    const url = formatURL(API_END_POINTS.LOANS.licenseKeys, {
      loanId: props?.record?.loanId,
    });
    const formData = new FormData();

    const files = formValues?.files?.fileList?.map((file: any) => {
      return file?.originFileObj;
    });
    formData.append("assetId", props?.record?.id);
    files?.forEach((image: any) => {
      formData.append("image", image);
    });
    const response = await Requester.post(url, formData).catch(
      ({ response }) => response
    );
    if (response?.status === 204) {
      message.success(t("Successfully added"));
      props?.close();
      dispatch(LoansActions.fetchLoanById(props?.record?.loanId || ""));
    } else {
      message.error(t("Error while adding License Key"));
    }
    setIsSubmitLoading(false);
  };

  const handleUpdate = async (formValues: any) => {
    setIsSubmitLoading(true);
    const url = formatURL(API_END_POINTS.LOANS.licenseKeys, {
      loanId: props?.record?.loanId,
    });
    const formData = new FormData();

    const files = formValues?.files?.fileList?.map((file: any) => {
      return file?.originFileObj;
    });
    formData.append("assetId", props?.record?.id);
    files?.forEach((image: any) => {
      formData.append("image", image);
    });
    const response = await Requester.put(url, formData).catch(
      ({ response }) => response
    );
    if (response?.status === 204) {
      message.success(t("Successfully added"));
      props?.close();
      dispatch(LoansActions.fetchLoanById(props?.record?.loanId || ""));
    } else {
      message.error(t("Error while adding License Key"));
    }
    setIsSubmitLoading(false);
  };

  const getTagByMacMatchStatus = (status: MacMatchState) => {
    switch (status) {
      case "pending":
        return (
          <Tag icon={<WarningFilled />} color="yellow">
            {t("Upload Pending")}
          </Tag>
        );
      case "match":
        return (
          <Tag icon={<CheckCircleFilled />} color="green">
            {t("Match")}
          </Tag>
        );
      case "misMatch":
        return (
          <Tag icon={<CloseCircleFilled />} color="red">
            {t("Mis-Match")}
          </Tag>
        );
      default:
    }
  };

  const verifyMac = (files: UploadFile<any>[]) => {
    const filesBlob = files?.map((file: any) => {
      return file?.originFileObj;
    });
    const reader = new FileReader();
    reader.onload = (e) => {
      const text: string = e?.target?.result?.toString() || "";
      const mac = text
        ?.split("\n")
        ?.at(0)
        ?.split("mac:")
        ?.at(1)
        ?.trim()
        ?.split("\r")
        ?.at(0);
      if (props?.record?.mac.replaceAll(":", "") === mac?.replaceAll(":", "")) {
        setMacMatchStatus("match");
      } else {
        setMacMatchStatus("misMatch");
      }
    };
    reader.readAsText(filesBlob[0]);
  };

  return (
    <Modal
      open={props?.open}
      maskClosable={false}
      onCancel={handleModalClose}
      title={
        props.mode === LicenseKeyModes.UPDATE
          ? t("Update License Key")
          : t("Add License Key")
      }
      footer={[
        <Button
          onClick={form.submit}
          disabled={macMatchStatus !== "match"}
          loading={isSubmitLoading}
          type="primary"
        >
          {t("Submit")}
        </Button>,
      ]}
      width={600}
    >
      <Form
        onFinish={
          props.mode === LicenseKeyModes.ADD ? handleSubmit : handleUpdate
        }
        form={form}
        layout="vertical"
      >
        <Space>
          <Typography.Text strong>{t("Status - ")}</Typography.Text>
          {getTagByMacMatchStatus(macMatchStatus)}
        </Space>
        <br />
        <br />
        <Form.Item
          rules={[
            {
              required: true,
              message: t("Please upload License key txt file"),
            },
            {
              validator() {
                if (!isImageTypeCorrect) {
                  return Promise.reject(t("File type not supported"));
                }
                return Promise.resolve();
              },
            },
          ]}
          name={"files"}
        >
          <Upload
            beforeUpload={(file) => {
              const isProperFile = ["text/plain"].includes(file?.type);
              if (!isProperFile) {
                setIsImageTypeCorrect(false);
              } else {
                setIsImageTypeCorrect(true);
              }
              return false;
            }}
            onChange={(info) => {
              const fileList = info?.fileList;
              if (fileList?.length > 0) {
                verifyMac(fileList);
              } else {
                setMacMatchStatus("pending");
              }
            }}
            maxCount={1}
          >
            <Button>{t("Upload License key txt file")}</Button>
          </Upload>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default LoanDashboardLicenseKeyForm;
