import LoanDashboardProductDetails, {
  LOAN_PRODUCT_ACTIONS,
} from './loan.dashboard.productDetails';

import { IEmiDetail } from 'store/types';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';

interface ILoanDashboardSendLicenseFormProps {
  openModal: boolean;
  closeModal: () => void;
  record: IEmiDetail;
}

const LoanDashboardSendLicenseForm = (
  props: ILoanDashboardSendLicenseFormProps
) => {
  const { t } = useTranslation();

  return (
    <Modal
      open={props?.openModal}
      maskClosable={false}
      width={'90%'}
      onCancel={props?.closeModal}
      title={t('labels.loans.dashboard.sendLicense')}
      footer={null}
      destroyOnClose
    >
      <LoanDashboardProductDetails
        actionType={LOAN_PRODUCT_ACTIONS.SEND_LICENSE}
        emiRecord={props?.record}
      />
    </Modal>
  );
};

export default LoanDashboardSendLicenseForm;
