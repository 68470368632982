import { Tag, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "store/store";

const CustomerDashboardHeader = () => {
  const { t } = useTranslation();
  const headerComponentFontSize = 40;

  const { customerDetails } = useSelector(
    (state: RootState) => state?.customers?.customerProfile
  );

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
      }}
    >
      <Typography.Text
        style={{
          fontSize: headerComponentFontSize,
          color: "#104359",
          fontWeight: 600,
        }}
      >
        {t(
          `${customerDetails.firstName ? customerDetails.firstName : ""} ${
            customerDetails.lastName ? `${customerDetails.lastName}'s` : ""
          } Dashboard`
        )}
        {customerDetails.vip && (
          <Tag style={{ marginLeft: "5px" }} color="gold">
            {"VIP"}
          </Tag>
        )}
      </Typography.Text>
      <div>
        {/* <Space>
          <Button>{t("Disable Customer")}</Button>
          <Button>{t("Send EMI reminder")}</Button>
        </Space> */}
      </div>
    </div>
  );
};

export default CustomerDashboardHeader;
