import { Requester, formatURL } from "api/requester";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { API_END_POINTS } from "api/urls";
import { AxiosResponse } from "axios";
import { IProduct } from "store/types";

const fetchProductsList = createAsyncThunk(
  "products/fetchList",
  async (payload: any, thunkAPI) => {
    try {
      const response: AxiosResponse = await Requester.get(
        API_END_POINTS.PRODUCTS.list,
        {
          params: payload?.requestParams,
        }
      );
      return response?.data;
    } catch (e: any) {
      return thunkAPI.rejectWithValue({ errorStatus: e?.response?.status });
    }
  }
);

const fetchProductsDetailById = createAsyncThunk(
  "products/fetchById",
  async (payload: any, thunkAPI) => {
    try {
      const response: AxiosResponse = await Requester.get(
        formatURL(API_END_POINTS.PRODUCTS.productById, {
          productId: payload,
        })
      );
      return response?.data;
    } catch (e: any) {
      return thunkAPI.rejectWithValue({ errorStatus: e?.response?.status });
    }
  }
);

const initialState = {
  productsTable: {
    productsList: [] as IProduct[],
    loading: false,
    error: false,
    errorStatus: 0,
  },
  productById: {
    details: {} as IProduct,
    loading: false,
    error: false,
    errorStatus: 0,
  },
};

export const productsSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    deleteImageLinks: (state, { payload }) => {
      let imageLinks = state.productById.details?.imageLinks?.filter((link) => {
        if (link === payload) {
          return false;
        }
        return true;
      });
      state.productById.details.imageLinks = imageLinks;
    },
    resetState: (state) => {
      state.productById = initialState.productById;
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchProductsList.pending, (state) => {
      state.productsTable.loading = true;
    });
    builder.addCase(fetchProductsList.rejected, (state, action: any) => {
      state.productsTable.loading = false;
      state.productsTable.error = true;
      state.productsTable.errorStatus = action.payload?.errorStatus;
    });
    builder.addCase(fetchProductsList.fulfilled, (state, { payload }) => {
      state.productsTable.loading = false;
      state.productsTable.error = false;
      state.productsTable.productsList = payload?.items;
    });

    builder.addCase(fetchProductsDetailById.pending, (state) => {
      state.productById.loading = true;
    });
    builder.addCase(fetchProductsDetailById.rejected, (state, action: any) => {
      state.productById.loading = false;
      state.productById.error = true;
      state.productById.errorStatus = action.payload?.errorStatus;
    });
    builder.addCase(fetchProductsDetailById.fulfilled, (state, { payload }) => {
      state.productById.loading = false;
      state.productById.error = false;
      state.productById.details = payload;
    });
  },
});

export const ProductsActions = {
  ...productsSlice.actions,
  fetchProductsList,
  fetchProductsDetailById,
};

export const ProductReducer = productsSlice.reducer;
