import { Button, Col, Row, Space, Typography } from "antd";
import RestrictedComponent, {
  PERMISSIONS,
} from "components/util-components/RestrictedComponent";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PlusOutlined } from "@ant-design/icons";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const UsersHeader = () => {
  const { t } = useTranslation();
  const headerComponentFontSize = 24;
  const navigate = useNavigate();
  return (
    <Row justify={"space-between"} style={{ width: "100%" }}>
      <Col>
        <Space align="baseline">
          <Typography.Text style={{ fontSize: headerComponentFontSize }}>
            <FontAwesomeIcon
              icon={faUser}
              color={"inherit"}
              fontSize={headerComponentFontSize}
            />{" "}
            {t("labels.users.title")}
          </Typography.Text>
        </Space>
      </Col>
      <Col>
        <RestrictedComponent permission={PERMISSIONS.USERS.create}>
          <Button
            type={"primary"}
            onClick={() => navigate("new")}
            icon={<PlusOutlined />}
          >
            {t("labels.users.createUser")}
          </Button>
        </RestrictedComponent>
      </Col>
    </Row>
  );
};

export default UsersHeader;
