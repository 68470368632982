import { Button, Col, Drawer, Row, Tag, Typography } from "antd";
import Table, { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";

import ErrorRenderer from "components/util-components/ErrorRenderer";
import HiddenComponent from "components/util-components/HiddenComponent";
import { IManagedAsset } from "store/types";
import Loader from "components/util-components/Loader";
import { LoansActions } from "store/slices/loansSlice";
import { RootState } from "store/store";
import { SyncOutlined } from "@ant-design/icons";
import { momentDateTimeFormat } from "components/util-components/dateTimeFormattor";
import { useAppDispatch } from "store/hooks";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const LoanDashboardLicenseKeyTable = (props: {
  record: IManagedAsset;
  open: boolean;
  close: () => void;
}) => {
  const { loans } = useSelector((state: RootState) => state);
  const { details, loading, error } = loans.assetLicenseKey;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [licenseKeys, setLicenseKeys] = useState([] as any);

  useEffect(() => {
    if (details.licenseKeys) {
      let licenseKeyData: any = [];
      details.licenseKeys.forEach((licenseKey: any) => {
        let index = Object.keys(licenseKey)[0];
        licenseKeyData.push({
          index: index,
          value: licenseKey[index],
        });
      });
      setLicenseKeys(licenseKeyData);
    }
  }, [details]);

  const handleRefresh = () => {
    dispatch(
      LoansActions.fetchLicenseKeysByAssetId(
        loans?.loanDashboard?.selectedAssetId
      )
    );
  };

  const columns: ColumnsType<{ index: any; value: any }> = [
    {
      title: t("Id"),
      key: "index",
      dataIndex: "index",
    },
    {
      title: t("Key"),
      key: "key",
      render: (_, record) => {
        return (
          <>
            <Typography.Text>{record?.value}</Typography.Text>
            {record?.value === details.lastDispatchedKey ? (
              <Tag color={"green"}>{`Dispatched on   ${momentDateTimeFormat(
                details.lastDispatchedTime,
                "dateTime"
              )}`}</Tag>
            ) : (
              <></>
            )}
          </>
        );
      },
    },
  ];

  return (
    <Drawer
      open={props?.open}
      onClose={props?.close}
      width={1000}
      maskClosable={false}
      title={
        <Row justify={"space-between"} align={"middle"}>
          <Col>{t("License Keys")} </Col>
          <Col>
            <Button onClick={handleRefresh} icon={<SyncOutlined />} />
          </Col>
        </Row>
      }
    >
      <HiddenComponent hidden={loading} fallBack={<Loader />}>
        <HiddenComponent hidden={error} fallBack={<ErrorRenderer />}>
          <Table
            bordered
            columns={columns}
            rowKey={(data) => data?.index}
            dataSource={licenseKeys}
            pagination={false}
            loading={loading}
          />
        </HiddenComponent>
      </HiddenComponent>
    </Drawer>
  );
};

export default LoanDashboardLicenseKeyTable;
