import UsersHeader from "components/users/users.header";
import UsersList from "components/users/users.list";

const UsersScreen = () => {
  return (
    <>
      <UsersHeader />
      <br />
      <UsersList />
    </>
  );
};

export default UsersScreen;
