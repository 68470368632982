import "styles/Products.less";

import { Col, Drawer, Image, Row, Space, Tooltip, Typography } from "antd";
import {
  DetailDescriptionItem,
  IndianRuppeNumberFormattor,
} from "./product.util.components";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";

import ErrorRenderer from "components/util-components/ErrorRenderer";
import { FALL_BACK_IMAGE } from "appConstants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import HiddenComponent from "components/util-components/HiddenComponent";
import { InfoCircleOutlined } from "@ant-design/icons";
import Loader from "components/util-components/Loader";
import { ProductsActions } from "store/slices/productsSlice";
import { RootState } from "store/store";
import { faIndianRupeeSign } from "@fortawesome/free-solid-svg-icons";
import { useAppDispatch } from "store/hooks";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { wrapWithAuthToken } from "components/util-components/utilFunctions";

const ProductsDetails = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const params = useParams();
  const { productById } = useSelector((state: RootState) => state?.products);
  const productDetails = productById?.details;
  const { returnTo }: any = useOutletContext();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(ProductsActions.fetchProductsDetailById(params?.productId));
    return () => {
      dispatch(ProductsActions.resetState());
    };
  }, []);

  return (
    <Drawer
      open={true}
      size={"large"}
      onClose={() => {
        navigate(returnTo);
      }}
      maskClosable={false}
      title={
        <HiddenComponent hidden={productById.loading} fallBack={<Loader />}>
          <div>
            <span>{`${productDetails.name}'s Details`}</span>
            <Typography.Link
              underline
              onClick={() => {
                navigate(`/products/${productDetails.id}/edit`);
              }}
              style={{ float: "right" }}
            >
              {t("Edit")}
            </Typography.Link>
          </div>
        </HiddenComponent>
      }
    >
      <HiddenComponent hidden={productById?.loading} fallBack={<Loader />}>
        <HiddenComponent
          hidden={productById?.error}
          fallBack={<ErrorRenderer status={productById?.errorStatus} />}
        >
          <Row>
            <Col span={12}>
              <DetailDescriptionItem label={t("Product Id")}>
                {productDetails?.id || "-"}
              </DetailDescriptionItem>
            </Col>
            <Col span={12}>
              <DetailDescriptionItem label={t("Name")}>
                {productDetails?.name || "-"}
              </DetailDescriptionItem>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <DetailDescriptionItem label={t("Group")}>
                {productDetails?.group || "-"}
              </DetailDescriptionItem>
            </Col>
            <Col span={12}>
              <DetailDescriptionItem
                label={
                  <>
                    {t("Is Managed")}{" "}
                    <Tooltip
                      title={t("Managed devices require License to function")}
                    >
                      <InfoCircleOutlined />
                    </Tooltip>
                  </>
                }
              >
                {productDetails?.isPrimary ? "Yes" : "No" || "-"}{" "}
              </DetailDescriptionItem>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <>
                <Typography.Text>{t("Price")}</Typography.Text>
                <br />
                <Typography.Title level={4}>
                  <FontAwesomeIcon icon={faIndianRupeeSign} /> {""}
                  {IndianRuppeNumberFormattor(
                    productDetails?.retailPrice?.toString()
                  )}
                </Typography.Title>
              </>
            </Col>
            <Col span={12}>
              <>
                <Typography.Text>{t("Product link")}</Typography.Text>
                <br />
                <Typography.Link
                  href={productDetails?.productLink}
                  target={"_blank"}
                >
                  {productDetails?.productLink || "-"}
                </Typography.Link>
              </>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <>
                <Typography.Text>{t("Down Payment")}</Typography.Text>
                <br />
                <Typography.Title level={4}>
                  <FontAwesomeIcon icon={faIndianRupeeSign} /> {""}
                  {IndianRuppeNumberFormattor(
                    productDetails?.emiOptions?.at(0)?.downPayment?.toString()
                  )}
                </Typography.Title>
              </>
            </Col>
            <Col span={12}>
              <>
                <Typography.Text>{t("Processing Fees")}</Typography.Text>
                <br />
                <Typography.Title level={4}>
                  <FontAwesomeIcon icon={faIndianRupeeSign} /> {""}
                  {IndianRuppeNumberFormattor(
                    productDetails?.emiOptions?.at(0)?.processingFee?.toString()
                  )}
                </Typography.Title>
              </>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Typography.Text>{t("Images")}</Typography.Text>
              <br />
              <Image.PreviewGroup>
                <Space>
                  {productDetails?.imageLinks?.map((link) => {
                    return (
                      <Image
                        key={link}
                        width={100}
                        src={wrapWithAuthToken(link)}
                        fallback={FALL_BACK_IMAGE}
                      />
                    );
                  })}
                </Space>
              </Image.PreviewGroup>
            </Col>
          </Row>
        </HiddenComponent>
      </HiddenComponent>
    </Drawer>
  );
};

export default ProductsDetails;
