import RestrictedComponent, {
  PERMISSIONS,
} from "components/util-components/RestrictedComponent";

import CustomerHeader from "components/customers/customers.header";
import CustomersList from "components/customers/customers.list";

const CustomersScreen = () => {
  return (
    <RestrictedComponent permission={PERMISSIONS.CUSTOMERS.viewTabs} withAlert>
      <CustomerHeader />
      <CustomersList />
    </RestrictedComponent>
  );
};

export default CustomersScreen;
