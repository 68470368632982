import { Input } from "antd";
import { useTranslation } from "react-i18next";

interface NumericInputProps {
  style?: React.CSSProperties;
  value: string;
  placeholder?: string;
  maxLength?: number;
  onChange: (value: string) => void;
}

// const formatNumber = (value: number) => new Intl.NumberFormat().format(value);

const NumericInput = (props: NumericInputProps) => {
  const { value, onChange } = props;
  const { t } = useTranslation();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value: inputValue } = e.target;
    const reg = /^-?\d*(\.\d*)?$/;
    if (reg.test(inputValue) || inputValue === "" || inputValue === "-") {
      onChange(inputValue);
    }
  };

  // '.' at the end or only '-' in the input box.
  const handleBlur = () => {
    let valueTemp = value;
    if (value.charAt(value.length - 1) === "." || value === "-") {
      valueTemp = value.slice(0, -1);
    }
    onChange(valueTemp.replace(/0*(\d+)/, "$1"));
  };

  // const title = value ? (
  //   <span className='numeric-input-title'>
  //     {value !== '-' ? formatNumber(Number(value)) : '-'}
  //   </span>
  // ) : (
  //   'Input a number'
  // );

  return (
    //   <Tooltip trigger={['focus']} title={title} placement="topLeft" overlayClassName="numeric-input">
    <Input
      {...props}
      onChange={handleChange}
      onBlur={handleBlur}
      placeholder={props?.placeholder || t("placeholders.numberInput")}
      maxLength={props?.maxLength}
    />
    //   </Tooltip>
  );
};

export default NumericInput;
