import { EmiStatus, ILoan } from "store/types";
import { Table, Typography } from "antd";

import { ColumnsType } from "antd/es/table";
import { IndianRuppeeView } from "components/products/product.util.components";
import { LoansActions } from "store/slices/loansSlice";
import { RootState } from "store/store";
import { getLoanStatusTagByStatus } from "components/loans/loans.util-components";
import { momentDateTimeFormat } from "components/util-components/dateTimeFormattor";
import { useAppDispatch } from "store/hooks";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const LoanDashboardChildLoanDetails = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const params = useParams();
  const { childLoanDetails } = useSelector((state: RootState) => state?.loans);

  useEffect(() => {
    dispatch(LoansActions.fetchChildLoansByParentLoanId(params?.loanId || ""));
  }, []);
  const columns: ColumnsType<ILoan> = [
    {
      title: t("Order Id"),
      key: "orderId",
      render: (_, record) => {
        return (
          <Typography.Link
            onClick={() => {
              window.open(`/orders/details/${record?.id}`, "_blank");
            }}
          >
            {record?.id}
          </Typography.Link>
        );
      },
    },
    {
      title: t("Order Amount"),
      key: "orderAmount",
      render: (_, record) => {
        return <IndianRuppeeView value={record?.loanAmount?.toString()} />;
      },
    },
    {
      title: t("Tenure"),
      key: "tenure",
      render: (_, record) => {
        return <>{`${record?.tenure} months`}</>;
      },
    },
    {
      title: t("Order Start Date"),
      key: "orderStartDate",
      render: (_, record) => {
        return <>{momentDateTimeFormat(record?.approveTime, "date")}</>;
      },
    },
    {
      title: t("Order End Date"),
      key: "orderEndDate",
      render: (_, record) => {
        return <>{momentDateTimeFormat(record?.endDate, "date")}</>;
      },
    },
    {
      title: t("EMIs Paid"),
      key: "emiPaid",
      render: (_, record) => {
        const emisPaidCount = record?.emis?.filter((emi) => {
          return emi?.status === EmiStatus.PAID;
        })?.length;
        return <>{`${emisPaidCount} / ${record?.emis?.length}`}</>;
      },
    },
    {
      title: t("Status"),
      key: "status",
      render: (_, record) => {
        return <>{getLoanStatusTagByStatus(record?.status)}</>;
      },
    },
  ];
  return (
    <section>
      <Typography.Title level={3} style={{ color: "#104359" }}>
        {t("Child Loan Details")}
      </Typography.Title>
      <Table
        bordered
        columns={columns}
        dataSource={childLoanDetails?.details}
        pagination={false}
        loading={childLoanDetails?.loading}
      />
    </section>
  );
};

export default LoanDashboardChildLoanDetails;
