import RestrictedComponent, {
  PERMISSIONS,
} from "components/util-components/RestrictedComponent";

import LoansRequestHeader from "components/loansRequest/loans.request.header";
import LoansRequestList from "components/loansRequest/loans.request.list";

const LoanRequestScreen = () => {
  return (
    <RestrictedComponent
      permission={PERMISSIONS.LOAN_REQUESTS.viewTabs}
      withAlert
    >
      <LoansRequestHeader />
      <LoansRequestList />
    </RestrictedComponent>
  );
};

export default LoanRequestScreen;
