import "styles/Sidebar.less";

import { Divider, Layout, Menu, MenuProps, message } from "antd";
import { ReactElement, useState } from "react";
import {
  faCircleUser,
  faCube,
  faMoneyBill,
  faRightFromBracket,
  faUser,
  faUsers,
  faHandshake,
  faHome,
} from "@fortawesome/free-solid-svg-icons";
import { useLocation, useNavigate } from "react-router-dom";

import { API_END_POINTS } from "api/urls";
import { AuthActions } from "store/slices/authSlice";
import { AxiosResponse } from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactComponent as Logo } from "../../assets/images/logo_New.svg";
import ProfileCard from "./Profile.card";
import { ROLES } from "store/types";
import { Requester } from "api/requester";
import { RootState } from "store/store";
import { resetSecurityToken } from "store/sessionStore";
import { useAppDispatch } from "store/hooks";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

interface IMenuItem {
  path: string;
  title: string;
  icon: ReactElement<any, any>;
  onClick?: () => void;
  onlyFor?: ROLES[];
  children?: any[];
}

type MenuItem = Required<MenuProps>["items"][number];

const SideBar = () => {
  const { auth } = useSelector((state: RootState) => state);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const SIDEBAR_WIDTH_COLLAPSED = 62;
  const SIDEBAR_WIDTH_EXPANDED = 200;
  const location = useLocation();
  const [isSideBarCollapsed, setIsSideBarCollapsed] = useState(true);
  const [isProfileCardOpen, setIsProfileCardOpen] = useState(false);

  const doLogout = async () => {
    const hide = message.loading(t("Signing out..."), 0);
    const response: AxiosResponse = await Requester.post(
      API_END_POINTS.AUTH.logout
    ).catch(({ response }) => response);
    if (response?.status === 204) {
      resetSecurityToken();
      dispatch(AuthActions.destroySession());
      navigate("/", { replace: false });
    } else {
      message.error(t("Error in signing out..."), 5);
    }
    hide();
  };

  function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    onClick?: () => void,
    children?: MenuItem[]
  ): MenuItem {
    return {
      key,
      icon,
      children,
      label,
      onClick,
    } as MenuItem;
  }

  const MENU_ITEMS: IMenuItem[] = [
    {
      path: "/dashboard",
      title: t("Home"),
      icon: (
        <FontAwesomeIcon
          icon={faHome}
          color={"inherit"}
          style={{ scale: "1.2" }}
        />
      ),
      onlyFor: [ROLES.DISTRIBUTOR_ADMIN],
      onClick: () => {
        navigate(`/distributors/${auth.userInfo.distributorId}/dashboard`);
      },
    },
    {
      path: "/distributors",
      title: t("labels.distributors.title"),
      icon: (
        <FontAwesomeIcon
          icon={faHandshake}
          color={"inherit"}
          style={{ scale: "1.2" }}
        />
      ),
      onlyFor: [ROLES.ADMIN, ROLES.VIEW_ADMIN],
      onClick: () => {
        navigate("/distributors");
      },
    },
    {
      path: "/customers/list",
      title: t("labels.customers.title"),
      icon: (
        <FontAwesomeIcon
          icon={faUsers}
          color={"inherit"}
          style={{ scale: "1.2" }}
        />
      ),
      onlyFor: [ROLES.ADMIN, ROLES.CREDIT_APPROVER, ROLES.VIEW_ADMIN],
      onClick: () => {
        navigate("/customers/list");
      },
    },
    {
      path: "/users",
      title: t("labels.users.title"),
      icon: (
        <FontAwesomeIcon
          icon={faUser}
          color={"inherit"}
          style={{ scale: "1.2" }}
        />
      ),
      onlyFor: [ROLES.ADMIN, ROLES.VIEW_ADMIN],
      onClick: () => {
        navigate("/users");
      },
    },
    {
      path: "/products",
      title: t("labels.products.title"),
      icon: (
        <FontAwesomeIcon
          icon={faCube}
          color={"inherit"}
          style={{ scale: "1.2" }}
        />
      ),
      onlyFor: [
        ROLES.ADMIN,
        ROLES.CREDIT_APPROVER,
        ROLES.DELIVERY_MANAGER,
        ROLES.LICENSE_KEY_MANAGER,
        ROLES.VIEW_ADMIN,
      ],
      onClick: () => {
        navigate("/products");
      },
    },
    {
      path: "/orders/list",
      title: t("Orders"),
      icon: (
        <FontAwesomeIcon
          icon={faMoneyBill}
          color={"inherit"}
          style={{ scale: "1.2" }}
        />
      ),
      onlyFor: [
        ROLES.ADMIN,
        ROLES.CREDIT_APPROVER,
        ROLES.DELIVERY_MANAGER,
        ROLES.LICENSE_KEY_MANAGER,
        ROLES.VIEW_ADMIN,
      ],
      children: [
        {
          label: t("Requests"),
          key: "loanRequests",
          hidden: ![
            ROLES.ADMIN,
            ROLES.CREDIT_APPROVER,
            ROLES.VIEW_ADMIN,
          ].includes(auth?.userInfo?.role),
          onClick: () => {
            navigate("/orders/requests-list");
          },
          icon: (
            <FontAwesomeIcon
              icon={faMoneyBill}
              color={"inherit"}
              style={{ scale: "1.1" }}
            />
          ),
        },
        {
          label: t("Accepted"),
          key: "acceptedLoans",
          hidden: ![
            ROLES.ADMIN,
            ROLES.CREDIT_APPROVER,
            ROLES.DELIVERY_MANAGER,
            ROLES.LICENSE_KEY_MANAGER,
            ROLES.VIEW_ADMIN,
          ].includes(auth?.userInfo?.role),
          onClick: () => {
            navigate("/orders/accepted-list");
          },
          icon: (
            <FontAwesomeIcon
              icon={faMoneyBill}
              color={"inherit"}
              style={{ scale: "1.1" }}
            />
          ),
        },
      ],
    },
  ];

  const LOGOUT_MENU = {
    path: location.pathname,
    title: t("Logout"),
    icon: (
      <FontAwesomeIcon icon={faRightFromBracket} style={{ scale: "1.2" }} />
    ),
    onClick: doLogout,
    onlyFor: [
      ROLES.ADMIN,
      ROLES.CREDIT_APPROVER,
      ROLES.DELIVERY_MANAGER,
      ROLES.LICENSE_KEY_MANAGER,
      ROLES.VIEW_ADMIN,
      ROLES.DISTRIBUTOR_ADMIN,
    ],
  };

  const PROFILE_MENU = {
    path: location.pathname,
    title: auth?.userInfo?.firstName,
    icon: <FontAwesomeIcon icon={faCircleUser} style={{ scale: "1.4" }} />,
    onClick: () => {
      if (auth.userInfo.role !== ROLES.ADMIN) {
        navigate(`user-profile/${auth.userInfo.id}/details`);
      } else {
        navigate(`users/${auth.userInfo.id}/details`);
      }
    },
    onlyFor: [
      ROLES.ADMIN,
      ROLES.CREDIT_APPROVER,
      ROLES.DELIVERY_MANAGER,
      ROLES.LICENSE_KEY_MANAGER,
      ROLES.VIEW_ADMIN,
      ROLES.DISTRIBUTOR_ADMIN,
    ],
  };

  const menuItems = (menuItems: IMenuItem[]) => {
    let menus = [] as MenuItem[];
    menuItems.forEach((item: IMenuItem) => {
      if (item?.onlyFor?.includes(auth?.userInfo?.role)) {
        const menuItem = getItem(
          item?.title,
          item?.path,
          item?.icon,
          item?.onClick,
          item?.children
        );
        menus.push(menuItem);
      }
    });

    return menus.filter((e) => Boolean(e));
  };

  return (
    <>
      {auth.authorized ? (
        <>
          <Layout.Sider
            onMouseEnter={() => {
              setIsSideBarCollapsed(false);
            }}
            onMouseLeave={() => {
              setIsSideBarCollapsed(true);
            }}
            collapsed={isSideBarCollapsed}
            collapsedWidth={SIDEBAR_WIDTH_COLLAPSED}
            width={SIDEBAR_WIDTH_EXPANDED}
            theme={"light"}
            style={{ fontSize: "20px" }}
            className={`sidebar-container`}
            id={"sidebar-container"}
          >
            <Logo
              width={
                isSideBarCollapsed
                  ? SIDEBAR_WIDTH_COLLAPSED
                  : SIDEBAR_WIDTH_EXPANDED
              }
              height={SIDEBAR_WIDTH_COLLAPSED}
              style={{
                scale: isSideBarCollapsed ? "0.8" : "1.5",
                transition: "0.3s ease",
              }}
            />
            <Divider />
            <Menu
              mode="inline"
              selectedKeys={[location.pathname]}
              items={menuItems(MENU_ITEMS)}
            />
            <Divider />
            <Menu
              // className="profileCard-menu"
              /* enable the class name and move this Menu Component
                below the "logout" Menu Component to
              */
              selectedKeys={["/"]}
              mode="inline"
              items={menuItems([PROFILE_MENU])}
            />
            <Menu
              selectedKeys={["/"]}
              mode="inline"
              items={menuItems([LOGOUT_MENU])}
            />
          </Layout.Sider>
        </>
      ) : (
        <></>
      )}
      <ProfileCard
        open={isProfileCardOpen}
        close={() => {
          setIsProfileCardOpen(false);
        }}
      />
    </>
  );
};

export default SideBar;
