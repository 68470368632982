import Backend from "i18next-http-backend";
import { LANG_CODES } from "./appConstants";
import LanguageDetector from "i18next-browser-languagedetector";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

declare module "i18next" {
  interface CustomTypeOptions {
    returnNull: false;
  }
}

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next) // bind react-i18next to the instance
  .init({
    lng: localStorage.getItem("i18nextLng") || "en",
    supportedLngs: LANG_CODES,
    fallbackLng: "en",
    // debug: true,
    detection: {
      order: [
        "localStorage",
        "sessionStorage",
        "cookie",
        "path",
        "querystring",
        "navigator",
        "htmlTag",
        "subdomain",
      ],
    },
    interpolation: {
      escapeValue: false, // not needed for react!!
    },

    // react i18next special options (optional)
    // override if needed - omit if ok with defaults
    react: {
      bindI18n: "languageChanged",
      bindI18nStore: "",
      transEmptyNodeValue: "",
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ["br", "strong", "i"],
      useSuspense: true,
    },
  });

export default i18n;
