import DistributorsHeader from "components/distributors/distributors.header";
import DistributorsList from "components/distributors/distributors.list";

const DistributorsScreen = () => {
  return (
    <>
      <DistributorsHeader />
      <br />
      <DistributorsList />
    </>
  );
};

export default DistributorsScreen;
