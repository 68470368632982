import { ILoanScreenTabs, ROLES } from "store/types";
import { Tabs, TabsProps } from "antd";
import { useNavigate, useParams } from "react-router-dom";

import { LoansListActions } from "store/slices/loansListSlice";
import LoansListActive from "./loans.list.active";
import LoansListAssignDispatcherPending from "components/loans/loans.list.assignDispatcherPending";
import LoansListClosed from "./loans.list.closed";
import LoansListDeviceMappingPending from "./loans.list.deviceMappingPending";
import LoansListDispatchPending from "./loans.list.dispatchPending";
import LoansListDownPaymentPending from "./loan.list.downPaymentPending";
import LoansListEmiPending from "./loans.list.emiPending";
import LoansListLicenseKeyPending from "./loans.list.licenseKeyPending";
import { RootState } from "store/store";
import { useAppDispatch } from "store/hooks";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

interface ICustomLoanMenuItem {
  key: string;
  label: string;
  children: React.ReactNode;
  onlyFor?: ROLES[];
}

const LoansList = () => {
  const { auth, loansList } = useSelector((state: RootState) => state);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { tabId } = useParams();
  const tabPaths: { [key in ILoanScreenTabs]: string } = {
    [ILoanScreenTabs.ACTIVE]: "active",
    [ILoanScreenTabs.CLOSED]: "closed",
    [ILoanScreenTabs.DEVICE_MAPPING_PENDING]: "mapping-pending",
    [ILoanScreenTabs.DOWN_PAYMENT_PENDING]: "down-payment-pending",
    [ILoanScreenTabs.EMI_PENDING]: "emi-pending",
    [ILoanScreenTabs.LICENSE_KEY_PENDING]: "license-key-pending",
    [ILoanScreenTabs.DISPATCH_PENDING]: "dispatch-pending",
    [ILoanScreenTabs.ASSIGN_DISPATCHER_PENDING]: "assign-dispatcher-pending",
  };

  useEffect(() => {
    if (!tabId) {
      navigate(
        `/orders/accepted-list/${
          tabPaths[ILoanScreenTabs.DOWN_PAYMENT_PENDING]
        }`,
        { replace: true }
      );
    }

    return () => {
      dispatch(LoansListActions.resetToInit());
    };
  }, []);

  useEffect(() => {
    const tabKey: ILoanScreenTabs | undefined = Object.keys(tabPaths)?.find(
      (key) => tabPaths[key as ILoanScreenTabs] === tabId
    ) as ILoanScreenTabs;
    dispatch(LoansListActions.setCurrentLoansTab(tabKey));
  }, [tabId]);

  useEffect(() => {
    if (loansList.currentLoansTab) {
      dispatch(LoansListActions.fetchLoansListByStatus());
    }
  }, [
    loansList.currentLoansTab,
    loansList.loansPagination,
    loansList?.loanSearchValues,
    loansList?.loanSortValues,
  ]);

  // useEffect(() => {
  //   if (Object.values(tabPaths).includes(currentRequestedTab)) {
  //     navigate(`/orders/accepted-list/${currentRequestedTab}`, {
  //       replace: true,
  //     });
  //   } else {
  //     navigate(`/orders/accepted-list/closed`, { replace: true });
  //   }
  // }, [currentLoansTab]);

  const handleTabChange = (key: string) => {
    navigate(`/orders/accepted-list/${key}`, {
      replace: true,
    });
  };

  const getMenuItems = (items: ICustomLoanMenuItem[]) => {
    const menus = items?.map((item) => {
      if (item?.onlyFor?.includes(auth?.userInfo?.role)) {
        return item;
      }
      return null;
    });
    return menus as TabsProps["items"];
  };

  const items: ICustomLoanMenuItem[] = [
    {
      key: tabPaths[ILoanScreenTabs.DOWN_PAYMENT_PENDING],
      label: t("Down Payment Pending"),
      children: <LoansListDownPaymentPending />,
      onlyFor: [ROLES.ADMIN, ROLES.CREDIT_APPROVER, ROLES.VIEW_ADMIN],
    },
    {
      key: tabPaths[ILoanScreenTabs.DEVICE_MAPPING_PENDING],
      label: t("Device Mapping Pending"),
      children: <LoansListDeviceMappingPending />,
      onlyFor: [
        ROLES.ADMIN,
        ROLES.CREDIT_APPROVER,
        ROLES.DELIVERY_MANAGER,
        ROLES.VIEW_ADMIN,
      ],
    },
    {
      key: tabPaths[ILoanScreenTabs.LICENSE_KEY_PENDING],
      label: t("License Key Pending"),
      children: <LoansListLicenseKeyPending />,
      onlyFor: [
        ROLES.ADMIN,
        ROLES.CREDIT_APPROVER,
        ROLES.LICENSE_KEY_MANAGER,
        ROLES.VIEW_ADMIN,
      ],
    },
    {
      key: tabPaths[ILoanScreenTabs.ASSIGN_DISPATCHER_PENDING],
      label: t("Assign Dispatcher Pending"),
      children: <LoansListAssignDispatcherPending />,
      onlyFor: [ROLES.ADMIN, ROLES.LICENSE_KEY_MANAGER, ROLES.CREDIT_APPROVER],
    },
    {
      key: tabPaths[ILoanScreenTabs.DISPATCH_PENDING],
      label: t("Delivery Pending"),
      children: <LoansListDispatchPending />,
      onlyFor: [
        ROLES.ADMIN,
        ROLES.CREDIT_APPROVER,
        ROLES.DELIVERY_MANAGER,
        ROLES.VIEW_ADMIN,
      ],
    },
    {
      key: tabPaths[ILoanScreenTabs.ACTIVE],
      label: t("Active"),
      children: <LoansListActive />,
      onlyFor: [
        ROLES.ADMIN,
        ROLES.CREDIT_APPROVER,
        ROLES.DELIVERY_MANAGER,
        ROLES.LICENSE_KEY_MANAGER,
        ROLES.VIEW_ADMIN,
      ],
    },
    {
      key: tabPaths[ILoanScreenTabs.CLOSED],
      label: t("Closed"),
      children: <LoansListClosed />,
      onlyFor: [
        ROLES.ADMIN,
        ROLES.CREDIT_APPROVER,
        ROLES.DELIVERY_MANAGER,
        ROLES.LICENSE_KEY_MANAGER,
        ROLES.VIEW_ADMIN,
      ],
    },
    {
      key: tabPaths[ILoanScreenTabs.EMI_PENDING],
      label: t("EMI Pending"),
      children: <LoansListEmiPending />,
      onlyFor: [
        ROLES.ADMIN,
        ROLES.CREDIT_APPROVER,
        ROLES.DELIVERY_MANAGER,
        ROLES.LICENSE_KEY_MANAGER,
        ROLES.VIEW_ADMIN,
      ],
    },
  ];

  return (
    <Tabs
      onChange={handleTabChange}
      items={getMenuItems(items)}
      destroyInactiveTabPane={true}
      activeKey={tabId}
    />
  );
};

export default LoansList;
