import "styles/Customers.less";

import { Button, Table } from "antd";
import {
  CUSTOMER_DOCUMENT_STATUS,
  CUSTOMER_DOCUMENT_TYPE,
  ICustomer,
} from "store/types";

import { ColumnsType } from "antd/es/table";
import ErrorRenderer from "components/util-components/ErrorRenderer";
import HiddenComponent from "components/util-components/HiddenComponent";
import { RootState } from "store/store";
import { getTagByStatus } from "./kycDocument.tags";
import { useCustomerListPagination } from "./useCustomerPagination";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const CustomersListKYCPending = () => {
  const { t } = useTranslation();
  const { customers } = useSelector((state: RootState) => state);
  const navigate = useNavigate();
  const { pagination } = useCustomerListPagination();

  const getDocumentStatus = (
    record: ICustomer,
    docType: CUSTOMER_DOCUMENT_TYPE
  ) => {
    return (
      record.kyc.documents
        .filter((documnet) => {
          return documnet.type === docType;
        })
        ?.at(0)?.status || ("" as keyof typeof CUSTOMER_DOCUMENT_STATUS)
    );
  };

  const columns: ColumnsType<ICustomer> = [
    {
      title: t("labels.customers.name"),
      dataIndex: "firstName",
      key: "customerName",
    },
    {
      title: t("labels.customers.phoneNumber"),
      dataIndex: "phoneNumber",
      key: "customerName",
    },
    {
      title: t("labels.customers.email"),
      dataIndex: "email",
      key: "email",
    },
    {
      title: t("Aadhar"),
      key: "aadhar",
      render: (_, record) => {
        const status = getDocumentStatus(
          record,
          CUSTOMER_DOCUMENT_TYPE.AADHAR_CARD
        );
        return <>{getTagByStatus(status as CUSTOMER_DOCUMENT_STATUS)}</>;
      },
    },
    {
      title: t("Pan Card"),
      key: "panCard",
      render: (_, record) => {
        const status = getDocumentStatus(
          record,
          CUSTOMER_DOCUMENT_TYPE.PAN_CARD
        );
        return <>{getTagByStatus(status as CUSTOMER_DOCUMENT_STATUS)}</>;
      },
    },
    {
      title: t("Bank Statement"),
      key: "bankStatement",
      render: (_, record) => {
        const status = getDocumentStatus(
          record,
          CUSTOMER_DOCUMENT_TYPE.BANK_STATEMENT
        );
        return <>{getTagByStatus(status as CUSTOMER_DOCUMENT_STATUS)}</>;
      },
    },
    {
      title: t("Actions"),
      key: "review",
      render: (_, record) => {
        return (
          <Button
            type="primary"
            style={{ fontWeight: "bolder" }}
            onClick={() => {
              navigate(`/customers/${record.id}/profile`);
            }}
          >
            {t("Review")}
          </Button>
        );
      },
    },
  ];
  return (
    <>
      <HiddenComponent
        hidden={customers?.customerList?.error}
        fallBack={
          <ErrorRenderer status={customers?.customerList?.errorStatus} />
        }
      >
        <Table
          bordered
          sticky={true}
          rowKey={(data) => data?.id}
          size={"small"}
          loading={customers?.customerList?.loading}
          dataSource={customers?.customerList?.customersListKYCPending}
          columns={columns}
          scroll={{
            x: "calc(100vw - 150px)",
            scrollToFirstRowOnChange: true,
          }}
          pagination={pagination}
        />
      </HiddenComponent>
    </>
  );
};

export default CustomersListKYCPending;
