import { IDistributor } from "store/types";
import { Outlet, useNavigate } from "react-router-dom";
import { Button, Table, Typography } from "antd";

import { ColumnsType } from "antd/es/table";
import ErrorRenderer from "components/util-components/ErrorRenderer";
import HiddenComponent from "components/util-components/HiddenComponent";
import { RootState } from "store/store";
import { DistributorsActions } from "store/slices/distributorsSlice";
import { useAppDispatch } from "store/hooks";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const DistributorsList = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { distributorsList } = useSelector(
    (state: RootState) => state.distributors
  );

  useEffect(() => {
    dispatch(DistributorsActions.fetchDistributorsList({}));
  }, []);

  const columns: ColumnsType<IDistributor> = [
    {
      title: t("labels.distributors.networkName"),
      key: "networkName",
      render: (_, record) => {
        return (
          <Typography.Link
            onClick={() => {
              navigate(`/distributors/${record?.distributorId}/dashboard`);
            }}
          >
            {record?.networkName}
          </Typography.Link>
        );
      },
    },
    {
      title: t("labels.distributors.contactPersonName"),
      key: "contactPersonName",
      render: (_, record) => {
        return <Typography.Text>{record?.contactPersonName}</Typography.Text>;
      },
    },
    {
      title: t("labels.distributors.phoneNumber"),
      dataIndex: "phoneNumber",
      key: "phoneNumber",
    },
    {
      title: t("labels.distributors.email"),
      dataIndex: "email",
      key: "email",
    },
    {
      title: t("labels.distributors.address"),
      key: "address",
      render: (_: any, record: IDistributor) => {
        return (
          <Typography.Text>
            {record.address.addressLine1 + ", "}
            {record.address.addressLine2
              ? record.address.addressLine2 + ", "
              : ""}
            {record.address.city +
              " - " +
              record.address.pincode +
              ", " +
              record.address.state +
              ", " +
              record.address.country}
          </Typography.Text>
        );
      },
    },
    {
      title: t("labels.actions"),
      key: "actions",
      fixed: "right",
      align: "center",
      render: (_: any, record: IDistributor) => {
        console.log("record is: ", record);
        return (
          <>
            <Button
              onClick={() => {
                navigate(`${record?.distributorId}/details`);
              }}
              type="link"
            >
              {t("View")}
            </Button>
            <Button
              onClick={() => {
                navigate(`${record?.distributorId}/edit`);
              }}
              type="link"
            >
              {t("Edit")}
            </Button>
          </>
        );
      },
    },
  ];

  return (
    <HiddenComponent
      hidden={distributorsList?.error}
      fallBack={<ErrorRenderer status={distributorsList?.errorStatus} />}
    >
      <Table
        bordered
        sticky={true}
        rowKey={(data) => data?.distributorId}
        loading={distributorsList?.loading}
        dataSource={distributorsList?.details}
        size={"small"}
        columns={columns}
        scroll={{
          x: "calc(100vw - 150px)",
          scrollToFirstRowOnChange: true,
        }}
      />
      <Outlet />
    </HiddenComponent>
  );
};

export default DistributorsList;
