import {
  IAssetLicenseKey,
  IBranch,
  ICustomer,
  ILoan,
  ILoanRequestStatus,
  ILoanScreenTabs,
  ILoansList,
  ILoansRequest,
  ILoansRequestList,
  ILoansStatus,
  IUser,
} from "store/types";
import { Requester, formatURL } from "api/requester";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { API_END_POINTS } from "api/urls";
import { AxiosResponse } from "axios";

const fetchActiveAndClosedLoansList = createAsyncThunk(
  "loans/fetchActiveAndClosedList",
  async (payload: any, thunkAPI) => {
    const response: AxiosResponse = await Requester.get(
      API_END_POINTS.LOANS.activelist,
      {
        params: payload?.requestParams,
      }
    ).catch(({ response }) => response);
    if (response?.status === 200) {
      return response?.data;
    } else {
      return thunkAPI.rejectWithValue({
        error: true,
        status: response?.status,
      });
    }
  }
);

const fetchPendingAndRejectedLoansList = createAsyncThunk(
  "loans/fetchPendingAndRejectedList",
  async (payload: any, thunkAPI) => {
    const response: AxiosResponse = await Requester.get(
      API_END_POINTS.LOANS.requestList,
      {
        params: payload,
      }
    ).catch(({ response }) => response);
    if (response?.status === 200) {
      return response?.data;
    } else {
      return thunkAPI.rejectWithValue({
        error: true,
        status: response?.status,
      });
    }
  }
);

const fetchLoanRequestById = createAsyncThunk(
  "loans/loanRequestById",
  async (loanRequestId: string, thunkAPI) => {
    let url = formatURL(API_END_POINTS.LOANS.requestById, {
      loanRequestId: loanRequestId,
    });
    const response = await Requester.get(url);
    if (response?.status === 200) {
      return response?.data;
    } else {
      return thunkAPI.rejectWithValue({
        error: true,
        status: response?.status,
      });
    }
  }
);

const fetchCustomerById = createAsyncThunk(
  "loans/loadCustomer",
  async (customerId: string, thunkAPI) => {
    let url = formatURL(API_END_POINTS.CUSTOMERS.customerById, {
      customerId: customerId,
    });

    const response = await Requester.get(url);
    if (response?.status === 200) {
      return response?.data;
    } else {
      return thunkAPI.rejectWithValue({
        error: true,
        status: response?.status,
      });
    }
  }
);

const fetchCustomerLoansById = createAsyncThunk(
  "loans/loadCustomerLoansById",
  async (customerId: string, thunkAPI) => {
    let url = formatURL(API_END_POINTS.CUSTOMERS.allLoans, {
      customerId: customerId,
    });
    const response = await Requester.get(url);
    if (response?.status === 200) {
      return response?.data;
    } else {
      return thunkAPI.rejectWithValue({
        error: true,
        status: response?.status,
      });
    }
  }
);

const fetchLoanById = createAsyncThunk(
  "loans/loadLoanById",
  async (loanId: string, thunkAPI) => {
    let url = formatURL(API_END_POINTS.LOANS.activeById, {
      loanId: loanId,
    });
    const response = await Requester.get(url);
    if (response?.status === 200) {
      return response?.data;
    } else {
      return thunkAPI.rejectWithValue({
        error: true,
        status: response?.status,
      });
    }
  }
);

const fetchLicenseKeysByAssetId = createAsyncThunk(
  "assets/assetId",
  async (assetId: string, thunkAPI) => {
    console.log("fetching license keys by asset id");
    let url = formatURL(API_END_POINTS.LOANS.licenseKeysByAssetId, {
      assetId: assetId,
    });
    const response = await Requester.get(url);
    if (response?.status === 200) {
      return response?.data;
    } else {
      return thunkAPI.rejectWithValue({
        error: true,
        status: response?.status,
      });
    }
  }
);

const fetchChildLoansByParentLoanId = createAsyncThunk(
  "loans/fetchChildLoansByParentLoanId",
  async (parentLoanId: string, thunkAPI) => {
    let url = API_END_POINTS.LOANS.activelist;
    const response = await Requester.get(url, {
      params: {
        parentLoanId: parentLoanId,
      },
    });
    if (response?.status === 200) {
      return response?.data;
    } else {
      return thunkAPI.rejectWithValue({
        error: true,
        status: response?.status,
      });
    }
  }
);

const fetchBranches = createAsyncThunk(
  "loans/branches",
  async (payload: any, thunkAPI) => {
    let url = API_END_POINTS.BRANCHES.list;
    const response = await Requester.get(url);
    if (response?.status === 200) {
      return response?.data;
    } else {
      return thunkAPI.rejectWithValue({
        error: true,
        status: response?.status,
      });
    }
  }
);

const fetchDispatchers = createAsyncThunk(
  "loans/dispatchers",
  async (params: {}, thunkAPI) => {
    let url = API_END_POINTS.USERS.list;
    // let params = branchId ? { branchId: branchId } : {};
    const response = await Requester.get(url, {
      params: params,
    });
    if (response?.status === 200) {
      return response?.data;
    } else {
      return thunkAPI.rejectWithValue({
        error: true,
        status: response?.status,
      });
    }
  }
);

const initialState = {
  loansTable: {
    details: {
      activeLoansList: [] as ILoan[],
      closedLoansList: [] as ILoan[],
      pendingLoansList: [] as ILoansRequest[],
      rejectedLoansList: [] as ILoansRequest[],
    },
    loading: {
      activeLoansList: false,
      closedLoansList: false,
      pendingLoansList: false,
      rejectedLoansList: false,
    },
    error: {
      activeLoansList: false,
      closedLoansList: false,
      pendingLoansList: false,
      rejectedLoansList: false,
    },
    pagination: {
      pageTokens: [] as string[],
      currentPage: 1,
      pageSize: 100,
    },
    selectedTab: ILoanScreenTabs.ACTIVE,
  },
  loansRequestDashboard: {
    requestDetails: {
      details: {} as ILoansRequest,
      loading: false,
      error: false,
    },
    customerDetails: {
      details: {} as ICustomer,
      loading: false,
      error: false,
    },
    customerLoansDetails: {
      details: {
        activeLoans: [] as ILoan[],
        closedLoans: [] as ILoan[],
      },
      loading: false,
      error: false,
    },
  },
  assetLicenseKey: {
    details: {} as IAssetLicenseKey,
    loading: false,
    error: false,
  },
  loanDashboard: {
    details: {} as ILoan,
    selectedAssetId: "",
    loading: false,
    error: false,
  },
  childLoanDetails: {
    details: [] as ILoan[],
    loading: false,
    error: false,
  },
  dispatchersList: {
    details: [] as IUser[],
    loading: false,
    error: false,
  },
  branchesList: {
    details: [] as IBranch[],
    loading: false,
    error: false,
  },
};

export const loansSlice = createSlice({
  name: "loanSlice",
  initialState: initialState,
  reducers: {
    setCurrentPageNumber: (state, { payload }) => {
      state.loansTable.pagination.currentPage = payload;
    },
    setCurrentTab: (state, { payload }) => {
      state.loansTable.selectedTab = payload;
    },
    setSelectedAssetId: (state, { payload }) => {
      state.loanDashboard.selectedAssetId = payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchActiveAndClosedLoansList.pending, (state) => {
      state.loansTable.loading.activeLoansList = true;
      state.loansTable.loading.closedLoansList = true;
      state.loansTable.error.activeLoansList = false;
      state.loansTable.error.closedLoansList = false;
    });
    builder.addCase(fetchActiveAndClosedLoansList.rejected, (state) => {
      state.loansTable.loading.activeLoansList = false;
      state.loansTable.loading.closedLoansList = false;
      state.loansTable.error.activeLoansList = true;
      state.loansTable.error.closedLoansList = true;
    });
    builder.addCase(
      fetchActiveAndClosedLoansList.fulfilled,
      (state, { payload }: { payload: ILoansList }) => {
        const loans = {
          closedLoans: [] as ILoan[],
          activeLoans: [] as ILoan[],
        };
        payload?.items?.forEach((loan) => {
          if (loan?.status === ILoansStatus.CLOSED) {
            loans.closedLoans.push(loan);
          } else {
            loans.activeLoans.push(loan);
          }
        });
        state.loansTable.loading.activeLoansList = false;
        state.loansTable.loading.closedLoansList = false;
        state.loansTable.details.activeLoansList = loans.activeLoans;
        state.loansTable.details.closedLoansList = loans.closedLoans;
      }
    );

    builder.addCase(fetchPendingAndRejectedLoansList.pending, (state) => {
      state.loansTable.loading.pendingLoansList = true;
      state.loansTable.loading.rejectedLoansList = true;
    });
    builder.addCase(fetchPendingAndRejectedLoansList.rejected, (state) => {
      state.loansTable.loading.pendingLoansList = false;
      state.loansTable.loading.rejectedLoansList = false;
      state.loansTable.error.pendingLoansList = true;
      state.loansTable.error.rejectedLoansList = true;
    });
    builder.addCase(
      fetchPendingAndRejectedLoansList.fulfilled,
      (state, { payload }: { payload: ILoansRequestList }) => {
        const loans = {
          pendingLoans: [] as ILoansRequest[],
          rejectedLoans: [] as ILoansRequest[],
        };
        payload?.items?.forEach((loan) => {
          if (loan?.status === ILoanRequestStatus.PENDING) {
            loans.pendingLoans.push(loan);
          }
          if (loan?.status === ILoanRequestStatus.REJECTED) {
            loans.rejectedLoans.push(loan);
          }
        });
        state.loansTable.loading.pendingLoansList = false;
        state.loansTable.loading.rejectedLoansList = false;
        state.loansTable.details.pendingLoansList = loans.pendingLoans;
        state.loansTable.details.rejectedLoansList = loans.rejectedLoans;
        state.loansTable.pagination.pageTokens.push(payload?.pageToken);
      }
    );

    builder.addCase(fetchLoanRequestById.pending, (state) => {
      state.loansRequestDashboard.requestDetails.error = false;
      state.loansRequestDashboard.requestDetails.loading = true;
    });
    builder.addCase(fetchLoanRequestById.rejected, (state) => {
      state.loansRequestDashboard.requestDetails.loading = false;
      state.loansRequestDashboard.requestDetails.error = true;
    });
    builder.addCase(fetchLoanRequestById.fulfilled, (state, { payload }) => {
      state.loansRequestDashboard.requestDetails.loading = false;
      state.loansRequestDashboard.requestDetails.details = payload;
    });

    builder.addCase(fetchCustomerById.pending, (state) => {
      state.loansRequestDashboard.customerDetails.error = false;
      state.loansRequestDashboard.customerDetails.loading = true;
    });
    builder.addCase(fetchCustomerById.rejected, (state) => {
      state.loansRequestDashboard.customerDetails.loading = false;
      state.loansRequestDashboard.customerDetails.error = true;
    });
    builder.addCase(fetchCustomerById.fulfilled, (state, { payload }) => {
      state.loansRequestDashboard.customerDetails.loading = false;
      state.loansRequestDashboard.customerDetails.details = payload;
    });

    builder.addCase(fetchCustomerLoansById.pending, (state) => {
      state.loansRequestDashboard.customerLoansDetails.error = false;
      state.loansRequestDashboard.customerLoansDetails.loading = true;
    });
    builder.addCase(fetchCustomerLoansById.rejected, (state) => {
      state.loansRequestDashboard.customerLoansDetails.loading = false;
      state.loansRequestDashboard.customerLoansDetails.error = true;
    });
    builder.addCase(
      fetchCustomerLoansById.fulfilled,
      (state, { payload }: { payload: ILoan[] }) => {
        state.loansRequestDashboard.customerLoansDetails.loading = false;
        const loans = {
          closedLoans: [] as ILoan[],
          activeLoans: [] as ILoan[],
        };
        payload?.forEach((loan) => {
          if (loan?.status === ILoansStatus.CLOSED) {
            loans.closedLoans.push(loan);
          } else {
            loans.activeLoans.push(loan);
          }
        });
        state.loansRequestDashboard.customerLoansDetails.loading = false;
        state.loansRequestDashboard.customerLoansDetails.details.activeLoans =
          loans.activeLoans;
        state.loansRequestDashboard.customerLoansDetails.details.closedLoans =
          loans.closedLoans;
      }
    );

    builder.addCase(fetchLoanById.pending, (state) => {
      state.loanDashboard.error = false;
      state.loanDashboard.loading = true;
    });
    builder.addCase(fetchLoanById.rejected, (state) => {
      state.loanDashboard.loading = false;
      state.loanDashboard.error = true;
    });
    builder.addCase(fetchLoanById.fulfilled, (state, { payload }) => {
      state.loanDashboard.loading = false;
      state.loanDashboard.details = payload;
    });

    builder.addCase(fetchLicenseKeysByAssetId.pending, (state) => {
      state.assetLicenseKey.error = false;
      state.assetLicenseKey.loading = true;
    });
    builder.addCase(fetchLicenseKeysByAssetId.rejected, (state) => {
      state.assetLicenseKey.loading = false;
      state.assetLicenseKey.error = true;
    });
    builder.addCase(
      fetchLicenseKeysByAssetId.fulfilled,
      (state, { payload }) => {
        state.assetLicenseKey.loading = false;
        state.assetLicenseKey.details = payload;
      }
    );

    builder.addCase(fetchChildLoansByParentLoanId.pending, (state) => {
      state.childLoanDetails.error = false;
      state.childLoanDetails.loading = true;
    });
    builder.addCase(fetchChildLoansByParentLoanId.rejected, (state) => {
      state.childLoanDetails.loading = false;
      state.childLoanDetails.error = true;
    });
    builder.addCase(
      fetchChildLoansByParentLoanId.fulfilled,
      (state, { payload }) => {
        state.childLoanDetails.loading = false;
        state.childLoanDetails.details = payload?.items;
      }
    );

    builder.addCase(fetchDispatchers.pending, (state) => {
      state.dispatchersList.error = false;
      state.dispatchersList.loading = true;
    });
    builder.addCase(fetchDispatchers.rejected, (state) => {
      state.dispatchersList.loading = false;
      state.dispatchersList.error = true;
    });
    builder.addCase(fetchDispatchers.fulfilled, (state, { payload }) => {
      state.dispatchersList.loading = false;
      state.dispatchersList.details = payload?.items;
    });

    builder.addCase(fetchBranches.pending, (state) => {
      state.branchesList.error = false;
      state.branchesList.loading = true;
    });
    builder.addCase(fetchBranches.rejected, (state) => {
      state.branchesList.loading = false;
      state.branchesList.error = true;
    });
    builder.addCase(fetchBranches.fulfilled, (state, { payload }) => {
      state.branchesList.loading = false;
      state.branchesList.details = payload?.items;
    });
  },
});

export const LoansActions = {
  ...loansSlice.actions,
  fetchActiveAndClosedLoansList,
  fetchPendingAndRejectedLoansList,
  fetchLoanRequestById,
  fetchCustomerById,
  fetchCustomerLoansById,
  fetchLoanById,
  fetchChildLoansByParentLoanId,
  fetchDispatchers,
  fetchBranches,
  fetchLicenseKeysByAssetId,
};

export const LoansReducer = loansSlice.reducer;
