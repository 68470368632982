import { ILoan, ILoanType, IManagedAsset } from "store/types";
import { Requester, formatURL } from "api/requester";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { API_END_POINTS } from "api/urls";
import { IDashboardStats } from "store/types";

const fetchCustomerLoansById = createAsyncThunk(
  "customers/loadCustomerLoansById",
  async (customerId: string, thunkAPI) => {
    let url = formatURL(API_END_POINTS.CUSTOMERS.allLoans, {
      customerId: customerId,
    });
    const response = await Requester.get(url);
    if (response?.status === 200) {
      return response?.data;
    } else {
      return thunkAPI.rejectWithValue({
        error: true,
        status: response?.status,
      });
    }
  }
);

const fetchCustomerDashboardStatsById = createAsyncThunk(
  "customers/fetchCustomerDashboardStatsById",
  async (customerId: string, thunkAPI) => {
    let url = formatURL(API_END_POINTS.CUSTOMERS.dashboardStats, {
      customerId: customerId,
    });
    const response = await Requester.get(url);
    if (response?.status === 200) {
      return response?.data;
    } else {
      return thunkAPI.rejectWithValue({
        error: true,
        status: response?.status,
      });
    }
  }
);

const initialState = {
  customerLoansAndAssets: {
    loans: [] as ILoan[],
    assets: [] as IManagedAsset[],
    loading: false,
    error: false,
  },
  customerStatsDetails: {
    details: {} as IDashboardStats,
    loading: false,
    error: false,
  },
};

export const customerStatsSlice = createSlice({
  name: "customerDashboard",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCustomerLoansById.pending, (state) => {
      state.customerLoansAndAssets.error = false;
      state.customerLoansAndAssets.loading = true;
    });
    builder.addCase(fetchCustomerLoansById.rejected, (state) => {
      state.customerLoansAndAssets.loading = false;
      state.customerLoansAndAssets.error = true;
    });
    builder.addCase(
      fetchCustomerLoansById.fulfilled,
      (state, { payload }: { payload: ILoan[] }) => {
        state.customerLoansAndAssets.loading = false;
        state.customerLoansAndAssets.loans = payload;

        const managedAssets = [] as IManagedAsset[];
        payload.forEach((loan) => {
          if (loan?.loanType === ILoanType.PRIMARY) {
            loan?.managedAssets?.forEach((asset) => {
              managedAssets?.push(asset);
            });
          }
        });
        state.customerLoansAndAssets.assets = managedAssets;
      }
    );

    builder.addCase(fetchCustomerDashboardStatsById.pending, (state) => {
      state.customerStatsDetails.error = false;
      state.customerStatsDetails.loading = true;
    });
    builder.addCase(fetchCustomerDashboardStatsById.rejected, (state) => {
      state.customerStatsDetails.loading = false;
      state.customerStatsDetails.error = true;
    });
    builder.addCase(
      fetchCustomerDashboardStatsById.fulfilled,
      (state, { payload }: { payload: IDashboardStats }) => {
        state.customerStatsDetails.loading = false;
        state.customerStatsDetails.details = payload;
      }
    );
  },
});

export const CustomerStatsActions = {
  ...customerStatsSlice.actions,
  fetchCustomerLoansById,
  fetchCustomerDashboardStatsById,
};

export const CustomerStatsReducer = customerStatsSlice.reducer;
