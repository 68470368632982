import {
  Button,
  Col,
  Drawer,
  Form,
  Input,
  InputNumber,
  Radio,
  Row,
  Space,
  Tooltip,
  Upload,
  message,
  Image,
} from "antd";
import { InfoCircleOutlined, UploadOutlined } from "@ant-design/icons";
import RestrictedComponent, {
  PERMISSIONS,
} from "components/util-components/RestrictedComponent";
import { useEffect, useState } from "react";
import { useForm, useWatch } from "antd/es/form/Form";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";

import { API_END_POINTS } from "api/urls";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import HiddenComponent from "components/util-components/HiddenComponent";
import { IndianRuppeNumberFormattor } from "./product.util.components";
import { Requester } from "api/requester";
import { faIndianRupeeSign } from "@fortawesome/free-solid-svg-icons";
import {
  uploadFilesToS3,
  wrapWithAuthToken,
} from "components/util-components/utilFunctions";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "store/hooks";
import { ProductsActions } from "store/slices/productsSlice";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import { FALL_BACK_IMAGE } from "appConstants";
import { RcFile } from "antd/es/upload";
import Loader from "components/util-components/Loader";

const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

const ProductCreateForm = () => {
  const { t } = useTranslation();
  const { callback, returnTo }: any = useOutletContext();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const pathParams = useParams();
  const [isEdit, setIsEdit] = useState(false);
  const [form] = useForm();
  const [isImageTypeCorrect, setIsImageTypeCorrect] = useState(false);
  const [isFormUploading, setIsFormUploading] = useState(false);
  // eslint-disable-next-line
  const [maxImageCount, setMaxImageCount] = useState(3);
  const primaryProductWatch = useWatch("isPrimary", form);
  const imageLinksWatch = useWatch("imagesList", form);
  const { details, loading } = useSelector(
    (state: RootState) => state?.products?.productById
  );
  const [imageUrls, setImageUrls] = useState({} as any);
  const [disableUpload, setDisableUpload] = useState(false);

  const closeDrawer = () => {
    navigate(returnTo);
  };

  useEffect(() => {
    if (!pathParams?.productId) {
      form.setFieldValue("isPrimary", true);
    }
    return () => {
      dispatch(ProductsActions.resetState());
    };
  }, []);

  useEffect(() => {
    if (pathParams?.productId) {
      setIsEdit(true);
    }
  }, [pathParams?.productId]);

  useEffect(() => {
    if (isEdit) {
      dispatch(ProductsActions.fetchProductsDetailById(pathParams?.productId));
    }
  }, [isEdit]);

  useEffect(() => {
    isEdit && prefillValue();
  }, [details]);

  const prefillValue = async () => {
    form.setFieldsValue({
      isPrimary: details.isPrimary,
      name: details.name,
      group: details.group,
      retailPrice: details.retailPrice,
      productLink: details.productLink,
      emiMinDownPayment: details.emiOptions[0].downPayment || 0,
      processingFee: details.emiOptions[0].processingFee || 0,
      distributorFee: details.distributorFee || 0,
    });
  };

  const deleteImage = (imageUrl: string) => {
    dispatch(ProductsActions.deleteImageLinks(imageUrl));
  };

  const deleteFile = async (fileUid: string) => {
    imageLinksWatch.fileList = imageLinksWatch.fileList.filter((file: any) => {
      return file.uid === fileUid ? false : true;
    });
    let modifiedUrls = Object.fromEntries(
      Object.entries(imageUrls).filter(([key]) => {
        return key.includes(fileUid) ? false : true;
      })
    );
    setImageUrls(modifiedUrls);
    form.setFieldValue("imagesList", imageLinksWatch);
  };

  const handleSubmit = async (formValues: any) => {
    setIsFormUploading(true);

    if (formValues?.imagesList?.fileList) {
      uploadFilesToS3(
        formValues?.imagesList?.fileList,
        () => {
          setIsFormUploading(false);
        },
        async (links) => {
          links = links.concat(details?.imageLinks);
          await forwardRequest(formValues, links);
        }
      );
    } else {
      await forwardRequest(formValues, details?.imageLinks);
    }
  };

  const forwardRequest = async (formValues: any, links: any) => {
    let data = {};
    if (links) {
      links = links.filter((link: any) => {
        return link ? true : false;
      });
      data = {
        ...formValues,
        imageLinks: links,
      };
    } else {
      data = { ...formValues };
    }

    let url = API_END_POINTS.PRODUCTS.list;
    let response = null;
    if (isEdit) {
      url = url + "/" + pathParams?.productId;
      response = await Requester.patch(url, data).catch(
        ({ response }) => response
      );
    } else {
      response = await Requester.post(url, data).catch(
        ({ response }) => response
      );
    }
    if (response?.status === 200) {
      navigate(returnTo);
      message.success(t("Successfully Added"));
      setIsFormUploading(false);
      callback();
    } else {
      message.error(t("Form Submission Failed"));
      setIsFormUploading(false);
    }
  };

  const shouldDisableFileUpload = () => {
    const shouldDisable =
      Object.keys(imageUrls).length + details?.imageLinks?.length ===
      maxImageCount;
    setDisableUpload(shouldDisable);
  };

  useEffect(() => {
    shouldDisableFileUpload();
  }, [imageUrls, details?.imageLinks]);

  useEffect(() => {
    (async () => {
      if (imageLinksWatch) {
        imageLinksWatch?.fileList?.map(async (file: any, index: any) => {
          let base64 = await getBase64(file.originFileObj as RcFile);
          setImageUrls({ ...imageUrls, [file.uid]: base64 });
        });
      }
    })();
  }, [imageLinksWatch]);

  const handleFileUploadChange = () => {};

  return (
    <Drawer
      open={true}
      maskClosable={false}
      size={"large"}
      title={!isEdit ? t("labels.products.create") : t("labels.products.edit")}
      footer={
        <RestrictedComponent permission={PERMISSIONS.PRODUCTS.create}>
          <Row>
            <Col span={24} style={{ textAlign: "right" }}>
              <Form.Item className="form-actionbuttons">
                <Button
                  type="primary"
                  onClick={form.submit}
                  loading={isFormUploading}
                >
                  {t("labels.submit")}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </RestrictedComponent>
      }
      onClose={closeDrawer}
    >
      <RestrictedComponent permission={PERMISSIONS.PRODUCTS.create} withAlert>
        <HiddenComponent hidden={loading} fallBack={<Loader />}>
          <Form form={form} onFinish={handleSubmit} layout="vertical">
            <Form.Item
              name={"isPrimary"}
              label={
                <Space align="center">
                  {t("labels.products.productType")}
                  <Tooltip
                    title={
                      <>{t("Primary Product needs License key to function")}</>
                    }
                  >
                    <InfoCircleOutlined />
                  </Tooltip>
                </Space>
              }
            >
              <Radio.Group optionType="default" disabled={isEdit}>
                <Radio.Button value={true}>{t("Primary Product")}</Radio.Button>
                <Radio.Button value={false}>
                  {t("Secondary Product")}
                </Radio.Button>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: t("messages.validations.productNameRequired"),
                },
              ]}
              name={"name"}
              label={t("labels.products.name")}
            >
              <Input placeholder={t("placeholders.productName")} />
            </Form.Item>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: t("messages.validations.groupRequired"),
                },
              ]}
              name={"group"}
              label={t("labels.products.group")}
            >
              <Input placeholder={t("placeholders.group")} />
            </Form.Item>
            <Form.Item
              name={"retailPrice"}
              label={t("labels.products.retailPrice")}
              rules={[
                {
                  required: true,
                  message: t("messages.validations.retailPriceRequired"),
                },
              ]}
            >
              <InputNumber
                style={{ width: "100%" }}
                controls={false}
                formatter={IndianRuppeNumberFormattor}
                prefix={<FontAwesomeIcon icon={faIndianRupeeSign} />}
              />
            </Form.Item>
            <HiddenComponent hidden={!primaryProductWatch}>
              <Form.Item
                name={"emiMinDownPayment"}
                label={t("labels.products.minDownPayment")}
              >
                <InputNumber
                  style={{ width: "100%" }}
                  controls={false}
                  formatter={IndianRuppeNumberFormattor}
                  prefix={<FontAwesomeIcon icon={faIndianRupeeSign} />}
                />
              </Form.Item>
              <Form.Item name={"processingFee"} label={t("Processing Fee")}>
                <InputNumber
                  style={{ width: "100%" }}
                  controls={false}
                  formatter={IndianRuppeNumberFormattor}
                  prefix={<FontAwesomeIcon icon={faIndianRupeeSign} />}
                />
              </Form.Item>
            </HiddenComponent>
            <Form.Item name={"distributorFee"} label={t("Distributor Fee")}>
              <InputNumber
                style={{ width: "100%" }}
                controls={false}
                formatter={IndianRuppeNumberFormattor}
                prefix={<FontAwesomeIcon icon={faIndianRupeeSign} />}
              />
            </Form.Item>
            <Form.Item
              name={"productLink"}
              label={t("labels.products.websiteLink")}
            >
              <Input placeholder={t("placeholders.productWebLink")} />
            </Form.Item>

            <Form.Item
              rules={[
                {
                  required: isEdit
                    ? details?.imageLinks?.length === 0
                      ? true
                      : false
                    : true,
                  message: t("messages.validations.productImageRequired"),
                },
                {
                  validator() {
                    console.log("validator triggered");
                    if (
                      Object.keys(imageUrls).length === 0 &&
                      details?.imageLinks?.length !== 0
                    ) {
                      return Promise.resolve();
                    }
                    if (!isImageTypeCorrect) {
                      return Promise.reject(t("File type not supported"));
                    }
                    return Promise.resolve();
                  },
                },
              ]}
              name={"imagesList"}
              label={t("labels.products.addProductImage")}
            >
              <Upload
                beforeUpload={(file) => {
                  const isProperFile = [
                    "image/png",
                    "image/jpg",
                    "image/jpeg",
                  ].includes(file?.type);
                  if (!isProperFile) {
                    setIsImageTypeCorrect(false);
                  } else {
                    setIsImageTypeCorrect(true);
                  }
                  return false;
                }}
                maxCount={maxImageCount}
                multiple
                showUploadList={false}
                disabled={disableUpload}
                onChange={handleFileUploadChange}
              >
                <Button icon={<UploadOutlined />} disabled={disableUpload}>
                  {t("Upload (Max: 3)")}
                </Button>
              </Upload>
            </Form.Item>

            <Row style={{ marginTop: "10px" }}>
              <Col span={24}>
                <Image.PreviewGroup>
                  <Space>
                    {details?.imageLinks?.map((link) => {
                      return (
                        <Col>
                          <Image
                            key={link}
                            width={100}
                            src={wrapWithAuthToken(link)}
                            fallback={FALL_BACK_IMAGE}
                          />
                          <Col>
                            <Button
                              type="primary"
                              onClick={() => {
                                deleteImage(link);
                              }}
                              style={{ width: "100%" }}
                            >
                              {t("labels.delete")}
                            </Button>
                          </Col>
                        </Col>
                      );
                    })}
                    {Object.keys(imageUrls).map((key: any) => {
                      return (
                        <Col>
                          <Image
                            key={key}
                            width={100}
                            src={imageUrls[key]}
                            fallback={FALL_BACK_IMAGE}
                          />
                          <Col>
                            <Button
                              type="primary"
                              onClick={async () => {
                                await deleteFile(key);
                              }}
                              style={{ width: "100%" }}
                            >
                              {t("labels.delete")}
                            </Button>
                          </Col>
                        </Col>
                      );
                    })}
                  </Space>
                </Image.PreviewGroup>
              </Col>
            </Row>
          </Form>
        </HiddenComponent>
      </RestrictedComponent>
    </Drawer>
  );
};

export default ProductCreateForm;
